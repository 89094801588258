import { useEffect, useState } from 'react';
import {
  addDoc,
  collection,
  doc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { db } from '../../../firebase';
import { Drawer } from '../../../components';
import { IBartender } from '../../../types';

interface IProps {
  bartender: IBartender | null;
  breweryId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function BartenderForm({
  bartender,
  breweryId,
  isOpen,
  onClose,
}: IProps): JSX.Element {
  const [name, setName] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    if (bartender) {
      setName(bartender.name);
      setIsActive(bartender.isActive);
    }
  }, [bartender]);

  function handleOnClose() {
    onClose();
    setName('');
    setIsActive(false);
  }

  async function handleSubmit() {
    const bartendersCollection = collection(db, 'bartenders');

    try {
      const breweryRef = doc(db, 'breweries', breweryId);
      const bartenderData: Omit<IBartender, 'id'> = {
        archivedAt: null,
        breweryRef,
        createdAt: Timestamp.now(),
        isActive,
        name,
      };

      await addDoc(bartendersCollection, bartenderData);

      handleOnClose();

      toast({
        description: 'Bartender created',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred while creating the bartender. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  async function handleEditBartender() {
    try {
      const bartenderDoc = doc(db, 'bartenders', bartender!.id);
      const bartenderData: Pick<IBartender, 'isActive' | 'name'> = {
        isActive,
        name,
      };

      await updateDoc(bartenderDoc, bartenderData);

      handleOnClose();

      toast({
        description: 'Bartender updated',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred while updating the bartender. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <Drawer
      title={bartender ? 'Edit Bartender' : 'Add Bartender'}
      isOpen={isOpen}
      onClose={handleOnClose}
      onSubmit={bartender ? handleEditBartender : handleSubmit}
      isSubmitButtonDisabled={!Boolean(name)}
    >
      <FormControl isRequired mb={4}>
        <FormLabel htmlFor="name">Bartender Name</FormLabel>
        <Input
          id="name"
          type="text"
          placeholder="Name"
          onChange={({ target }) => setName(target.value)}
          value={name}
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="active">Active</FormLabel>
        <Switch
          id="active"
          colorScheme="green"
          size="lg"
          onChange={({ currentTarget }) => setIsActive(currentTarget.checked)}
          isChecked={isActive}
        />
        <FormHelperText>Is this bartender active?</FormHelperText>
      </FormControl>
    </Drawer>
  );
}
