import { Box, Text } from '@chakra-ui/react';

interface IProps {
  children: React.ReactNode;
}

export default function PublicLayout({ children }: IProps): JSX.Element {
  return (
    <Box display="flex" h="calc(var(--vh, 1vh) * 100)">
      <Box
        w="65%"
        bg="green.50"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box w="55%" minWidth="512px">
          {children}
        </Box>
      </Box>

      <Box w="50%" display="flex" alignItems="center" justifyContent="center">
        <Box fontSize="7xl" display="flex">
          <Text mr={1}>🍺</Text>
          <Text fontWeight="bold">Mug</Text>Club
        </Box>
      </Box>
    </Box>
  );
}
