import dayjs from 'dayjs';
import { IClub, IPatron } from '../../types';

interface IPatronWithClubName extends IPatron {
  clubNames: string[];
}

export function mapPatronsToClubNames(
  patrons: IPatron[],
  clubs: IClub[],
): IPatronWithClubName[] {
  return patrons.map((patron) => {
    const clubNames: string[] = [];

    patron.clubs.forEach((clubId) => {
      const clubName = clubs.find((club) => club.id === clubId)?.name;

      if (clubName) {
        clubNames.push(clubName);
      }
    });

    return {
      ...patron,
      clubNames,
    };
  });
}

interface ISearchPatrons {
  clubId?: string;
  orderedDescending: boolean;
  patrons: IPatron[];
  searchValue: string;
  sorter: string;
}

export function searchPatrons({
  clubId,
  orderedDescending,
  patrons,
  searchValue,
  sorter,
}: ISearchPatrons) {
  return patrons
    ?.filter((patron) => {
      if (clubId && !patron.clubs.includes(clubId)) {
        return false;
      }

      const patronFullName =
        `${patron.firstName} ${patron.lastName}`.toLowerCase();
      if (patronFullName.includes(searchValue.toLowerCase())) {
        return true;
      }

      return false;
    })
    .sort((patronA: IPatron, patronB: IPatron) => {
      if (sorter === 'membershipExpiresAt') {
        return sortByExpirationDate(patronA, patronB, orderedDescending);
      }

      if (orderedDescending) {
        // @ts-ignore
        if (patronA[sorter]! > patronB[sorter]) {
          return -1;
        }

        return 1;
      }

      // @ts-ignore
      if (patronA[sorter]! > patronB[sorter]) {
        return 1;
      }

      return -1;
    });
}

function sortByExpirationDate(
  patronA: IPatron,
  patronB: IPatron,
  orderedDescending: boolean,
): number {
  let patronAUnix = 0;
  let patronBUnix = 0;

  const patronADate = dayjs(patronA?.membershipExpiresAt);
  const patronBDate = dayjs(patronB?.membershipExpiresAt);

  if (patronADate.isValid()) {
    patronAUnix = patronADate.unix();
  }

  if (patronBDate.isValid()) {
    patronBUnix = patronBDate.unix();
  }

  if (orderedDescending) {
    if (patronAUnix > patronBUnix) {
      return -1;
    }

    return 1;
  }

  if (patronAUnix > patronBUnix) {
    return 1;
  }

  return -1;
}
