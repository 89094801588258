import React, { ChangeEvent, useEffect, useState } from 'react';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { storage } from '../../firebase';

interface IProps {
  breweryId: string;
}

export default function BreweryLogoTab({ breweryId }: IProps): JSX.Element {
  const [logoUrl, setLogoUrl] = useState<any>();
  const [file, setFile] = useState<any>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    async function fetchLogo() {
      const imageRef = ref(storage, `logos/${breweryId}`);

      const url = await getDownloadURL(imageRef);

      setLogoUrl(url);
    }

    fetchLogo();
  }, [breweryId]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.[0];

    if (fileUpload) {
      const imageSrc = URL.createObjectURL(fileUpload);

      setFile(fileUpload);
      setImagePreviewUrl(imageSrc);
    }
  }

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    setIsLoading(true);

    const storageRef = ref(storage, `logos/${breweryId}`);

    try {
      await uploadBytes(storageRef, file);

      toast({
        description: 'Brewery logo successfully uploaded.',
        status: 'success',
        title: 'Success!',
      });

      setLogoUrl(imagePreviewUrl);
      setImagePreviewUrl('');
      setFile(undefined);
    } catch (error) {
      toast({
        description: 'An error occurred while uploading brewery logo.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Brewery Logo
      </Heading>

      <Box display="flex" mb={6} gap={8}>
        {logoUrl && (
          <Box>
            <Text fontWeight="medium">Current Logo</Text>
            <Text color="gray.500" fontSize="sm" mb={6}>
              This logo will be displayed on the form where new members sign up
              for your mug clubs.
            </Text>
            <Box bg="gray.400" display="inline-block">
              <Image src={logoUrl} alt="logo-preview" maxHeight={200} />
            </Box>
          </Box>
        )}

        {imagePreviewUrl && (
          <Box>
            <Text mb={2} fontWeight="medium">
              New Logo Preview
            </Text>
            <Box bg="gray.400" display="inline-block">
              <Image src={imagePreviewUrl} alt="logo-preview" maxHeight={200} />
            </Box>
          </Box>
        )}
      </Box>

      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel>Upload Logo</FormLabel>
          <Input type="file" onChange={handleChange} />
        </FormControl>

        <Button
          type="submit"
          width="100%"
          disabled={!file}
          isLoading={isLoading}
        >
          Upload
        </Button>
      </form>
    </Box>
  );
}
