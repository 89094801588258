import { Box, Heading, Link, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { AlertCircle } from '../../assets';

export default function MissingCheckoutModal(): JSX.Element {
  return (
    <Box
      position={'absolute'}
      top={0}
      right={0}
      bottom={0}
      height="100vh"
      bg="blackAlpha.500"
      w="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="100"
      p={2}
    >
      <Box bg="white" borderRadius={4} p={8} shadow="md" maxWidth={768}>
        <Box mb={8} textAlign="center">
          <AlertCircle fontSize="4xl" color="red.500" mb={4} />

          <Heading size="lg">Unable to Create Account</Heading>
        </Box>

        <Text mb={2}>
          Apologies, but we are unable to create your account. This may be due
          to one of the following:
        </Text>
        <Box textAlign="left" margin="auto" w="384px" mb={4}>
          <Text>1. You've already created an account</Text>
          <Text>2. Your payment is still processing</Text>
          <Text>3. An error occurred while processing your payment</Text>
        </Box>

        <Text textAlign="center">
          If you already created an account, please login{' '}
          <Link as={ReactRouterLink} to="/" color="green.500">
            here
          </Link>
          . Otherwise, please reach out to <b>accounts@mugclub.beer</b> for
          help. Apologies for the inconvenience.
        </Text>
      </Box>
    </Box>
  );
}
