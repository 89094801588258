import { Box, Heading, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from '../../assets';
import { auth } from '../../firebase';

export default function ErrorBoundary(): JSX.Element {
  const { currentUser } = auth;

  function handleOnClick() {
    const homeUrl = currentUser ? '/auth/clubs' : '/';
    window.location.pathname = homeUrl;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="calc(var(--vh, 1vh) * 100)"
    >
      <Box textAlign="center">
        <InfoCircleIcon fontSize="8xl" mb={6} color="red.500" />
        <Heading mb={4}>Uh Oh</Heading>
        <Heading mb={4} size="md" fontWeight="normal">
          An error occurred. Sorry about that.
        </Heading>
        <Text onClick={handleOnClick} cursor="pointer" color="green.500">
          Return Home
        </Text>
      </Box>
    </Box>
  );
}
