import { useMemo } from 'react';
import { Td, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MembershipStatusBadge } from '../../../components';
import { IMembership } from '../../../types';

interface IProps {
  clubId?: string;
  isLoading?: boolean;
  memberships?: IMembership[];
}

export default function MembershipStatus({
  clubId,
  isLoading,
  memberships,
}: IProps): JSX.Element {
  const membership = useMemo(() => {
    if (!memberships) {
      return undefined;
    }

    if (!clubId) {
      const activeMembership = memberships.find(
        (membership) => membership.isActive,
      );

      if (activeMembership) {
        return activeMembership;
      }

      if (memberships.length === 1) {
        return memberships[0];
      }
    }

    if (clubId) {
      return memberships.find((membership) => membership.clubId === clubId);
    }

    return undefined;
  }, [clubId, memberships]);
  const expirationDate = useMemo(() => {
    if (membership?.expiresAt === null || membership?.isActive === false) {
      return '-';
    }

    if (!membership?.expiresAt) {
      return undefined;
    }

    return dayjs(membership.expiresAt.toDate()).format('MM/DD/YYYY');
  }, [membership]);

  if (isLoading) {
    return (
      <>
        <Td>
          <Text
            bg="gray.50"
            display="inline"
            fontSize="xs"
            fontWeight="medium"
            px={1}
            py={0.5}
            rounded="md"
          >
            Loading...
          </Text>
        </Td>
        <Td>
          <Text
            bg="gray.50"
            display="inline"
            fontSize="xs"
            fontWeight="medium"
            px={1}
            py={0.5}
            rounded="md"
          >
            Loading...
          </Text>
        </Td>
      </>
    );
  }

  return (
    <>
      <Td>
        {membership ? <MembershipStatusBadge membership={membership} /> : '-'}
      </Td>
      <Td>{expirationDate || '-'}</Td>
    </>
  );
}
