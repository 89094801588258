import { deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ArchiveIcon } from '../../../assets';
import { db } from '../../../firebase';
import { IPatron } from '../../../types';
import { useRef } from 'react';

interface IProps {
  patron: IPatron;
}

export default function ArchivePatron({ patron }: IProps): JSX.Element {
  const {
    isOpen: isConfirmArchiveOpen,
    onOpen: onOpenConfirmArchive,
    onClose: onCloseConfirmArchive,
  } = useDisclosure();
  const cancelRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();

  async function handleArchivePatron() {
    const { id } = patron!;
    const patronRef = doc(db, `patrons/${id}`);

    onCloseConfirmArchive();

    try {
      await deleteDoc(patronRef);

      navigate('/auth/patrons');

      toast({
        description: 'Patron archived.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          "An error occurred changing the patron's status. Please try again.",
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={isConfirmArchiveOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirmArchive}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Archive Patron?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                onClick={onCloseConfirmArchive}
                variant="outline"
                colorScheme="gray"
              >
                Cancel
              </Button>
              <Button onClick={handleArchivePatron} colorScheme="red" ml={3}>
                Archive
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <MenuItem icon={<ArchiveIcon />} onClick={onOpenConfirmArchive}>
        Archive Patron
      </MenuItem>
    </>
  );
}
