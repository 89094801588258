import { SyntheticEvent, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Link,
  Input,
  Text,
} from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import Success from './Success';
import { functions } from '../../firebase';

export default function RequestPasswordReset(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  async function handleOnSubmit(event: SyntheticEvent) {
    event.preventDefault();

    if (!email.trim()) {
      return;
    }

    setIsLoading(true);

    const requestPasswordReset = httpsCallable(
      functions,
      'breweryUserRequestPasswordReset',
    );

    try {
      await requestPasswordReset({ email });
    } catch (error) {
      console.log(error);
    } finally {
      setShowSuccess(true);
    }
  }

  if (showSuccess) {
    return <Success />;
  }

  return (
    <Box
      bg="gray.100"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box bg="white" rounded="lg" shadow="md" p={6} w={384}>
        <Box textAlign="center" mb={6}>
          <Text fontSize="lg" mb={6}>
            🍺 <b>Mug</b>
            Club
          </Text>
          <Text fontSize="2xl" fontWeight="semibold">
            Request Password Reset
          </Text>
          <Text color="gray.500" fontSize="sm">
            Enter the email associated with your MugClub.beer account to begin
            the password reset process.
          </Text>
        </Box>

        <form onSubmit={handleOnSubmit}>
          <FormControl mb={4}>
            <FormLabel htmlFor="email" fontSize="sm">
              Email
            </FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="Email"
              onChange={({ target }) => setEmail(target.value)}
            />
          </FormControl>

          <Button
            w="100%"
            type="submit"
            bg="green.500"
            color="white"
            isLoading={isLoading}
            size="sm"
          >
            Request Password Reset
          </Button>
        </form>

        <Text mt={6} textAlign="center" color="gray.500" fontSize="sm">
          <Link as={ReactRouterLink} to="/">
            Click here
          </Link>{' '}
          to return to the Login page.
        </Text>
      </Box>
    </Box>
  );
}
