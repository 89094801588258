import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { Layout } from '../../components';
import BreweryTab from './BreweryTab';
import BreweryLogoTab from './BreweryLogoTab';
import BartenderTab from './BartenderTab';
import EmailNotificationsTab from './EmailNotificationsTab';
import PasswordTab from './PasswordTab';
import PatronExportTab from './PatronExportTab';
import SubscriptionTab from './SubscriptionTab';
import { useGetBrewery } from '../../contexts';

export default function SettingsPage(): JSX.Element {
  const [brewery] = useGetBrewery();

  return (
    <Layout
      isLoading={!brewery}
      pageDescription="Update your brewery and other key settings."
      pageHeading="Settings"
    >
      <Tabs variant="enclosed" colorScheme="green" isLazy size="sm">
        <TabList>
          <Tab>Brewery Details</Tab>
          <Tab>Brewery Logo</Tab>
          <Tab>Bartender Management</Tab>
          <Tab>Email Notifications</Tab>
          <Tab>Change Password</Tab>
          <Tab>Manage Subscription</Tab>
          <Tab>Patron Export</Tab>
        </TabList>

        {brewery && (
          <TabPanels>
            <TabPanel>
              <BreweryTab brewery={brewery} />
            </TabPanel>

            <TabPanel>
              <BreweryLogoTab breweryId={brewery.id} />
            </TabPanel>

            <TabPanel>
              <BartenderTab brewery={brewery} />
            </TabPanel>

            <TabPanel>
              <EmailNotificationsTab brewery={brewery} />
            </TabPanel>

            <TabPanel>
              <PasswordTab />
            </TabPanel>

            <TabPanel>
              <SubscriptionTab brewery={brewery} />
            </TabPanel>

            <TabPanel>
              <PatronExportTab />
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </Layout>
  );
}
