import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { usePostHog } from 'posthog-js/react';
import AuthenticatedApp from './AuthApp';
import {
  GetStartedPage,
  LogInPage,
  NotFoundPage,
  PatronSignUpPage,
  RequestPasswordResetPage,
  ResetPasswordPage,
  SignUpPage,
} from './pages';
import { ErrorBoundary as ErrorFallback, FullscreenLoader } from './components';
import { auth } from './firebase';

function App() {
  const [isAuthLoaded, setIsAuthLoaded] = useState<boolean>(false);
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthLoaded(true);

      if (user && posthog) {
        posthog.identify(user.uid, {
          email: user.email,
        });
      }
    });

    return () => unsubscribe();
  }, [posthog]);

  useEffect(() => {
    posthog?.capture('$pageview');
  }, [location, posthog]);

  if (!isAuthLoaded) {
    return <FullscreenLoader />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes>
        <Route path="/" element={<LogInPage />} />
        <Route path="log-in" element={<LogInPage />} />
        <Route path="complete-sign-up" element={<SignUpPage />} />
        <Route path="get-started" element={<GetStartedPage />} />
        <Route path="patron-sign-up/:clubId" element={<PatronSignUpPage />} />
        <Route
          path="request-password-reset"
          element={<RequestPasswordResetPage />}
        />
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route path="auth/*" element={<AuthenticatedApp />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
