import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useToast } from '@chakra-ui/react';
import { db } from '../firebase';
import { IMembership } from '../types';

export default function useFetchPatronMembershipsByClub(
  clubId: string | undefined,
): [IMembership[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [memberships, setMemberships] = useState<IMembership[]>([]);
  const toast = useToast();

  const fetchMemberships = useCallback(async () => {
    try {
      const membershipsCollection = collection(db, 'memberships');
      const q = query(membershipsCollection, where('clubId', '==', clubId));
      const querySnapshot = await getDocs(q);
      const memberships: IMembership[] = [];

      querySnapshot.forEach((doc) => {
        const membership = {
          id: doc.id,
          ...doc.data(),
        } as IMembership;

        memberships.push(membership);
      });

      setMemberships(memberships);
      setIsLoading(false);
    } catch (error) {
      toast({
        description:
          "An error fetching the club's membership. Please try again.",
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [clubId, toast]);

  useEffect(() => {
    if (clubId) {
      fetchMemberships();
    }
  }, [clubId, fetchMemberships]);

  return [memberships, isLoading];
}
