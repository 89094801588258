import { Box, FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import SectionHeading from '../SectionHeading';
import SectionNavigation from '../SectionNavigation';
import { useClubCreatorContext } from '../Context';

export default function CustomQuestions(): JSX.Element {
  const { formValues, updateFormValues, stepBackward, stepForward } =
    useClubCreatorContext();

  function handleOnSubmit() {
    updateFormValues({ customQuestions: formValues.customQuestions });
    stepForward();
  }

  return (
    <Box>
      <SectionHeading
        title="Custom Questions (Optional)"
        description="Add custom questions to your club's signup form."
      />

      <FormControl mb={6}>
        <FormLabel htmlFor="customQuestions">Custom Questions</FormLabel>
        <Textarea
          id="customQuestions"
          onChange={({ currentTarget }) =>
            updateFormValues({ customQuestions: currentTarget.value })
          }
          value={formValues.customQuestions}
        />
      </FormControl>

      <SectionNavigation onPrevious={stepBackward} onNext={handleOnSubmit} />
    </Box>
  );
}
