import { Box, Text } from '@chakra-ui/react';
import ClubRewardRow from './ClubRewardRow';
import { IClub, IPatronReward } from '../../../../types';
import { useMemo } from 'react';

interface Props {
  club: IClub;
  rewards: IPatronReward[];
}

export default function RewardsList({ club, rewards }: Props): JSX.Element {
  const { rewards: clubRewards } = club;
  const sortedClubRewards = useMemo(() => {
    return clubRewards.sort((a, b) => {
      if (a.threshold > b.threshold) {
        return 1;
      }

      return -1;
    });
  }, [clubRewards]);

  function getRewardStats(id: string) {
    let earned = 0;
    let redeemed = 0;
    let unredeemed = 0;
    let ratio = '0%';
    const patronRewards: IPatronReward[] = [];

    rewards.forEach((reward) => {
      if (reward.rewardId === id) {
        if (reward.patronId || reward.patronRef) {
          patronRewards.push(reward);
        }

        earned += 1;

        if (reward.redeemed) {
          redeemed += 1;
        } else {
          unredeemed += 1;
        }
      }
    });

    if (redeemed > 0) {
      ratio = `${Math.round((redeemed / earned) * 100)}%`;
    }

    return {
      earned,
      patronRewards,
      redeemed,
      unredeemed,
      ratio,
    };
  }

  return (
    <Box>
      <Box
        display="grid"
        fontSize="xs"
        fontWeight="semibold"
        gridTemplateColumns="2.5fr 1fr 1fr 1fr 1fr 60px"
        mb={2}
        px={2}
        textTransform="uppercase"
        textColor="gray.600"
      >
        <Text>Reward</Text>
        <Text># Earned</Text>
        <Text># Redeemed</Text>
        <Text># Unredeemed</Text>
        <Text>Redemption Ratio</Text>
        <Box />
      </Box>

      {sortedClubRewards.map((clubReward) => {
        const stats = getRewardStats(clubReward.id);

        return (
          <ClubRewardRow
            key={clubReward.id}
            clubReward={clubReward}
            rewardStats={stats}
          />
        );
      })}
    </Box>
  );
}
