import { Box, Heading, Text } from '@chakra-ui/react';
import { useClubCreatorContext } from './Context';

interface IProps {
  title: string;
  description: string;
}

export default function SectionHeading({
  title,
  description,
}: IProps): JSX.Element {
  const { step } = useClubCreatorContext();

  return (
    <Box mb={6}>
      <Heading as="h3" size="md" mb={1}>
        Step {step}: {title}
      </Heading>

      <Text color="gray.500" fontSize="sm">
        {description}
      </Text>
    </Box>
  );
}
