import { useEffect, useState } from 'react';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import { db } from '../../../firebase';
import { Loader } from '../../../components';
import { IBartender, IBrewery } from '../../../types';
import BartendersTable from './BartendersTable';
import BartenderForm from './BartenderForm';

interface IProps {
  brewery: IBrewery;
}

export default function BartenderTab({ brewery }: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bartender, setBartender] = useState<IBartender | null>(null);
  const [bartenders, setBartenders] = useState<IBartender[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const bartenderCollection = collection(db, 'bartenders');
    const q = query(
      bartenderCollection,
      where('breweryRef', '==', doc(db, 'breweries', brewery.id)),
    );

    let unsub: any;

    try {
      unsub = onSnapshot(q, (collection) => {
        const bartenders: IBartender[] = [];

        collection.forEach((doc) => {
          bartenders.push({
            id: doc.id,
            ...doc.data(),
          } as IBartender);
        });

        setBartenders(bartenders);
        setIsLoading(false);
      });
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    }

    return () => unsub();
  }, [brewery.id]);

  if (isLoading) {
    return <Loader height="50vh" />;
  }

  function handleOnEdit(bartender: IBartender) {
    setBartender(bartender);
    onOpen();
  }

  function handleOnClose() {
    setBartender(null);
    onClose();
  }

  return (
    <div>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={4}
      >
        <Heading as="h2" fontSize="lg" mb={4}>
          Bartender Management
        </Heading>
        <Button onClick={onOpen} size="sm">
          Add Bartender
        </Button>
      </Box>

      <BartenderForm
        bartender={bartender}
        breweryId={brewery.id}
        isOpen={isOpen}
        onClose={handleOnClose}
      />

      <BartendersTable bartenders={bartenders} onEdit={handleOnEdit} />
    </div>
  );
}
