import { Box, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from '../../../../assets';

export default function FeatureGateText(): JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      gap={1}
      justifyContent="flex-end"
      fontSize="xs"
      fontWeight="medium"
      color="orange.500"
    >
      <InfoCircleIcon />
      <Text>Upgrade your plan to enable this custom message.</Text>
    </Box>
  );
}
