import { useState } from 'react';
import { updatePassword } from 'firebase/auth';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { auth } from '../../../firebase';

export default function PasswordTab(): JSX.Element {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    if (!auth.currentUser) {
      return;
    }

    setIsLoading(true);

    try {
      await updatePassword(auth.currentUser, password);

      toast({
        description: 'Password changed',
        status: 'success',
        title: 'Success!',
      });

      resetForm();
    } catch (error) {
      toast({
        description: 'An error occurred while changing your password.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handlePasswordMatch() {
    if (password !== confirmPassword) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }

  function resetForm() {
    setPassword('');
    setConfirmPassword('');
  }

  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Change Password
      </Heading>

      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={password}
              placeholder="New Password"
              onChange={({ currentTarget }) => setPassword(currentTarget.value)}
              onBlur={handlePasswordMatch}
            />
          </FormControl>

          <FormControl isRequired isInvalid={hasError}>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              placeholder="Confirm New Password"
              onChange={({ currentTarget }) =>
                setConfirmPassword(currentTarget.value)
              }
              onBlur={handlePasswordMatch}
            />
            {hasError && (
              <FormErrorMessage>Passwords must match.</FormErrorMessage>
            )}
          </FormControl>

          <Button
            w="100%"
            type="submit"
            disabled={hasError || !password}
            isLoading={isLoading}
          >
            Change Password
          </Button>
        </VStack>
      </form>
    </Box>
  );
}
