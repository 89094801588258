import { Box, Text } from '@chakra-ui/react';
import { AlertCircle } from '../../../assets';

export default function RegistrationClosed(): JSX.Element {
  return (
    <Box
      p={4}
      border="2px solid"
      borderColor="orange.500"
      bg="orange.50"
      borderRadius={4}
      mb={8}
    >
      <Box display="flex" gap={2} mb={2} alignItems="center">
        <AlertCircle color="orange.500" fontSize="xl" />
        <Text fontWeight="medium" color="orange.500">
          Registration Closed
        </Text>
      </Box>
      <Text>
        Registration for this club is now closed. If you believe you are seeing
        this message in error, please check with a member of our brewery staff.
      </Text>
    </Box>
  );
}
