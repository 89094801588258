import { doc, updateDoc } from 'firebase/firestore';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { db } from '../../../firebase';
import { IBrewery } from '../../../types';

interface IProps {
  brewery: IBrewery;
}

export default function EmailNotificationsTab({ brewery }: IProps) {
  const toast = useToast();

  async function handleChangeClubOverviewEmails(checked: boolean) {
    try {
      const breweryRef = doc(db, `breweries/${brewery.id}`);

      await updateDoc(breweryRef, {
        hasClubOverviewEmails: checked,
      } as Pick<IBrewery, 'hasClubOverviewEmails'>);

      toast({
        description: 'Club overview email notifications updated.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating the club settings. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  async function handleChangeNewPatronEmails(checked: boolean) {
    try {
      const breweryRef = doc(db, `breweries/${brewery.id}`);

      await updateDoc(breweryRef, {
        hasNewPatronEmails: checked,
      } as Pick<IBrewery, 'hasNewPatronEmails'>);

      toast({
        description: 'New patron email notifications updated.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating the club settings. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Email Notifications
      </Heading>

      <FormControl mb={4}>
        <FormLabel>Club Overview Emails</FormLabel>
        <Switch
          defaultChecked={brewery.hasClubOverviewEmails}
          colorScheme="green"
          onChange={({ currentTarget }) =>
            handleChangeClubOverviewEmails(currentTarget.checked)
          }
        />
        <FormHelperText>
          Opt into weekly email updates on the progress of your mug clubs.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel>New Patron Emails</FormLabel>
        <Switch
          defaultChecked={brewery.hasNewPatronEmails}
          colorScheme="green"
          onChange={({ currentTarget }) =>
            handleChangeNewPatronEmails(currentTarget.checked)
          }
        />
        <FormHelperText>
          Opt into email updates when a new patron signs up for a mug club.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
