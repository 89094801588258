import { useMemo } from 'react';
import { Box, Progress } from '@chakra-ui/react';
import { TOTAL_STEPS, useClubCreatorContext } from '../Context';

interface Props {
  children: React.ReactNode;
}

export default function Step({ children }: Props): JSX.Element {
  const { step } = useClubCreatorContext();
  const progressValue = useMemo(() => {
    return ((step - 1) / (TOTAL_STEPS - 1)) * 100;
  }, [step]);

  return (
    <Box maxWidth={640} m="auto" mt={4}>
      {step !== TOTAL_STEPS && (
        <Progress
          value={progressValue}
          colorScheme="green"
          hasStripe
          mb={4}
          size="sm"
        />
      )}

      <Box mb={8}>{children}</Box>
    </Box>
  );
}
