import {
  Box,
  MenuDivider,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { OptionsMenu, PatronForm, PatronFormData } from '../../../components';
import AddToClub from './AddToClub';
import ArchivePatron from './ArchivePatron';
import BenefitsTracker from './BenefitsTracker';
import ClubSelect from './ClubSelect';
import MembershipManager from './MembershipManager';
import Notes from './Notes';
import RecordVisit from './RecordVisit';
import { updatePatron } from '../../../api';
import { EditIcon, File, PlusIcon, User } from '../../../assets';
import { Features, IBrewery, IClub, IPatron } from '../../../types';

interface IProps {
  brewery: IBrewery;
  clubs: IClub[];
  onClubChange: (clubId: string) => void;
  patron?: IPatron;
  selectedClub?: IClub;
}

export default function PatronActions({
  brewery,
  clubs,
  onClubChange,
  patron,
  selectedClub,
}: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isMembershipManagerOpen,
    onOpen: onOpenMembershipManager,
    onClose: onCloseMembershipManager,
  } = useDisclosure();
  const {
    isOpen: isAddToClubOpen,
    onOpen: onOpenAddToClub,
    onClose: onCloseAddToClub,
  } = useDisclosure();
  const {
    isOpen: isNotesOpen,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();
  const toast = useToast();
  const { id, features } = brewery;

  const showMembershipManager =
    isMembershipManagerOpen && selectedClub && patron;
  const showAddToClub = isAddToClubOpen && patron;

  async function handleEditPatron(values: PatronFormData): Promise<void> {
    try {
      await updatePatron(patron!.id, values);

      toast({
        description: 'Patron updated.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating this patron. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <Box>
      <PatronForm
        club={selectedClub}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleEditPatron}
        patron={patron}
      />

      {showMembershipManager && (
        <MembershipManager
          club={selectedClub}
          isOpen={isMembershipManagerOpen}
          onClose={onCloseMembershipManager}
        />
      )}

      {showAddToClub && (
        <AddToClub
          clubs={clubs}
          isOpen={isAddToClubOpen}
          onClose={onCloseAddToClub}
          onClubChange={onClubChange}
          patron={patron}
        />
      )}

      <Notes isOpen={isNotesOpen} onClose={onCloseNotes} patron={patron} />

      <Box display="flex" alignItems="center" gap={2}>
        {patron && selectedClub && (
          <>
            {selectedClub.rewards.length > 0 && (
              <RecordVisit
                breweryId={id}
                clubs={clubs}
                patronId={patron.id}
                selectedClub={selectedClub}
              />
            )}

            {features?.includes(Features.BENEFITS_TRACKING) && (
              <BenefitsTracker
                patronId={patron.id}
                selectedClub={selectedClub}
              />
            )}
          </>
        )}

        {patron && (
          <ClubSelect
            clubs={clubs}
            onChange={onClubChange}
            patronId={patron.id}
          />
        )}

        <OptionsMenu>
          <MenuItem icon={<User />} onClick={onOpen}>
            Edit Patron Details
          </MenuItem>
          {selectedClub && (
            <MenuItem icon={<EditIcon />} onClick={onOpenMembershipManager}>
              Update Membership
            </MenuItem>
          )}
          {patron && (
            <>
              <MenuItem icon={<File />} onClick={onOpenNotes}>
                Notes
              </MenuItem>
              <MenuItem icon={<PlusIcon />} onClick={onOpenAddToClub}>
                Add to Club
              </MenuItem>
            </>
          )}
          <MenuDivider />
          {patron && <ArchivePatron patron={patron} />}
        </OptionsMenu>
      </Box>
    </Box>
  );
}
