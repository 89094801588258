import { ClubStatus } from '../../types';

export default function getClubStatusDescription(status: ClubStatus): string {
  switch (status) {
    case ClubStatus.DRAFT:
      return 'This club is a draft and not yet active. New members can be added, but their visits cannot be tracked.';
    case ClubStatus.ACTIVE:
      return 'This club is active. New members may be added and their visits can be tracked.';
    case ClubStatus.COMPLETE:
      return 'This club is complete. New members cannot be added and member visits cannot be tracked.';
    default:
      return '';
  }
}
