import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import ClubsActions from './ClubsActions';
import ClubsTable from './ClubsTable';
import { ClubCreator, Layout, OnboardingFlow } from '../../components';
import { useFetchClubs } from '../../hooks';
import { useGetBrewery } from '../../contexts/Brewery';

const Clubs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isWelcomePromptOpen,
    onOpen: onWelcomePromptOpen,
    onClose: onWelcomePromptClose,
  } = useDisclosure();
  const [brewery] = useGetBrewery();
  const [clubs, isLoading] = useFetchClubs(brewery?.id);

  useEffect(() => {
    if (!brewery) {
      return;
    }

    const welcomePrompt = window.localStorage.getItem('welcomePrompt');

    if (isLoading === false && clubs.length === 0 && !welcomePrompt) {
      window.localStorage.setItem('welcomePrompt', 'true');
      onWelcomePromptOpen();
    }
  }, [brewery, isLoading, clubs, onWelcomePromptOpen]);

  return (
    <Layout
      ActionsComponent={
        <ClubsActions brewery={brewery} clubs={clubs} onClick={onOpen} />
      }
      pageDescription="Find, update, and create new mug clubs."
      pageHeading="Clubs"
    >
      <OnboardingFlow
        isOpen={isWelcomePromptOpen}
        onClose={onWelcomePromptClose}
        onComplete={onOpen}
      />

      <ClubCreator isOpen={isOpen} onClose={onClose} />

      <ClubsTable clubs={clubs} onEmptyStateClick={onOpen} />
    </Layout>
  );
};

export default Clubs;
