import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { updateClubConfig } from '../../../../../api';
import { HelpCircle } from '../../../../../assets';
import { IClub } from '../../../../../types';

interface IProps {
  club: IClub;
}

export default function RenewalReminders({ club }: IProps): JSX.Element {
  const toast = useToast();

  async function handleChangeRenewalReminders(checked: boolean) {
    try {
      await updateClubConfig(club.id, {
        hasRenewalReminders: checked,
      });

      toast({
        description: 'Club renewal reminders updated.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating the club settings. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <>
      <FormControl>
        <FormLabel alignItems="center" display="flex" gap={2} mb={1}>
          Membership Renewal Email Reminders{' '}
          <Tooltip
            hasArrow
            label="When this option is enabled, patrons will receive two emails before their membership expires reminding them to renew. The first email will be sent 30 days before expiration and the second email will be sent 14 days from expiration."
          >
            <Box as="span" mt="-3px">
              <HelpCircle color="gray.500" />
            </Box>
          </Tooltip>
        </FormLabel>
        <FormHelperText mt={0} mb={2}>
          Remind patrons to renew their membership before it expires.
        </FormHelperText>

        <Switch
          defaultChecked={club.hasRenewalReminders}
          colorScheme="green"
          onChange={({ currentTarget }) =>
            handleChangeRenewalReminders(currentTarget.checked)
          }
        />
      </FormControl>
    </>
  );
}
