import { createContext, useContext, useMemo, useState } from 'react';
import { FormValues, getInitialFormValues } from './utils';

export enum ClubType {
  FIXED = 'fixed',
  SUBSCRIPTION = 'subscription',
}

const ClubCreatorContext = createContext<Context | undefined>(undefined);

interface Context {
  clubType: ClubType | undefined;
  formValues: FormValues;
  step: number;
  stepForward: () => void;
  stepBackward: () => void;
  updateClubType: (type: ClubType) => void;
  updateFormValues: (values: Partial<FormValues>) => void;
  updateStep: (step: number) => void;
}
interface Props {
  children: React.ReactNode;
}

const INITIAL_STEP = 1;
export const TOTAL_STEPS = 9;

function ClubCreatorProvider({ children }: Props): JSX.Element {
  const initialFormValues = useMemo(() => getInitialFormValues(), []);
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [clubType, setClubType] = useState<ClubType | undefined>();
  const [step, setStep] = useState<number>(INITIAL_STEP);

  function updateFormValues(values: Partial<FormValues>) {
    if (!formValues) {
      return;
    }

    setFormValues({
      ...formValues,
      ...values,
    });
  }

  function updateClubType(type: ClubType) {
    setClubType(type);
  }

  function updateStep(step: number) {
    setStep(step);
  }

  function stepForward() {
    setStep((prevStep) => prevStep + 1);
  }

  function stepBackward() {
    setStep((prevStep) => prevStep - 1);
  }

  const value: Context = {
    clubType,
    formValues,
    step,
    stepForward,
    stepBackward,
    updateClubType,
    updateFormValues,
    updateStep,
  };

  return (
    <ClubCreatorContext.Provider value={value}>
      {children}
    </ClubCreatorContext.Provider>
  );
}

function useClubCreatorContext(): Context {
  const context = useContext(ClubCreatorContext);

  if (!context) {
    throw new Error(
      'useClubCreatorContext must be used within a ClubCreatorProvider',
    );
  }

  return context;
}

export { ClubCreatorProvider, useClubCreatorContext };
