import { useState } from 'react';
import { Box, Button, Input, Text } from '@chakra-ui/react';

interface Props {
  benefit: string;
  isEditable?: boolean;
  index: number;
  onRemove: (index: number) => void;
  onUpdate: (benefit: string, index: number) => void;
}

export default function Benefit({
  benefit,
  index,
  isEditable,
  onRemove,
  onUpdate,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(benefit);

  function handleOnSave() {
    if (!value) {
      return;
    }

    onUpdate(value, index);
    setIsEditing(false);
  }

  function handleOnCancel() {
    setValue(benefit);
    setIsEditing(false);
  }

  return (
    <Box
      alignItems="center"
      border="1px solid"
      borderColor="gray.200"
      display="flex"
      justifyContent="space-between"
      gap={4}
      mb={2}
      px={4}
      py={3}
      rounded="md"
      shadow="sm"
    >
      <Box alignItems="center" display="flex" flex={1} gap={1}>
        <Text fontWeight="semibold" color="gray.500">
          {index + 1}.{' '}
        </Text>
        {isEditing ? (
          <Input
            rounded="md"
            value={value}
            size="sm"
            onChange={({ currentTarget }) => setValue(currentTarget.value)}
          />
        ) : (
          <Text>{benefit}</Text>
        )}
      </Box>

      {isEditable && !isEditing && (
        <Box display="flex" gap={2}>
          <Button
            size="xs"
            variant="outline"
            color="gray.400"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
          <Button
            size="xs"
            variant="outline"
            color="red.400"
            onClick={() => onRemove(index)}
          >
            Remove
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box display="flex" gap={2}>
          <Button
            size="xs"
            variant="outline"
            color="green.400"
            onClick={handleOnSave}
          >
            Save
          </Button>
          <Button
            size="xs"
            variant="outline"
            color="gray.400"
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
}
