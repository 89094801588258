import { useCallback, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { useToast } from '@chakra-ui/react';
import { db } from '../firebase';
import { IMembership } from '../types';

export default function useFetchPatronMembershipByClub(
  patronId: string | undefined,
  clubId: string | undefined,
  listener?: boolean,
): [IMembership | undefined, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [membership, setMembership] = useState<IMembership>();
  const toast = useToast();

  const fetchMembership = useCallback(async () => {
    try {
      const membershipsCollection = collection(db, 'memberships');
      const q = query(
        membershipsCollection,
        where('patronId', '==', patronId),
        where('clubId', '==', clubId),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const membership = {
          id: doc.id,
          ...doc.data(),
        } as IMembership;

        setMembership(membership);
      });

      setIsLoading(false);
    } catch (error) {
      toast({
        description:
          "An error fetching the patron's membership. Please try again.",
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [patronId, clubId, toast]);

  useEffect(() => {
    if (patronId && clubId) {
      if (listener) {
        const membershipsCollection = collection(db, 'memberships');
        const q = query(
          membershipsCollection,
          where('patronId', '==', patronId),
          where('clubId', '==', clubId),
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const membership = {
              id: doc.id,
              ...doc.data(),
            } as IMembership;

            setMembership(membership);
            setIsLoading(false);
          });
        });

        return () => unsubscribe();
      } else {
        fetchMembership();
      }
    }
  }, [patronId, clubId, listener, fetchMembership]);

  return [membership, isLoading];
}
