import { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import ConfirmationDialog from '../ConfirmationDialog';
import { updateClubStatus } from '../../../../../api';
import { ClubStatus, IClub } from '../../../../../types';

interface Props {
  club: IClub;
}

export default function ActivateClubButton({ club }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  async function handleOnConfirm() {
    setIsLoading(true);

    try {
      await updateClubStatus(club.id, ClubStatus.ACTIVE);

      toast({
        description: 'Your club has been activated.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description: 'Failed to activate your club. Please try again.',
        status: 'error',
        title: 'Uh Oh!',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        nextStatus={ClubStatus.ACTIVE}
        onClose={onClose}
        onConfirm={handleOnConfirm}
      />

      <FormControl mb={4}>
        <FormLabel>Activate Club</FormLabel>

        <Button size="sm" onClick={onOpen} isLoading={isLoading}>
          Activate
        </Button>

        <FormHelperText>
          Activate your club to begin adding members and tracking visits.
        </FormHelperText>
      </FormControl>
    </>
  );
}
