import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  Text,
} from '@chakra-ui/react';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function Loader({ isOpen, onClose }: IProps): JSX.Element {
  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody display="flex" flexDir="column" alignItems="center" py={12}>
          <Spinner color="green.500" size="xl" speed="1s" mb={6} />

          <Text mb={1} fontSize="lg" fontWeight="medium">
            Exporting Patrons to CSV
          </Text>

          <Text fontSize="sm" color="gray.500">
            This may take a minute depending on the club size.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
