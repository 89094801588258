import { Box, Text } from '@chakra-ui/react';
import Reward from './Reward';
import { IClub, IClubReward } from '../../../types';
import AddReward from './AddReward';

interface Props {
  club: IClub;
  isEditable?: boolean;
  onChange: (key: keyof IClub, value: IClubReward[]) => void;
}

export default function Rewards({
  club,
  isEditable,
  onChange,
}: Props): JSX.Element {
  const { rewards } = club;

  function handleOnRemove(id: string) {
    const updatedRewards = rewards.filter((reward) => reward.id !== id);

    onChange('rewards', updatedRewards);
  }

  function handleOnUpdate(reward: IClubReward) {
    const updatedRewards = rewards.map((r) => {
      if (r.id === reward.id) {
        return reward;
      }

      return r;
    });

    onChange('rewards', updatedRewards);
  }

  function handleOnAdd(reward: IClubReward) {
    const updatedRewards = [...rewards, reward];

    onChange('rewards', updatedRewards);
  }

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Rewards
      </Text>

      <Box mb={2}>
        {rewards
          .sort((a, b) => a.threshold - b.threshold)
          .map((reward) => (
            <Reward
              key={reward.id}
              reward={reward}
              isEditable={isEditable}
              onRemove={handleOnRemove}
              onUpdate={handleOnUpdate}
            />
          ))}
      </Box>

      {isEditable && <AddReward onAdd={handleOnAdd} />}
    </Box>
  );
}
