import { Box, FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import SectionHeading from './SectionHeading';
import SectionNavigation from './SectionNavigation';
import { useClubCreatorContext } from './Context';

export default function ClubTerms(): JSX.Element {
  const { formValues, updateFormValues, stepBackward, stepForward } =
    useClubCreatorContext();

  function handleOnSubmit() {
    updateFormValues({ terms: formValues.terms });
    stepForward();
  }

  return (
    <Box>
      <SectionHeading
        title="Club Terms and Conditions (Optional)"
        description="Enter any custom terms and conditions you want your members to agree to. Club terms will be displayed on your club's signup page."
      />

      <FormControl mb={6}>
        <FormLabel htmlFor="terms">Club Terms</FormLabel>
        <Textarea
          id="terms"
          onChange={({ currentTarget }) =>
            updateFormValues({ terms: currentTarget.value })
          }
          value={formValues.terms || ''}
        />
      </FormControl>

      <SectionNavigation onPrevious={stepBackward} onNext={handleOnSubmit} />
    </Box>
  );
}
