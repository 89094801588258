import { Box, Text } from '@chakra-ui/react';
import { ClubStatus, IClub } from '../../types';
import { InfoCircleIcon } from '../../assets';

interface Props {
  club: IClub;
}

export default function EditInfo({ club }: Props): JSX.Element {
  const { status } = club;
  const isClubDraft = status === ClubStatus.DRAFT;
  const isClubComplete = status === ClubStatus.COMPLETE;

  return (
    <Box border="2px solid" borderColor="blue.400" rounded="md" px={4} py={2}>
      <Box
        alignItems="center"
        color="blue.400"
        display="flex"
        fontSize="lg"
        fontWeight="medium"
        mb={1}
        gap={2}
      >
        <InfoCircleIcon />
        <Text>Note</Text>
      </Box>

      <Text color="gray.500">
        {isClubDraft
          ? 'This club is in draft mode. You can edit all fields.'
          : isClubComplete
          ? 'This club is complete. It is no longer editable.'
          : 'This club is active. You can edit select fields.'}
      </Text>
    </Box>
  );
}
