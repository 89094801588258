import { doc, updateDoc } from 'firebase/firestore';
import { Box, Heading, useToast } from '@chakra-ui/react';
import { db } from '../../firebase';
import { BreweryForm } from '../../components';
import { IBrewery } from '../../types';

interface ISubmitValues {
  name: string;
  website: string;
  phoneNumber: string;
}

interface IProps {
  brewery: IBrewery;
}

const BreweryTab = ({ brewery }: IProps): JSX.Element => {
  const toast = useToast();

  async function handleSubmit(values: ISubmitValues) {
    const breweryDoc = doc(db, 'breweries', brewery.id);

    try {
      await updateDoc(breweryDoc, { ...values });

      toast({
        description: 'Brewery details updated',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description: 'An error occurred while updating brewery details',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Brewery Details
      </Heading>

      <BreweryForm
        brewery={brewery}
        submitButtonText="Update Brewery"
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default BreweryTab;
