import { Box, Text } from '@chakra-ui/react';

interface IProps {
  children?: React.ReactNode;
  label?: string;
  value?: string;
}

export default function Detail({
  children,
  label,
  value,
}: IProps): JSX.Element {
  return (
    <Box alignSelf="stretch">
      {children}
      {label && (
        <Text fontWeight="medium" mb={0} fontSize="xs" color="gray.600">
          {label}
        </Text>
      )}
      {value && <Text fontSize="sm">{value}</Text>}
    </Box>
  );
}
