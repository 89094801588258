import { Avatar, Box, Text } from '@chakra-ui/react';
import { IPatron } from '../../types';

interface IProps {
  patron: IPatron;
}

export default function PatronName({ patron }: IProps): JSX.Element {
  const fullName = `${patron.firstName} ${patron.lastName}`;

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Avatar name={fullName} size="sm" />
      <Box>
        <Text fontWeight="semibold">{fullName}</Text>
        <Text fontSize="sm" color="gray.600">
          {patron.email}
        </Text>
      </Box>
    </Box>
  );
}
