import { useEffect } from 'react';
import { FormHelperText, Select } from '@chakra-ui/react';
import { useGetAvailableMugsByClub } from '../../../../../hooks';

interface Props {
  capacity: number;
  clubId: string;
}

export default function MugNumberSelect({
  capacity,
  clubId,
}: Props): JSX.Element {
  const [mugs, isLoading] = useGetAvailableMugsByClub(clubId);

  useEffect(() => {}, [clubId]);

  return (
    <>
      <FormHelperText mt={0} mb={2}>
        Select a mug number from a list of available mugs.
      </FormHelperText>
      <Select defaultValue="default" isDisabled={isLoading}>
        <option value="default" disabled>
          {isLoading ? 'Loading...' : 'Select Mug Number'}
        </option>
        {mugs.map(({ number, patron }) => (
          <option key={number} value={number} disabled={Boolean(patron)}>
            {number} - {patron ? 'Not Available' : 'Available'}
          </option>
        ))}
      </Select>
    </>
  );
}
