import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import WelcomeStep from './WelcomeStep';
import HelpStep from './HelpStep';
import GetStartedStep from './GetStartedStep';
import { ArrowLeftIcon, ArrowRightIcon } from '../../assets';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export default function OnboardingFlow({
  isOpen,
  onClose,
  onComplete,
}: IProps): JSX.Element {
  const [step, setStep] = useState<number>(0);

  function getStepComponent() {
    switch (step) {
      case 0:
        return <WelcomeStep />;
      case 1:
        return <HelpStep />;
      case 2:
        return <GetStartedStep />;
      default:
        return <WelcomeStep />;
    }
  }

  function handleNextStep() {
    if (step === 2) {
      onClose();
      onComplete();
      return;
    }

    setStep((prevStep) => prevStep + 1);
  }

  function handlePreviousStep() {
    setStep((prevStep) => prevStep - 1);
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody fontSize="md">{getStepComponent()}</ModalBody>

        <ModalFooter>
          {step !== 0 && (
            <Button
              colorScheme="gray"
              mr={2}
              onClick={handlePreviousStep}
              variant="outline"
            >
              <ArrowLeftIcon />
            </Button>
          )}

          <Button
            colorScheme="green"
            onClick={handleNextStep}
            rightIcon={<ArrowRightIcon />}
          >
            {step === 2 ? 'Create Your First Club' : 'Next'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
