import { useCallback, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  Timestamp,
} from 'firebase/firestore';
import dayjs from 'dayjs';
import { useToast } from '@chakra-ui/react';
import { db } from '../firebase';
import { IMembership } from '../types';

export default function useFetchPatronMembershipsByExpirationDate(
  clubId: string | undefined,
  daysFromToday: number,
): [IMembership[] | undefined, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [memberships, setMemberships] = useState<IMembership[]>();
  const toast = useToast();

  const fetchMemberships = useCallback(async () => {
    const today = Timestamp.fromDate(dayjs().toDate());
    const futureDate = dayjs().add(daysFromToday, 'day').toDate();

    try {
      const membershipsCollection = collection(db, 'memberships');
      const q = query(
        membershipsCollection,
        where('clubId', '==', clubId),
        where('isActive', '==', true),
        where('expiresAt', '>=', today),
        where('expiresAt', '<=', futureDate),
        orderBy('expiresAt', 'desc'),
      );
      const querySnapshot = await getDocs(q);
      const memberships: IMembership[] = [];

      querySnapshot.forEach((doc) => {
        const membership = {
          id: doc.id,
          ...doc.data(),
        } as IMembership;

        memberships.push(membership);
      });

      setMemberships(memberships);
      setIsLoading(false);
    } catch (error) {
      toast({
        description: 'An error fetching memberships. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [clubId, daysFromToday, toast]);

  useEffect(() => {
    if (clubId) {
      fetchMemberships();
    }
  }, [clubId, fetchMemberships]);

  return [memberships, isLoading];
}
