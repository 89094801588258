import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import LoginAlert from './LoginAlert';
import { MugClubLogo, PasswordInput } from '../../components';
import { auth } from '../../firebase';
import { validateAdminUser } from '../../api';
import { useRouteAuthenticatedUser } from '../../hooks';
import { AtSign } from '../../assets';

export default function LoginPage(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  useRouteAuthenticatedUser();

  async function handleOnSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    if (!email || !password) {
      toast({
        title: 'Uh Oh',
        description: 'One or more fields are missing.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    setIsLoading(true);
    setHasError(false);

    try {
      const { data } = await validateAdminUser({ email });
      const { isValid } = data;

      if (!isValid) {
        setHasError(true);
        setIsLoading(false);
        return;
      }

      await signInWithEmailAndPassword(auth, email, password);

      navigate('/auth/clubs');
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  }

  return (
    <Box
      bg="gray.100"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box bg="white" rounded="lg" shadow="md" p={6} w={384}>
        <Box textAlign="center" mb={6}>
          <MugClubLogo mb={6} />
          <Text fontSize="2xl" fontWeight="semibold">
            Brewery Login
          </Text>
        </Box>

        <Box mb={8}>
          <form onSubmit={handleOnSubmit}>
            {hasError && (
              <Box mb={4}>
                <LoginAlert />
              </Box>
            )}

            <FormControl mb={4}>
              <FormLabel htmlFor="email" fontSize="sm">
                Email
              </FormLabel>

              <InputGroup>
                <Input
                  id="email"
                  type="email"
                  placeholder="Email"
                  isInvalid={hasError}
                  onChange={({ target }) => setEmail(target.value)}
                  borderColor="gray.200"
                />

                <InputRightElement mr="7px">
                  <AtSign color="gray.500" />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel htmlFor="password" fontSize="sm">
                Password
              </FormLabel>
              <PasswordInput
                id="password"
                isInvalid={hasError}
                onChange={setPassword}
              />
            </FormControl>

            <Button
              w="100%"
              type="submit"
              bg="green.500"
              color="white"
              isLoading={isLoading}
            >
              Login
            </Button>
          </form>
        </Box>

        <Box fontSize="sm" textAlign="center">
          <Text color="gray.500">
            Need to reset your password?{' '}
            <Link as={ReactRouterLink} to="/request-password-reset">
              Reset it here
            </Link>
            .
          </Text>

          <Text color="gray.400" my={2}>
            or
          </Text>

          <Text color="gray.500">
            Don't have an account?{' '}
            <Link as={ReactRouterLink} to="/get-started">
              Create an account here
            </Link>
            .
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
