import { memo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ClubStatusBadge } from '../../../../../components';
import { getClubStatusDescription } from '../../../../../utils/clubs';
import { IClub } from '../../../../../types';

interface Props {
  club: IClub;
}

function CurrentStatus({ club }: Props): JSX.Element {
  const description = getClubStatusDescription(club.status);

  return (
    <Box>
      <Text fontWeight="medium" mb={1}>
        Current Status
      </Text>

      <Box mb={2}>
        <ClubStatusBadge status={club.status} />
      </Box>

      <Text color="gray.500" fontSize="sm">
        {description}
      </Text>
    </Box>
  );
}

export default memo(CurrentStatus);
