import { useMemo } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { ClubStatus, IClub } from '../../../../types';

interface IProps {
  clubs?: IClub[];
  hasError?: boolean;
  onChange: (clubId: string) => void;
}

export default function ClubSelect({
  clubs,
  hasError,
  onChange,
}: IProps): JSX.Element {
  const filteredClubs = useMemo(() => {
    return clubs?.filter(
      ({ status }) =>
        status === ClubStatus.ACTIVE || status === ClubStatus.DRAFT,
    );
  }, [clubs]);

  return (
    <FormControl isRequired isInvalid={hasError}>
      <FormLabel htmlFor="club" fontSize="xs">
        Mug Club
      </FormLabel>
      <Select
        id="club"
        onChange={({ target }) => onChange(target.value)}
        borderColor="green.200"
      >
        <option disabled selected>
          Select Mug Club
        </option>
        {filteredClubs?.map((club) => (
          <option key={club.id} value={club.id}>
            {club.name}
          </option>
        ))}
      </Select>
      <FormHelperText>
        Only clubs that are of active or draft status are shown.
      </FormHelperText>
      <FormErrorMessage>This field is required.</FormErrorMessage>
    </FormControl>
  );
}
