import { Layout } from '../../components';
import PatronsActions from './PatronsActions';
import PatronsTable from './PatronsTable';
import { useFetchClubs, useFetchPatrons } from '../../hooks';
import { useGetBrewery } from '../../contexts';
import { IClub } from '../../types';

function clubHasMugNumbers(clubs: IClub[]): boolean {
  return clubs.some((club) => club.hasMugNumbers);
}

const Patrons = (): JSX.Element => {
  const [brewery, isLoadingBrewery] = useGetBrewery();
  const [clubs, isLoadingClubs] = useFetchClubs(brewery?.id);
  const [patrons, isLoadingPatrons] = useFetchPatrons(brewery.id);

  const showMugNumbers = clubHasMugNumbers(clubs);

  return (
    <Layout
      isLoading={isLoadingBrewery}
      pageDescription="Search for and add new patrons."
      pageHeading="Patrons"
      ActionsComponent={<PatronsActions brewery={brewery} clubs={clubs} />}
    >
      <PatronsTable
        clubs={clubs}
        patrons={patrons}
        isLoading={isLoadingClubs || isLoadingPatrons}
        showMugNumbers={showMugNumbers}
      />
    </Layout>
  );
};

export default Patrons;
