export default function firebaseURL(): string {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_FIREBASE_CONFIG !== 'development'
  ) {
    return 'http://127.0.0.1:5001/mug-club-dev-8f657/us-central1';
  }

  if (process.env.REACT_APP_FIREBASE_CONFIG === 'development') {
    return 'https://us-central1-mug-club-dev-8f657.cloudfunctions.net';
  }

  if (process.env.NODE_ENV === 'production') {
    return 'https://us-central1-mug-club-34030.cloudfunctions.net';
  }

  return '';
}
