import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { IBrewery } from '../../types';

const BREWERY_COLLECTION = 'breweries';

export async function createBrewery(
  data: Omit<IBrewery, 'id'>,
): Promise<string> {
  const breweriesCollection = collection(db, BREWERY_COLLECTION);

  const ref = await addDoc(breweriesCollection, data);

  return ref.id;
}

export async function updateBrewery(
  id: string,
  data: Partial<Omit<IBrewery, 'id'>>,
): Promise<void> {
  const breweryRef = getBreweryRef(id);

  await updateDoc(breweryRef, data);
}

export async function updateBreweryOnboardingSurvey(
  id: string,
  data: IBrewery['onboardingSurvey'],
): Promise<void> {
  const breweryRef = getBreweryRef(id);

  await updateDoc(breweryRef, {
    onboardingSurvey: data,
  });
}

export async function fetchBreweryById(
  id: string,
): Promise<IBrewery | undefined> {
  const breweryRef = getBreweryRef(id);
  const breweryDoc = await getDoc(breweryRef);

  if (!breweryDoc.exists()) {
    return;
  }

  return {
    id: breweryDoc.id,
    ...breweryDoc.data(),
  } as IBrewery;
}

export async function fetchBreweryByUserUid(
  uid: string,
): Promise<IBrewery | undefined> {
  const breweryCollection = collection(db, BREWERY_COLLECTION);
  const q = query(breweryCollection, where('adminUids', 'array-contains', uid));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return;
  }

  const breweryDoc = querySnapshot.docs[0];

  return {
    id: breweryDoc.id,
    ...breweryDoc.data(),
  } as IBrewery;
}

function getBreweryRef(id: string) {
  return doc(db, `${BREWERY_COLLECTION}/${id}`);
}
