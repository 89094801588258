import { SyntheticEvent } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from '@chakra-ui/react';
import { useClubCreatorContext } from '../Context';

export default function SubscriptionClubInputs(): JSX.Element {
  const { formValues, updateFormValues } = useClubCreatorContext();
  const { membershipDurationMonths } = formValues;

  function handleOnChange({ currentTarget }: SyntheticEvent<HTMLInputElement>) {
    const { id, value } = currentTarget;

    updateFormValues({
      [id]: value,
    });
  }

  return (
    <VStack spacing={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="membershipDurationMonths">
          Membership Duration in Months
        </FormLabel>
        <Input
          id="membershipDurationMonths"
          type="number"
          placeholder="Membership Duration in Months"
          value={membershipDurationMonths}
          onChange={handleOnChange}
        />
        <FormHelperText>
          How long will a member's subscription last? Typical subscription
          lengths are 6 and 12 months.
        </FormHelperText>
      </FormControl>
    </VStack>
  );
}
