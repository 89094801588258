import { Box, Text } from '@chakra-ui/react';
import { ThumbsUp } from '../../assets';

interface IProps {
  message: string;
}

export default function SuccessMessage({ message }: IProps): JSX.Element {
  return (
    <Box
      p={4}
      border="2px solid"
      borderColor="green.600"
      bg="green.50"
      borderRadius={4}
      mb={8}
    >
      <Box display="flex" gap={2} mb={2} alignItems="center">
        <ThumbsUp color="green.600" />
        <Text fontWeight="medium" color="green.500">
          Success
        </Text>
      </Box>
      <Text whiteSpace="pre-line">{message}</Text>
    </Box>
  );
}
