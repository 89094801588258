import { useEffect, useState } from 'react';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { ClubDetails, Drawer } from '../../../../components';
import { db } from '../../../../firebase';
import { IClub, IClubReward } from '../../../../types';

interface IProps {
  club: IClub;
  isOpen: boolean;
  onClose: () => void;
}

export default function EditClub({
  club,
  isOpen,
  onClose,
}: IProps): JSX.Element | null {
  const [values, setValues] = useState<IClub>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setValues(club);
  }, [club]);

  function handleOnChange(
    key: keyof IClub,
    value: string | string[] | null | number | IClubReward[] | Timestamp,
  ): void {
    if (!values) {
      return;
    }

    setValues({
      ...values,
      [key]: value,
    });
  }

  if (!values) {
    return null;
  }

  async function handleSubmit(): Promise<void> {
    if (!values) {
      return;
    }

    setIsLoading(true);

    try {
      const clubRef = doc(db, 'clubs', club.id);
      const { id, ...rest } = values;
      const clubData: Omit<IClub, 'id'> = {
        ...rest,
      };

      await updateDoc(clubRef, clubData);

      handleOnClose();
    } catch (err) {
      console.error(err);
    }
  }

  function handleOnClose(): void {
    setIsLoading(false);
    onClose();
  }

  return (
    <Drawer
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
      size="lg"
      title="Edit Club Details"
    >
      <ClubDetails club={values} onChange={handleOnChange} />
    </Drawer>
  );
}
