import { Box } from '@chakra-ui/react';
import { Loader } from '../../components';

export default function LoadingState(): JSX.Element {
  return (
    <Box
      border="1px solid"
      borderColor="green.50"
      rounded="md"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="74vh"
    >
      <Loader />
    </Box>
  );
}
