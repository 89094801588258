import { Box, Heading } from '@chakra-ui/react';
import ClubMetrics from './ClubMetrics';
import RecordsDataTable from './RecordsDataTable';
import UpcomingPatronExpirations from './UpcomingPatronExpirations';
import { IClub, IPatron, IPatronReward, IRecord } from '../../../types';

interface IProps {
  club: IClub;
  patrons?: IPatron[];
  records: IRecord[];
  rewards: IPatronReward[];
}

export default function ClubOverview({
  club,
  patrons = [],
  records,
  rewards,
}: IProps): JSX.Element {
  const clubHasRewards = club.rewards.length > 0;
  const clubHasRollingMemberships = Boolean(club.membershipDurationMonths);

  return (
    <>
      <Heading as="h2" fontSize="lg" mb={4}>
        Dashboard
      </Heading>

      <Box mb={6}>
        <ClubMetrics
          club={club}
          patrons={patrons}
          recordsCount={records.length}
          rewards={rewards}
        />
      </Box>

      {clubHasRewards && (
        <Box mb={6}>
          <RecordsDataTable patrons={patrons} records={records} />
        </Box>
      )}

      {clubHasRollingMemberships && (
        <Box mb={6}>
          <UpcomingPatronExpirations club={club} patrons={patrons} />
        </Box>
      )}
    </>
  );
}
