import { useCallback, useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IPatronReward } from '../types';

export default function useFetchRewardsByClub(
  clubId?: string,
): [IPatronReward[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rewardsData, setRewardsData] = useState<IPatronReward[]>([]);

  const fetchRewards = useCallback(async () => {
    if (!clubId) {
      setIsLoading(false);
      return;
    }

    try {
      const recordsCollection = collection(db, 'rewards');
      const q = query(
        recordsCollection,
        where('clubRef', '==', doc(db, 'clubs', clubId)),
      );
      const rewardDocs = await getDocs(q);
      const rewards: IPatronReward[] = [];

      rewardDocs.forEach((doc) => {
        rewards.push({
          id: doc.id,
          ...doc.data(),
        } as IPatronReward);
      });

      setRewardsData(rewards);
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [clubId]);

  useEffect(() => {
    fetchRewards();
  }, [clubId, fetchRewards]);

  return [rewardsData, isLoading];
}
