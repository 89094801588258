import { Avatar, Box, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react';
import { useFetchPatron } from '../../../../../hooks';

interface Props {
  patronId: string;
}

export default function PatronName({ patronId }: Props): JSX.Element {
  const [patron, isLoading] = useFetchPatron(patronId);
  const fullName = `${patron?.firstName} ${patron?.lastName}`;

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <SkeletonCircle isLoaded={!isLoading}>
        <Avatar size="sm" name={fullName} />
      </SkeletonCircle>

      <Skeleton isLoaded={!isLoading}>
        <Box>
          <Text fontSize="sm" fontWeight="semibold">
            {fullName}
          </Text>
          <Text fontSize="xs" textColor="gray.600">
            {patron?.email}
          </Text>
        </Box>
      </Skeleton>
    </Box>
  );
}
