import dayjs from 'dayjs';
import { IRecord, IPatron } from '../../../../types';

interface IRecordWithPatronData extends IRecord {
  patron: IPatron;
}

export enum DataRange {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getRecordsByDate(date: string, records: IRecord[]): IRecord[] {
  const d = dayjs(date);
  return records.filter((record) => {
    const recordDate = dayjs(record.createdAt.toDate()).format('YYYY-MM-DD');

    if (d.isSame(recordDate)) {
      return record;
    }

    return undefined;
  });
}

export function mapRecordsToPatrons(
  records: IRecord[],
  patrons: IPatron[],
): IRecordWithPatronData[] {
  return records.map((record) => {
    const patron = patrons.find((patron) => patron.id === record.patronRef.id);

    return {
      ...record,
      patron,
    } as IRecordWithPatronData;
  });
}

export function getLabelsByRange(range?: DataRange): string[] {
  if (!range) {
    return [];
  }

  if (range === DataRange.YEAR) {
    const currentMonth = dayjs().get('month');

    return MONTHS.slice(0, currentMonth + 1);
  }

  const dates = [];

  if (range === DataRange.MONTH) {
    for (let i = 0; i <= 30; i++) {
      const date = dayjs().subtract(i, 'day').format('MM/DD');

      dates.unshift(date);
    }
  }

  if (range === DataRange.WEEK) {
    for (let i = 0; i <= 7; i++) {
      const date = dayjs().subtract(i, 'day').format('MM/DD');

      dates.unshift(date);
    }
  }

  return dates;
}

export function groupRecordsByRange(
  records: IRecord[],
  range?: DataRange,
): number[] {
  if (!range) {
    return [];
  }

  if (range === DataRange.YEAR) {
    return groupRecordsByMonthCreated(records);
  }

  if (range === DataRange.MONTH) {
    return groupLastMonthsRecords(records);
  }

  return groupLastWeeksRecords(records);
}

function groupRecordsByMonthCreated(records: IRecord[]): number[] {
  const recordsByMonth: Record<number, number> = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
  };

  records.forEach((record) => {
    const monthIdx = record.createdAt.toDate().getMonth();

    recordsByMonth[monthIdx] += 1;
  });

  return Object.values(recordsByMonth);
}

function groupLastMonthsRecords(records: IRecord[]): number[] {
  const recordsByDay: Record<string, number> = {};

  for (let i = 0; i <= 30; i++) {
    const date = dayjs().subtract(i, 'day').format('MM-DD-YYYY');

    recordsByDay[date] = 0;
  }

  records.forEach((record) => {
    const date = dayjs(record.createdAt.toDate()).format('MM-DD-YYYY');

    if (recordsByDay.hasOwnProperty(date)) {
      recordsByDay[date] += 1;
    }
  });

  return Object.values(recordsByDay).reverse();
}

function groupLastWeeksRecords(records: IRecord[]): number[] {
  const recordsByDay: Record<string, number> = {};

  for (let i = 0; i <= 7; i++) {
    const date = dayjs().subtract(i, 'day').format('MM-DD-YYYY');

    recordsByDay[date] = 0;
  }

  records.forEach((record) => {
    const date = dayjs(record.createdAt.toDate()).format('MM-DD-YYYY');

    if (recordsByDay.hasOwnProperty(date)) {
      recordsByDay[date] += 1;
    }
  });

  return Object.values(recordsByDay).reverse();
}
