import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react';

export default function CompletedClubAlert(): JSX.Element {
  return (
    <Box mb={4}>
      <Alert status="warning" rounded="md">
        <AlertIcon />
        <AlertTitle fontSize="sm">Completed Club</AlertTitle>
        <AlertDescription fontSize="sm">
          Cannot update membership as associated club is completed.
        </AlertDescription>
      </Alert>
    </Box>
  );
}
