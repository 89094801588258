import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
} from '@chakra-ui/react';
import RewardsDetailRow from './RewardsDetailRow';
import { EmptyState } from '../../../../../components';
import { IClubReward, IPatronReward } from '../../../../../types';

interface Props {
  clubReward: IClubReward;
  isOpen: boolean;
  onClose: () => void;
  patronRewards: IPatronReward[];
}

export default function RewardDetailsModal({
  clubReward,
  isOpen,
  onClose,
  patronRewards,
}: Props): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{clubReward.description}</Text>
          <Text fontSize="sm" textColor="gray.500">
            Earned at {clubReward.threshold} visits
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        {isOpen && (
          <ModalBody pt={0}>
            {patronRewards.length ? (
              <Box
                borderBottom="1px solid"
                borderColor="gray.200"
                bg="white"
                display="grid"
                gridTemplateColumns="2fr 1fr 1fr"
                fontSize="xs"
                fontWeight="semibold"
                mb={2}
                pb={2}
                position="sticky"
                textColor="gray.600"
                textTransform="uppercase"
                top={0}
                zIndex={1}
              >
                <Text>Patron</Text>
                <Text>Earned At</Text>
                <Text>Redeemed At</Text>
              </Box>
            ) : (
              <EmptyState
                heading="No Rewards Found"
                description="No patron has earned this reward yet."
              />
            )}

            <Box>
              {patronRewards.map((patronReward) => (
                <RewardsDetailRow
                  key={patronReward.id}
                  patronReward={patronReward}
                />
              ))}
            </Box>
          </ModalBody>
        )}

        <ModalFooter>
          <Button mr={3} onClick={onClose} size="sm" variant="outline">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
