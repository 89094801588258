import { memo } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react';
import { ProductPlan } from '../../../../../types';

function ActiveClubLimitAlert(): JSX.Element {
  return (
    <Box mb={4}>
      <Alert status="warning" flexDir="column" rounded="md">
        <AlertIcon mb={2} />
        <AlertTitle fontSize="lg">Active Club Limit Reached</AlertTitle>
        <AlertDescription fontSize="sm">
          The {ProductPlan.STARTER} plan is limited to one active club at a
          time. Upgrade to a {ProductPlan.CORE} or {ProductPlan.COMPLETE} plan
          to enable more than one active club at a time.
        </AlertDescription>
      </Alert>
    </Box>
  );
}

export default memo(ActiveClubLimitAlert);
