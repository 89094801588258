import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ClubsTableRow from './ClubsTableRow';
import { EmptyState } from '../../../components';
import { ClubStatus, IClub } from '../../../types';

interface IParams {
  clubs: IClub[];
  onEmptyStateClick: () => void;
}

function filterCompletedClubs(clubs: IClub[]): IClub[] {
  return clubs.filter((club) => club.status !== ClubStatus.COMPLETE);
}

export default function ClubsTable({
  clubs,
  onEmptyStateClick,
}: IParams): JSX.Element {
  const [filteredClubs, setFilteredClubs] = useState<IClub[]>();
  const allClubsCompleted = useMemo(() => {
    return clubs.every((club) => club.status === ClubStatus.COMPLETE);
  }, [clubs]);
  const navigate = useNavigate();

  useEffect(() => {
    const filtered = filterCompletedClubs(clubs);
    setFilteredClubs(filtered);
  }, [clubs]);

  useEffect(() => {
    if (allClubsCompleted) {
      setFilteredClubs(clubs);
    }
  }, [allClubsCompleted, clubs]);

  if (!clubs.length) {
    return (
      <Box alignItems="center" display="flex" h="calc(100vh - 110px)">
        <EmptyState
          description={`You haven't created any clubs yet. Click "Create Club" to get started.`}
          heading="No Clubs Found"
          onClick={onEmptyStateClick}
          buttonText="Create Club"
        />
      </Box>
    );
  }

  function handleOnRowClick(url: string) {
    navigate(url);
  }

  function handleOnChange({ currentTarget }: ChangeEvent<HTMLInputElement>) {
    const { checked } = currentTarget;

    if (checked) {
      setFilteredClubs(clubs);
    } else {
      setFilteredClubs(filterCompletedClubs(clubs));
    }
  }

  return (
    <Box>
      {!allClubsCompleted && (
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mb={4}
        >
          <FormLabel mb={0} fontSize="sm">
            Show completed clubs?
          </FormLabel>
          <Switch colorScheme="green" onChange={handleOnChange} />
        </FormControl>
      )}

      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Club Name</Th>
              <Th>Status</Th>
              <Th>Patron Count</Th>
              <Th>Membership Length</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredClubs?.map((club) => (
              <ClubsTableRow
                key={club.id}
                club={club}
                onClick={handleOnRowClick}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
