import { Box, MenuItem, useDisclosure } from '@chakra-ui/react';
import ClubDetails from './ClubDetails';
import ClubSettings from './ClubSettings';
import UpdateClubStatus from './UpdateClubStatus';
import StatusNotification from './StatusNotification';
import { OptionsMenu } from '../../../components';
import { Activity, File, Settings } from '../../../assets';
import { IClub } from '../../../types';

interface IProps {
  club?: IClub;
}

export default function ClubActions({ club }: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isStatusOpen,
    onOpen: onStatusOpen,
    onClose: onStatusClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  return (
    <>
      {club && (
        <>
          <ClubDetails club={club} isOpen={isEditOpen} onClose={onEditClose} />
          <ClubSettings club={club} isOpen={isOpen} onClose={onClose} />
          <UpdateClubStatus
            club={club}
            isOpen={isStatusOpen}
            onClose={onStatusClose}
          />
        </>
      )}

      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        gap={2}
      >
        {club && <StatusNotification club={club} />}

        <OptionsMenu>
          <MenuItem icon={<File />} onClick={onEditOpen}>
            Edit Details
          </MenuItem>
          <MenuItem icon={<Settings />} onClick={onOpen}>
            Change Settings
          </MenuItem>
          <MenuItem icon={<Activity />} onClick={onStatusOpen}>
            Update Club Status
          </MenuItem>
        </OptionsMenu>
      </Box>
    </>
  );
}
