import { Button, useDisclosure } from '@chakra-ui/react';
import TrackBenefitsForm from './TrackBenefitsForm';
import { IClub } from '../../../../types';

interface IProps {
  patronId: string;
  selectedClub: IClub;
}

export default function BenefitsTracker({
  patronId,
  selectedClub,
}: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <TrackBenefitsForm
        isOpen={isOpen}
        onClose={onClose}
        patronId={patronId}
        selectedClub={selectedClub}
      />

      <Button size="sm" variant="outline" colorScheme="green" onClick={onOpen}>
        Track Benefit
      </Button>
    </>
  );
}
