import { Box, Text, Tooltip } from '@chakra-ui/react';
import { ClubStatusIcon } from '../../../../components';
import { getClubStatusDescription } from '../../../../utils';
import { IClub } from '../../../../types';

interface IProps {
  club: IClub;
}

export default function StatusNotification({ club }: IProps): JSX.Element {
  const { status } = club;
  const description = getClubStatusDescription(status);

  return (
    <Tooltip hasArrow label={description} placement="bottom-start">
      <Box
        alignItems="center"
        border="1px"
        borderColor="gray.200"
        display="flex"
        fontSize="sm"
        gap={2}
        h={8}
        px={2}
        rounded="md"
      >
        <ClubStatusIcon fontSize="xs" status={status} />
        <Box display="flex" gap={1}>
          <Text as="span" fontWeight="medium">
            Club Status:
          </Text>
          <Text textTransform="capitalize">{status}</Text>
        </Box>
      </Box>
    </Tooltip>
  );
}
