import { useEffect, useState } from 'react';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useDisclosure } from '@chakra-ui/react';
import { useGetBrewery } from '../../contexts';
import { Layout } from '../../components';
import AddBeerForm from './AddBeerForm';
import BeersTable from './BeersTable';
import { db } from '../../firebase';
import { IBeer } from '../../types';

export default function Beers(): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [brewery] = useGetBrewery();
  const [beers, setBeers] = useState<IBeer[]>([]);

  useEffect(() => {
    if (!brewery?.id) {
      return;
    }

    let unsubscribe: any;
    const beersRef = collection(db, 'beers');
    const breweryRef = doc(db, 'breweries', brewery.id);
    const q = query(
      beersRef,
      where('breweryRef', '==', breweryRef),
      orderBy('createdAt', 'desc'),
    );

    unsubscribe = onSnapshot(q, (beersCollection) => {
      const beers: IBeer[] = [];

      beersCollection.forEach((doc) => {
        beers.push({
          id: doc.id,
          ...doc.data(),
        } as IBeer);
      });

      setBeers(beers);
    });

    return () => unsubscribe();
  }, [brewery?.id]);

  return (
    <Layout
      buttonText="Add Beer"
      onClick={onOpen}
      pageDescription="Manage your beer menu."
      pageHeading="Beers"
    >
      <AddBeerForm isOpen={isOpen} onClose={onClose} />

      <BeersTable beers={beers} onClick={onOpen} />
    </Layout>
  );
}
