import { FormControl, FormLabel } from '@chakra-ui/react';
import MugNumberInput from './MugNumberInput';
import MugNumberSelect from './MugNumberSelect';
import { IClub } from '../../../../../types';

interface Props {
  club?: IClub;
  onChange: (value: string) => void;
}

export default function MugNumberField({
  club,
  onChange,
}: Props): JSX.Element | null {
  if (!club || !club.hasMugNumbers) {
    onChange('');
    return null;
  }

  const { capacity } = club;

  return (
    <FormControl>
      <FormLabel htmlFor="mugNumber" fontSize="xs" mb={1}>
        Mug Number
      </FormLabel>

      {capacity && <MugNumberSelect capacity={capacity} clubId={club.id} />}
      {!capacity && <MugNumberInput onChange={onChange} />}
    </FormControl>
  );
}
