import { SyntheticEvent, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { useClubCreatorContext } from '../Context';
import ClubRewardsList from '../ClubRewardsList';
import SectionHeading from '../SectionHeading';
import SectionNavigation from '../SectionNavigation';
import FeatureGate from './FeatureGate';
import { EmptyState } from '../../';
import { useGetBrewery } from '../../../contexts';
import { PlusIcon } from '../../../assets';
import { ProductPlan } from '../../../types';

export default function ClubRewards(): JSX.Element {
  const [brewery] = useGetBrewery();
  const [currentReward, setCurrentReward] = useState<string>('');
  const [currentRewardThreshold, setCurrentRewardThreshold] =
    useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const { formValues, updateFormValues, stepForward, stepBackward } =
    useClubCreatorContext();
  const isFeatureGated = useMemo(
    () => brewery.plan === ProductPlan.FREE,
    [brewery],
  );
  const { rewards } = formValues;

  function handleOnSubmit() {
    stepForward();
  }

  function handleAddReward() {
    if (isFeatureGated) {
      return;
    }

    setHasError(false);

    const threshold = parseInt(currentRewardThreshold);

    if (isNaN(threshold) || threshold < 0) {
      setHasError(true);
      return;
    }

    const newReward = {
      description: currentReward,
      id: crypto.randomUUID(),
      threshold,
    };

    const updatedRewards = [...rewards, newReward];

    updateFormValues({ rewards: updatedRewards });
    setCurrentReward('');
    setCurrentRewardThreshold('');
  }

  function handleRemoveReward(idx: number) {
    const updatedRewards = [...rewards];

    updatedRewards.splice(idx, 1);

    updateFormValues({ rewards: updatedRewards });
  }

  return (
    <Box>
      <SectionHeading
        title="Club Rewards"
        description="List your club's rewards and at what threshold each reward is earned."
      />

      <Box alignItems="left" mb={6} position="relative">
        {isFeatureGated && <FeatureGate />}

        <Box mb={4}>
          <FormControl mb={4}>
            <FormLabel htmlFor="reward" fontSize="sm" mb={2}>
              Reward
            </FormLabel>
            <Input
              id="reward"
              isDisabled={isFeatureGated}
              placeholder="Reward"
              value={currentReward}
              onChange={({ currentTarget }: SyntheticEvent<HTMLInputElement>) =>
                setCurrentReward(currentTarget.value)
              }
              size="sm"
            />
            <FormHelperText>The name of the reward.</FormHelperText>
          </FormControl>

          <FormControl isInvalid={hasError} mb={4}>
            <FormLabel htmlFor="reward" fontSize="sm">
              Visits Required
            </FormLabel>
            <Input
              id="rewardThreshold"
              isDisabled={isFeatureGated}
              placeholder="Visits Required"
              type="number"
              value={currentRewardThreshold}
              onChange={({ currentTarget }: SyntheticEvent<HTMLInputElement>) =>
                setCurrentRewardThreshold(currentTarget.value)
              }
              size="sm"
            />
            <FormHelperText>
              How many visits are needed to earn the reward?
            </FormHelperText>
            <FormErrorMessage>
              Reward thresholds must be 0 or higher.
            </FormErrorMessage>
          </FormControl>

          <Box display="flex" justifyContent="flex-end">
            <Button
              leftIcon={<PlusIcon />}
              onClick={handleAddReward}
              flexShrink={0}
              isDisabled={
                !currentReward || !currentRewardThreshold || isFeatureGated
              }
              size="sm"
            >
              Add Reward
            </Button>
          </Box>
        </Box>

        <Box>
          <Text fontWeight="medium" mb={2}>
            Rewards
          </Text>
          {rewards.length === 0 && (
            <EmptyState
              heading="No Rewards Added"
              description="Use the input above to add new rewards"
              isCompact
            />
          )}

          <ClubRewardsList
            rewards={rewards}
            onRemoveReward={handleRemoveReward}
          />
        </Box>
      </Box>

      <SectionNavigation onNext={handleOnSubmit} onPrevious={stepBackward} />
    </Box>
  );
}
