import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PatronName from './PatronName';
import { useFetchBartenders } from '../../../hooks';
import { IBrewery, IClub, ITrackedBenefit } from '../../../types';
import { db } from '../../../firebase';
import { EmptyState } from '../../../components';

interface IProps {
  brewery: IBrewery;
  club: IClub;
}

export default function ClubBenefits({ brewery, club }: IProps): JSX.Element {
  const [benefits, setBenefits] = useState<ITrackedBenefit[]>([]);
  const [breweryStaff] = useFetchBartenders(brewery.id);

  useEffect(() => {
    async function fetchBenefits() {
      const benefitsCollection = collection(db, 'trackedBenefits');
      const q = query(benefitsCollection, where('clubId', '==', club.id));
      const querySnapshot = await getDocs(q);
      const benefitsData: ITrackedBenefit[] = [];

      querySnapshot.forEach((doc) => {
        benefitsData.push({ ...doc.data(), id: doc.id } as ITrackedBenefit);
      });

      setBenefits(benefitsData);
    }

    fetchBenefits();
  }, [club]);

  return (
    <Box>
      <Heading as="h2" fontSize="lg" mb={4}>
        Benefits
      </Heading>

      {benefits.length > 0 && (
        <Text
          color="gray.500"
          fontSize="sm"
          fontWeight="medium"
          textAlign="right"
          mb={3}
        >
          {benefits.length} {benefits.length === 1 ? 'benefit' : 'benefits'}
        </Text>
      )}

      {benefits.length ? (
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Benefit</Th>
                <Th>Date</Th>
                <Th>Club</Th>
                <Th>Patron</Th>
                <Th>Recorded By</Th>
              </Tr>
            </Thead>
            <Tbody>
              {benefits.map((benefit) => {
                const staffMember = breweryStaff.find(
                  (staffMember) => staffMember.id === benefit.createdBy,
                );

                return (
                  <Tr key={benefit.id}>
                    <Td>{benefit.benefit}</Td>
                    <Td>{benefit.createdAt.toDate().toLocaleDateString()}</Td>
                    <Td>{club.name}</Td>
                    <Td>
                      <PatronName id={benefit.patronId} />
                    </Td>
                    <Td>{staffMember?.name}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState
          heading="No Benefits Have Been Recorded"
          description={`Track a patron's benefit usage by clicking "Track Benefit" on the patron details page.`}
        />
      )}
    </Box>
  );
}
