import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import SectionHeading from '../SectionHeading';
import SectionNavigation from '../SectionNavigation';
import { ClubType, useClubCreatorContext } from '../Context';
import Alert from './Alert';
import StructureOption from './StructureOption';

export default function ClubStructure(): JSX.Element {
  const [hasError, setHasError] = useState<boolean>(false);
  const { clubType, stepBackward, stepForward, updateClubType } =
    useClubCreatorContext();

  function handleOnSubmit() {
    if (!clubType) {
      setHasError(true);
      return;
    }

    stepForward();
  }

  function handleOnClick(clubType: ClubType) {
    updateClubType(clubType);
    setHasError(false);
  }

  return (
    <Box>
      <SectionHeading
        title="Club Structure"
        description="What's the structure of your club? Will it have a fixed duration or will it be subscription based?"
      />

      {hasError && <Alert />}

      <Box>
        <StructureOption
          onClick={handleOnClick}
          selected={clubType === ClubType.FIXED}
          type={ClubType.FIXED}
        />

        <StructureOption
          onClick={handleOnClick}
          selected={clubType === ClubType.SUBSCRIPTION}
          type={ClubType.SUBSCRIPTION}
        />
      </Box>

      <SectionNavigation onNext={handleOnSubmit} onPrevious={stepBackward} />
    </Box>
  );
}
