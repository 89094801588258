import { useMemo } from 'react';
import { Td, Tr } from '@chakra-ui/react';
import {} from 'firebase/firestore';
import dayjs from 'dayjs';
import PatronName from './PatronName';
import { MembershipStatusBadge } from '../';
import { IClub, IPatron } from '../../types';
import { useFetchPatronMembershipByClub } from '../../hooks';

interface IProps {
  club: IClub;
  onClick: (id: string) => void;
  patron: IPatron;
}

export default function PatronsTableRow({
  club,
  onClick,
  patron,
}: IProps): JSX.Element {
  const [membership] = useFetchPatronMembershipByClub(patron.id, club.id);
  const expirationDate = useMemo(() => {
    if (!membership?.expiresAt) {
      return 'N/A';
    }

    return dayjs(membership.expiresAt.toDate()).format('MM/DD/YYYY');
  }, [membership]);

  return (
    <Tr
      onClick={() => onClick(patron.id)}
      cursor="pointer"
      _hover={{
        backgroundColor: 'gray.50',
      }}
    >
      <Td>
        <PatronName patron={patron} />
      </Td>
      <Td>{membership && <MembershipStatusBadge membership={membership} />}</Td>
      <Td>{expirationDate}</Td>
    </Tr>
  );
}
