interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const firebaseConfigs: Record<string, IFirebaseConfig> = {
  development: {
    apiKey: 'AIzaSyC97hKHV043kkAUp60UgAecGe7_YkosYYE',
    authDomain: 'mug-club-dev-8f657.firebaseapp.com',
    projectId: 'mug-club-dev-8f657',
    storageBucket: 'mug-club-dev-8f657.appspot.com',
    messagingSenderId: '330137024865',
    appId: '1:330137024865:web:108d06ed449b495954ad20',
    measurementId: 'G-MXYWXL4CF3',
  },
  production: {
    apiKey: 'AIzaSyBDuYAYN1y34joiiFWtI0JkAewM0wbv-TE',
    authDomain: 'mug-club-34030.firebaseapp.com',
    projectId: 'mug-club-34030',
    storageBucket: 'mug-club-34030.appspot.com',
    messagingSenderId: '1036218193694',
    appId: '1:1036218193694:web:9525b940325b816c049b0f',
    measurementId: 'G-Y7MW8G2VYE',
  },
};

export function getFirebaseConfig(environment?: string) {
  if (!environment) {
    return firebaseConfigs.development;
  }

  return firebaseConfigs[environment];
}
