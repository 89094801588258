import { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react';
import FixedClubInputs from './FixedClubInputs';
import SubscriptionClubInputs from './SubscriptionClubInputs';
import SectionHeading from '../SectionHeading';
import SectionNavigation from '../SectionNavigation';
import { ClubType, useClubCreatorContext } from '../Context';

const stepCopy: Record<ClubType, { title: string; description: string }> = {
  [ClubType.FIXED]: {
    title: 'Fixed Club Cadence',
    description:
      'When will your club start, how long will it run for, and will it have an end date?',
  },
  [ClubType.SUBSCRIPTION]: {
    title: 'Subscription Club Cadence',
    description: 'How long will a member’s subscription last?',
  },
};

function validateStep(
  clubType: ClubType,
  values: {
    startDate?: string;
    endDate?: string;
    registrationEndDate?: string;
    membershipDurationMonths?: string;
  },
): boolean {
  const { startDate, endDate, membershipDurationMonths } = values;

  if (clubType === ClubType.SUBSCRIPTION) {
    if (!membershipDurationMonths) {
      return true;
    }
  }

  if (clubType === ClubType.FIXED) {
    if (!startDate || !endDate) {
      return true;
    }
  }

  return false;
}

export default function ClubCadence(): JSX.Element {
  const { clubType, formValues, stepForward, stepBackward } =
    useClubCreatorContext();
  const { endDate, startDate, registrationEndDate, membershipDurationMonths } =
    formValues;
  const [hasError, setHasError] = useState<boolean>(false);

  const { title, description } = stepCopy[clubType!];

  function handleOnSubmit() {
    setHasError(false);

    const errors = validateStep(clubType!, {
      startDate,
      endDate,
      registrationEndDate,
      membershipDurationMonths,
    });

    if (errors) {
      setHasError(true);
      return;
    }

    stepForward();
  }

  return (
    <Box>
      <SectionHeading title={title} description={description} />

      {hasError && (
        <Alert status="error" mb={4} variant="left-accent">
          <AlertIcon />
          <AlertTitle>Uh Oh.</AlertTitle>
          <AlertDescription>
            One or more fields are missing. Please fill out all required fields.
          </AlertDescription>
        </Alert>
      )}

      <Box mb={6}>
        {clubType === ClubType.FIXED && <FixedClubInputs />}

        {clubType === ClubType.SUBSCRIPTION && <SubscriptionClubInputs />}
      </Box>

      <SectionNavigation onNext={handleOnSubmit} onPrevious={stepBackward} />
    </Box>
  );
}
