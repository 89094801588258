import { Globe, Mail, Phone } from '../../../../assets';

const iconByMessageType: Record<MessageType, JSX.Element> = {
  email: <Mail />,
  sms: <Phone />,
  web: <Globe />,
};

interface Props {
  messageType: MessageType;
}

type MessageType = 'email' | 'sms' | 'web';

export default function MessageIcon({ messageType }: Props): JSX.Element {
  return iconByMessageType[messageType];
}
