import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import PatronName from './PatronName';
import { IPatronReward } from '../../../../../types';

interface Props {
  patronReward: IPatronReward;
}

function formatTimestamp(timestamp: Timestamp | null): string {
  if (!timestamp) return '-';

  return dayjs(timestamp.toDate()).format('MM/DD/YYYY h:mm A');
}

export default function RewardsDetailRow({ patronReward }: Props): JSX.Element {
  return (
    <Box
      alignItems="center"
      key={patronReward.id}
      borderBottom="1px solid"
      borderColor="gray.200"
      display="grid"
      gridTemplateColumns="2fr 1fr 1fr"
      mb={2}
      pb={2}
    >
      <PatronName
        patronId={patronReward.patronId || patronReward.patronRef.id}
      />
      <Text fontSize="sm">{formatTimestamp(patronReward.createdAt)}</Text>
      <Text fontSize="sm">{formatTimestamp(patronReward.redeemedAt)}</Text>
    </Box>
  );
}
