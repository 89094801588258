import dayjs from 'dayjs';
import { IMembership } from '../types';

enum MembershipStatus {
  ACTIVE = 'active',
  OVERDUE = 'overdue',
  INACTIVE = 'inactive',
}

export default function patronMembershipStatus(
  membership: IMembership,
): MembershipStatus {
  const { expiresAt, isActive } = membership;

  if (isActive && expiresAt) {
    const membershipExpirationDate = dayjs(expiresAt.toDate());
    const today = dayjs();

    if (membershipExpirationDate.isBefore(today)) {
      return MembershipStatus.OVERDUE;
    }

    return MembershipStatus.ACTIVE;
  }

  if (!membership.isActive) {
    return MembershipStatus.INACTIVE;
  }

  return MembershipStatus.INACTIVE;
}
