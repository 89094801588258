import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirebaseConfig } from './config';

const firebaseConfig = getFirebaseConfig(process.env.REACT_APP_FIREBASE_CONFIG);

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

function isLocalEmulatedEnvironment() {
  return (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_FIREBASE_CONFIG !== 'development' &&
    process.env.REACT_APP_FIREBASE_CONFIG !== 'production'
  );
}

if (isLocalEmulatedEnvironment()) {
  console.warn('🛠 Working within a local development environment.');

  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
}

if (process.env.REACT_APP_FIREBASE_CONFIG === 'development') {
  console.warn('🛠 Working within a remote development environment.');
}

export { auth, db, functions, storage };
