import { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Select,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MugNumberField } from '../../../components';
import { processPatronSignup, IFetchClubSignupData } from '../../../api';
import RegistrationClosed from './RegistrationClosed';
import { registrationClosed } from './utils';
import { IClub } from '../../../types';

interface IProps {
  clubId: string;
  pageData: IFetchClubSignupData;
  onSuccess: () => void;
  terms?: string;
}

export default function PatronSignUpForm({
  clubId,
  pageData,
  onSuccess,
}: IProps): JSX.Element {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [birthdayMonth, setBirthdayMonth] = useState<string>();
  const [shirtSize, setShirtSize] = useState<string>();
  const [mugNumber, setMugNumber] = useState<string>('');
  const [notes, setNotes] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { club } = pageData;
  const { customQuestions, hasPatronMugNumberSelection, registrationEndDate } =
    club;

  console.log('club', club);

  const isRegistrationClosed = registrationClosed(registrationEndDate);
  const toast = useToast();

  async function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await processPatronSignup({
        clubId: clubId,
        notes,
        patron: {
          birthdayMonth,
          email: email!,
          firstName: firstName!,
          lastName: lastName!,
          mugNumber: mugNumber ? parseInt(mugNumber) : null,
          phoneNumber: phoneNumber!,
          shirtSize,
        },
      });
      onSuccess();
    } catch (error) {
      setIsLoading(false);

      toast({
        description:
          'An error occurred while signing up for this club. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  if (isRegistrationClosed) {
    return <RegistrationClosed />;
  }

  return (
    <Box>
      <Heading as="h3" fontSize="lg" fontWeight="semibold">
        Sign Up
      </Heading>
      <Text color="gray.500" fontSize="sm" mb={4}>
        Complete the following from to sign up.
      </Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={3} mb={8}>
          <FormControl isRequired>
            <FormLabel fontSize="sm">First Name</FormLabel>
            <Input
              borderColor="gray.200"
              placeholder="First Name"
              onChange={({ currentTarget }) =>
                setFirstName(currentTarget.value)
              }
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Last Name</FormLabel>
            <Input
              borderColor="gray.200"
              placeholder="Last Name"
              onChange={({ currentTarget }) => setLastName(currentTarget.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Email</FormLabel>
            <Input
              borderColor="gray.200"
              type="email"
              placeholder="Email"
              onChange={({ currentTarget }) => setEmail(currentTarget.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Phone Number</FormLabel>
            <Input
              borderColor="gray.200"
              type="tel"
              placeholder="Phone Number"
              onChange={({ currentTarget }) =>
                setPhoneNumber(currentTarget.value)
              }
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Birthday Month</FormLabel>
            <Select
              borderColor="gray.200"
              placeholder="Select Month"
              onChange={({ currentTarget }) =>
                setBirthdayMonth(currentTarget.value)
              }
            >
              <option value="january">January</option>
              <option value="february">February</option>
              <option value="march">March</option>
              <option value="april">April</option>
              <option value="may">May</option>
              <option value="june">June</option>
              <option value="july">July</option>
              <option value="august">August</option>
              <option value="september">September</option>
              <option value="october">October</option>
              <option value="november">November</option>
              <option value="december">December</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">T-Shirt Size</FormLabel>
            <Select
              placeholder="Select Size"
              onChange={({ currentTarget }) =>
                setShirtSize(currentTarget.value)
              }
            >
              <option value="x-small">X-Small</option>
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="large">Large</option>
              <option value="x-large">X-Large</option>
              <option value="xx-large">XX-Large</option>
              <option value="xxx-large">XXX-Large</option>
            </Select>
          </FormControl>

          {hasPatronMugNumberSelection && (
            <MugNumberField
              club={
                {
                  ...club,
                  id: clubId,
                } as IClub
              }
              onChange={setMugNumber}
              value={mugNumber}
            />
          )}

          {customQuestions && (
            <FormControl>
              <FormLabel fontSize="sm">{customQuestions}</FormLabel>

              <Textarea
                placeholder="Enter your answer here."
                onChange={({ currentTarget }) => setNotes(currentTarget.value)}
                value={notes}
              />
            </FormControl>
          )}
        </VStack>

        <Button type="submit" w="100%" isLoading={isLoading} mb={4}>
          Sign Up
        </Button>

        <Text mb={4} fontSize="sm">
          By clicking "Sign Up" you agree to our{' '}
          <Link href="https://www.mugclub.beer/terms-of-service" isExternal>
            terms of service
          </Link>{' '}
          and{' '}
          <Link href="https://www.mugclub.beer/privacy-policy" isExternal>
            privacy policy
          </Link>
          .
          {club.terms &&
            ` You also agree to the following terms set by the brewery: ${club.terms}`}
        </Text>
      </form>
    </Box>
  );
}
