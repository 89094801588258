import { memo } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

function MugClubLogo({ ...rest }: TextProps): JSX.Element {
  return (
    <Text fontSize="lg" {...rest}>
      🍺 <b>Mug</b>
      Club
    </Text>
  );
}

export default memo(MugClubLogo);
