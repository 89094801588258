import { FormControl, FormLabel } from '@chakra-ui/react';
import MugNumberInput from './MugNumberInput';
import MugNumberSelect from './MugNumberSelect';
import { IClub } from '../../../types';

interface Props {
  club?: IClub;
  onChange: (value: string) => void;
  patronId?: string;
  size?: string;
  value: string;
}

export default function MugNumberField({
  club,
  onChange,
  patronId,
  size,
  value,
}: Props): JSX.Element | null {
  if (!club || !club.hasMugNumbers) {
    onChange('');
    return null;
  }

  const { capacity } = club;

  return (
    <FormControl>
      <FormLabel htmlFor="mugNumber" fontSize="sm" mb={1}>
        Mug Number
      </FormLabel>

      {capacity && (
        <MugNumberSelect
          clubId={club.id}
          onChange={onChange}
          patronId={patronId}
          size={size}
          value={value}
        />
      )}
      {!capacity && <MugNumberInput onChange={onChange} value={value} />}
    </FormControl>
  );
}
