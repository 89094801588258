import { addDoc, collection, doc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { IClub, IClubReward } from '../../types';
import { db } from '../../firebase';

interface ILogPatronRewardParams {
  breweryId: string;
  club: IClub;
  patronId: string;
  patronRecordsCount: number;
}

export async function logPatronReward({
  breweryId,
  club,
  patronId,
  patronRecordsCount,
}: ILogPatronRewardParams): Promise<IClubReward | undefined> {
  const { rewards } = club;
  const reward = rewards.find(
    (reward) => reward.threshold === patronRecordsCount,
  );

  if (!reward) {
    return;
  }

  const rewardsCollection = collection(db, 'rewards');
  const breweryRef = doc(db, 'breweries', breweryId);
  const patronRef = doc(db, 'patrons', patronId);
  const clubRef = doc(db, 'clubs', club.id);

  try {
    await addDoc(rewardsCollection, {
      breweryRef,
      clubRef,
      createdAt: new Date(),
      description: reward.description,
      patronRef,
      redeemed: false,
      redeemedAt: null,
      threshold: reward.threshold,
    });

    return reward;
  } catch (error) {
    // @TODO: Error handling
    console.error(error);
  }
}

export function parseDateStringToDate(dateString?: string): Date | undefined {
  if (!dateString) {
    return;
  }

  const now = dayjs();

  return dayjs(dateString)
    .hour(now.hour())
    .minute(now.minute())
    .second(now.second())
    .toDate();
}

export function getDateString(): string {
  return dayjs().format('YYYY-MM-DD');
}
