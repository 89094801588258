import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';

export default function LoginAlert(): JSX.Element {
  return (
    <Alert status="error" fontSize="sm" rounded="lg">
      <AlertIcon />
      <AlertTitle>Uh Oh</AlertTitle>
      <AlertDescription>Incorrect credentials</AlertDescription>
    </Alert>
  );
}
