import {
  FormControl,
  FormLabel,
  Switch,
  FormHelperText,
} from '@chakra-ui/react';

interface Props {
  isChecked: boolean;
  isDisabled: boolean;
  onChange: (isMembershipActive: boolean) => void;
}

export default function StatusSwitch({
  isChecked,
  isDisabled,
  onChange,
}: Props): JSX.Element {
  function handleOnChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    onChange(currentTarget.checked);
  }

  return (
    <FormControl mb={4}>
      <FormLabel>Membership Status</FormLabel>
      <Switch
        isChecked={isChecked}
        isDisabled={isDisabled}
        colorScheme="green"
        onChange={handleOnChange}
      />
      <FormHelperText>
        Set this patron's membership status to active or inactive.
      </FormHelperText>
    </FormControl>
  );
}
