import { Box, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from '../../assets';

interface IProps {
  message: string;
}

export default function InfoMessage({ message }: IProps): JSX.Element {
  return (
    <Box
      p={4}
      border="2px solid"
      borderColor="blue.600"
      bg="blue.50"
      borderRadius={4}
      mb={8}
    >
      <Box display="flex" gap={2} mb={2} alignItems="center">
        <InfoCircleIcon color="blue.600" fontSize="xl" />
        <Text fontWeight="medium" color="blue.600">
          Welcome
        </Text>
      </Box>
      <Text whiteSpace="pre-line">{message}</Text>
    </Box>
  );
}
