import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { BreweryConfigOptions } from '../../types';

export async function updateBreweryConfig(
  breweryId: string,
  breweryConfigOption: BreweryConfigOptions,
  value: string | boolean,
) {
  const breweryRef = doc(db, 'breweries', breweryId);

  await updateDoc(breweryRef, {
    [`config.${breweryConfigOption}`]: value,
  });
}
