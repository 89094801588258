import { ComponentStyleConfig } from '@chakra-ui/react';

const Link: ComponentStyleConfig = {
  baseStyle: {
    color: 'green.500',
    _hover: {
      color: 'green.200',
      textDecoration: 'none',
    },
  },
};

export default Link;
