import { useCallback, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IClub } from '../types';

export default function useFetchClub(
  id: string | undefined,
): [IClub | undefined, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [club, setClub] = useState<IClub>();

  const fetchClub = useCallback(async () => {
    try {
      const clubRef = doc(db, `clubs/${id}`);
      const clubDoc = await getDoc(clubRef);

      setClub({
        id: clubDoc.id,
        ...clubDoc.data(),
      } as IClub);
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchClub();
    }
  }, [id, fetchClub]);

  return [club, isLoading];
}
