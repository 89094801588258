import { SyntheticEvent } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from '@chakra-ui/react';
import { useClubCreatorContext } from '../Context';

export default function FixedClubInputs(): JSX.Element {
  const { formValues, updateFormValues } = useClubCreatorContext();
  const { endDate, registrationEndDate, startDate } = formValues;

  function handleOnChange({ currentTarget }: SyntheticEvent<HTMLInputElement>) {
    const { id, value } = currentTarget;

    updateFormValues({
      [id]: value,
    });
  }

  return (
    <VStack spacing={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="startDate">Start Date</FormLabel>
        <Input
          id="startDate"
          type="date"
          value={startDate}
          onChange={handleOnChange}
        />
        <FormHelperText>
          What is the club's starting date? Most fixed clubs begin on January
          1st.
        </FormHelperText>
      </FormControl>

      <FormControl isRequired>
        <FormLabel htmlFor="endDate">End Date</FormLabel>
        <Input
          id="endDate"
          type="date"
          value={endDate}
          onChange={handleOnChange}
        />
        <FormHelperText>
          What's the club's ending date? Most fixed clubs end on December 31st.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="registrationEndDate">
          Registration Deadline
        </FormLabel>
        <Input
          id="registrationEndDate"
          type="date"
          value={registrationEndDate}
          onChange={handleOnChange}
        />
        <FormHelperText>
          Does the club have a registration deadline after which new members
          cannot sign up?
        </FormHelperText>
      </FormControl>
    </VStack>
  );
}
