import { createContext, useContext } from 'react';
import { useFetchUsersBrewery } from '../hooks';
import { IBrewery } from '../types';

export const BreweryContext = createContext<any>(undefined);

interface IProps {
  children: React.ReactNode;
  userUid?: string;
}

function BreweryProvider({ children, userUid }: IProps): JSX.Element {
  const [brewery, isLoading] = useFetchUsersBrewery(userUid);

  return (
    <BreweryContext.Provider value={[brewery, isLoading]}>
      {children}
    </BreweryContext.Provider>
  );
}

function useGetBrewery(): [IBrewery, boolean] {
  const context = useContext(BreweryContext);

  return context;
}

export { BreweryProvider, useGetBrewery };
