import { useCallback, useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IRecord } from '../types';

export default function useFetchRecordsByClub(
  clubId?: string,
): [IRecord[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recordsData, setRecordsData] = useState<IRecord[]>([]);

  const fetchRecords = useCallback(async () => {
    if (!clubId) {
      setIsLoading(false);
      return;
    }

    try {
      const recordsCollection = collection(db, 'records');
      const q = query(
        recordsCollection,
        where('clubRef', '==', doc(db, 'clubs', clubId)),
      );
      const recordDocs = await getDocs(q);
      const records: IRecord[] = [];

      recordDocs.forEach((doc) => {
        records.push({
          id: doc.id,
          ...doc.data(),
        } as IRecord);
      });

      setRecordsData(records);
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [clubId]);

  useEffect(() => {
    fetchRecords();
  }, [clubId, fetchRecords]);

  return [recordsData, isLoading];
}
