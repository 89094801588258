import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MembershipStatusBadge } from '../../../components';
import { useFetchPatronMembershipByClub } from '../../../hooks';
import { IClub, IPatron } from '../../../types';

interface IProps {
  club: IClub;
  patron: IPatron;
}

export default function MembershipStatus({
  club,
  patron,
}: IProps): JSX.Element {
  const memberSince = useMemo(() => {
    return dayjs(patron.createdAt.toDate()).format('MM/DD/YYYY');
  }, [patron]);
  const { hasMugNumbers, id, name, membershipDurationMonths } = club;
  const [membership] = useFetchPatronMembershipByClub(patron.id, id, true);

  return (
    <Box>
      <Box
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        shadow="sm"
        py={2}
        px={3}
      >
        <Heading as="h2" size="sm" mb={3}>
          Membership
        </Heading>

        <Box mb={2}>
          <Text color="gray.600" fontSize="xs" fontWeight="medium">
            Club Name
          </Text>
          <Text
            as={Link}
            color="green.500"
            fontSize="sm"
            to={`/auth/clubs/${id}`}
          >
            {name}
          </Text>
        </Box>

        <Box mb={2}>
          <Text color="gray.600" fontSize="xs" fontWeight="medium">
            Membership Status
          </Text>
          {membership && <MembershipStatusBadge membership={membership} />}
        </Box>

        {membership && (
          <Box mb={2}>
            <Text color="gray.600" fontSize="xs" fontWeight="medium">
              Membership Expiration Date
            </Text>
            <Text fontSize="sm">
              {membership.expiresAt
                ? dayjs(membership.expiresAt?.toDate()).format('MM/DD/YYYY')
                : 'N/A'}
            </Text>
          </Box>
        )}

        {hasMugNumbers && (
          <Box mb={2}>
            <Text color="gray.600" fontSize="xs" fontWeight="medium">
              Mug Number
            </Text>
            <Text fontSize="sm">{patron.mugNumber || 'Not provided'}</Text>
          </Box>
        )}

        {membershipDurationMonths && (
          <Box mb={2}>
            <Text color="gray.600" fontSize="xs" fontWeight="medium">
              Club Membership Duration
            </Text>
            <Text fontSize="sm">{membershipDurationMonths} months</Text>
          </Box>
        )}

        <Box mb={2}>
          <Text color="gray.600" fontSize="xs" fontWeight="medium">
            Member Since
          </Text>
          <Text fontSize="sm">{memberSince}</Text>
        </Box>
      </Box>
    </Box>
  );
}
