import { Th } from '@chakra-ui/react';
import { ChevronDown, ChevronUp } from '../../assets';

interface IProps {
  active: boolean;
  children: React.ReactNode;
  orderedDescending?: boolean;
  onClick: () => void;
}

function OrderIcon({
  orderedDescending,
}: {
  orderedDescending?: boolean;
}): JSX.Element {
  if (orderedDescending) {
    return <ChevronDown fontSize="xl" />;
  }

  return <ChevronUp fontSize="xl" />;
}

export default function SortableTableHeader({
  active,
  children,
  onClick,
  orderedDescending,
}: IProps): JSX.Element {
  return (
    <Th
      onClick={onClick}
      display={active ? 'flex' : ''}
      alignItems="center"
      gap={2}
      cursor="pointer"
    >
      {children}

      {active && <OrderIcon orderedDescending={orderedDescending} />}
    </Th>
  );
}
