import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Box } from '@chakra-ui/react';
import { IMembership } from '../../types';
import { CheckCircle, Clock, PauseCircle } from '../../assets';

interface IProps {
  membership: IMembership;
}

const BGColorByStatus: Record<string, string> = {
  active: 'green.100',
  overdue: 'red.100',
  inactive: 'gray.100',
};

const TextColorByStatus: Record<string, string> = {
  active: 'green.500',
  overdue: 'red.500',
  inactive: 'gray.500',
};

const TextByStatus: Record<string, string> = {
  active: 'Active',
  overdue: 'Overdue',
  inactive: 'Inactive',
};

const IconByStatus: Record<string, any> = {
  active: () => <CheckCircle fontSize="xs" />,
  overdue: () => <Clock fontSize="xs" />,
  inactive: () => <PauseCircle fontSize="xs" />,
};

export default function MembershipStatusBadge({
  membership,
}: IProps): JSX.Element {
  const { bgColor, color, Icon, text } = useMemo(() => {
    const { isActive, expiresAt } = membership;

    if (isActive && expiresAt) {
      const membershipExpirationDate = dayjs(expiresAt.toDate());
      const today = dayjs();

      if (membershipExpirationDate.isBefore(today)) {
        return {
          bgColor: BGColorByStatus.overdue,
          color: TextColorByStatus.overdue,
          Icon: IconByStatus.overdue,
          text: TextByStatus.overdue,
        };
      }
    }

    if (isActive) {
      return {
        bgColor: BGColorByStatus.active,
        color: TextColorByStatus.active,
        Icon: IconByStatus.active,
        text: TextByStatus.active,
      };
    }

    return {
      bgColor: BGColorByStatus.inactive,
      color: TextColorByStatus.inactive,
      Icon: IconByStatus.inactive,
      text: TextByStatus.inactive,
    };
  }, [membership]);

  return (
    <Box
      alignItems="center"
      bg={bgColor}
      color={color}
      display="inline-flex"
      fontSize="2xs"
      fontWeight="semibold"
      gap={1}
      px={2}
      py={0.5}
      rounded="xl"
      textTransform="uppercase"
    >
      {Icon && <Icon />}

      {text}
    </Box>
  );
}
