import { IconProps } from '@chakra-ui/react';
import { CheckCircle, EditIcon, Award } from '../../assets';
import { ClubStatus } from '../../types';

const clubStatusIcon: Record<ClubStatus, any> = {
  [ClubStatus.ACTIVE]: {
    Icon: CheckCircle,
    color: 'green.500',
  },
  [ClubStatus.COMPLETE]: {
    Icon: Award,
    color: 'orange.500',
  },
  [ClubStatus.DRAFT]: {
    Icon: EditIcon,
    color: 'gray.500',
  },
};

interface IProps extends IconProps {
  status: ClubStatus;
}

export default function ClubStatusIcon({
  status,
  ...rest
}: IProps): JSX.Element {
  const { color, Icon } = clubStatusIcon[status];

  return <Icon color={color} {...rest} />;
}
