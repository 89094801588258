import { Divider } from '@chakra-ui/react';
import ActivateClubButton from './ActivateClubButton';
import ActiveClubLimitAlert from './ActiveClubLimitAlert';
import CompleteClubButton from './CompleteClubButton';
import CurrentStatus from './CurrentStatus';
import { Drawer, Loader } from '../../../../components';
import { ClubStatus, IClub } from '../../../../types';
import { useCanActivateClub } from './utils';

interface Props {
  club: IClub;
  isOpen: boolean;
  onClose: () => void;
}

export default function UpdateClubStatus({
  club,
  isOpen,
  onClose,
}: Props): JSX.Element {
  const { canActivateClub, isLoading } = useCanActivateClub();
  const { status } = club;
  const showActivateClubButton =
    status === ClubStatus.DRAFT && canActivateClub && !isLoading;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      showFooter={false}
      size="md"
      title="Update Club Status"
    >
      <CurrentStatus club={club} />

      <Divider my={4} />

      {isLoading && <Loader size="md" />}

      {!canActivateClub && <ActiveClubLimitAlert />}

      {showActivateClubButton && <ActivateClubButton club={club} />}

      {status === ClubStatus.ACTIVE && <CompleteClubButton club={club} />}
    </Drawer>
  );
}
