import { Box, Button } from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon } from '../../assets';

interface IProps {
  finalStep?: boolean;
  isLoading?: boolean;
  onNext: () => void;
  onPrevious?: () => void;
}

export default function SectionNavigation({
  finalStep,
  isLoading,
  onNext,
  onPrevious,
}: IProps): JSX.Element {
  return (
    <>
      <hr />

      <Box
        display="flex"
        justifyContent={onPrevious ? 'space-between' : 'flex-end'}
        gap={4}
        mt={4}
      >
        {onPrevious && (
          <Button
            leftIcon={<ArrowLeftIcon />}
            onClick={onPrevious}
            variant="ghost"
            size="sm"
          >
            Go Back
          </Button>
        )}

        <Button
          onClick={onNext}
          rightIcon={finalStep ? undefined : <ArrowRightIcon />}
          isLoading={isLoading}
          size="sm"
        >
          {finalStep ? 'Create Club' : 'Next'}
        </Button>
      </Box>
    </>
  );
}
