import { useNavigate } from 'react-router-dom';
import { Box, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import PatronsTableRow from './PatronsTableRow';
import { EmptyState, Loader } from '..';
import { IClub, IPatron } from '../../types';
interface IProps {
  club: IClub;
  emptyStateDescription?: string;
  emptyStateTitle?: string;
  loading?: boolean;
  patrons: IPatron[];
}

export default function PatronsTable({
  club,
  emptyStateDescription,
  emptyStateTitle,
  loading,
  patrons,
}: IProps) {
  const navigate = useNavigate();

  function handleOnRowClick(id: string) {
    navigate(`/auth/patrons/${id}`);
  }

  if (loading) {
    return (
      <Box mt={16}>
        <Loader />
      </Box>
    );
  }

  if (!patrons.length) {
    return (
      <EmptyState
        heading={emptyStateTitle}
        description={emptyStateDescription}
      />
    );
  }

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Membership Status</Th>
          <Th>Membership Expiration Date</Th>
        </Tr>
      </Thead>
      <Tbody>
        {patrons.map((patron) => (
          <PatronsTableRow
            club={club}
            key={patron.id}
            patron={patron}
            onClick={handleOnRowClick}
          />
        ))}
      </Tbody>
    </Table>
  );
}
