import React, { useMemo, useState } from 'react';
import { Box, Button, Input, Text } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import RecordsByDate from './RecordsByDate';
import { DataRange, getLabelsByRange, groupRecordsByRange } from './utils';
import { IPatron, IRecord } from '../../../../types';

interface IParams {
  patrons: IPatron[];
  records: IRecord[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

function getButtonVariant(buttonType: DataRange, range?: DataRange): string {
  if (buttonType === range) {
    return 'solid';
  }

  return 'outline';
}

export default function RecordsDataTable({
  patrons,
  records,
}: IParams): JSX.Element {
  const [date, setDate] = useState<string>('');
  const [range, setRange] = useState<DataRange | undefined>(DataRange.WEEK);
  const labelsByRange = useMemo(() => getLabelsByRange(range), [range]);
  const recordsByRange = useMemo(
    () => groupRecordsByRange(records, range),
    [records, range],
  );
  const recordCountInRange = recordsByRange.reduce(
    (memo, val) => memo + val,
    0,
  );

  const data = {
    labels: labelsByRange,
    datasets: [
      {
        data: recordsByRange,
        borderColor: '#9AE6B5',
        backgroundColor: '#38A169',
      },
    ],
  };

  function handleClick(range: DataRange): void {
    setDate('');
    setRange(range);
  }

  function handleDateChange(event: React.FormEvent<HTMLInputElement>): void {
    setDate(event.currentTarget.value);
    setRange(undefined);
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Text fontWeight="medium">Visits Over Time</Text>
          <Text color="gray.500" fontSize="sm">
            Compare patron visits over select time ranges.
          </Text>
        </Box>

        <Box display="flex" gap={2}>
          <Input
            size="sm"
            value={date}
            type="date"
            onChange={handleDateChange}
            rounded="md"
            minWidth={175}
          />

          <Button
            size="sm"
            variant={getButtonVariant(DataRange.WEEK, range)}
            onClick={() => handleClick(DataRange.WEEK)}
            flexShrink={0}
          >
            Week
          </Button>

          <Button
            size="sm"
            variant={getButtonVariant(DataRange.MONTH, range)}
            onClick={() => handleClick(DataRange.MONTH)}
            flexShrink={0}
          >
            Month
          </Button>

          <Button
            size="sm"
            variant={getButtonVariant(DataRange.YEAR, range)}
            onClick={() => handleClick(DataRange.YEAR)}
            flexShrink={0}
          >
            Year
          </Button>
        </Box>
      </Box>

      {date ? (
        <RecordsByDate date={date} records={records} patrons={patrons} />
      ) : (
        <>
          <Text fontSize="sm" textAlign="right" mb={2}>
            <b>{recordCountInRange}</b> records added over the last {range}
          </Text>

          <Line options={chartOptions} data={data} />
        </>
      )}
    </Box>
  );
}
