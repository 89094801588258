import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { PlusIcon } from '../../../assets';
import { IClubReward } from '../../../types';

interface Props {
  onAdd: (reward: IClubReward) => void;
}

export default function AddReward({ onAdd }: Props): JSX.Element {
  const [description, setDescription] = useState<string>('');
  const [threshold, setThreshold] = useState<number | undefined>();
  const [isAdding, setIsAdding] = useState<boolean>(false);

  function handleOnSave() {
    if (!description) {
      return;
    }

    const updatedReward = {
      description,
      id: crypto.randomUUID(),
      threshold: threshold || 0,
    };

    onAdd(updatedReward);
    onStopAdding();
  }

  function handleOnCancel() {
    onStopAdding();
  }

  function onStopAdding() {
    setDescription('');
    setThreshold(undefined);
    setIsAdding(false);
  }

  if (isAdding) {
    return (
      <Box
        alignItems="center"
        border="1px solid"
        borderColor="gray.200"
        display="flex"
        gap={1}
        mb={2}
        px={4}
        py={2}
        rounded="md"
        shadow="sm"
      >
        <Box
          display="grid"
          gridTemplateColumns="auto min-content"
          alignItems="center"
          gap={4}
          w="100%"
        >
          <Box display="flex" gap={4}>
            <FormControl>
              <FormLabel fontSize="xs" mb={1}>
                Description
              </FormLabel>
              <Input
                value={description}
                onChange={({ currentTarget }) =>
                  setDescription(currentTarget.value)
                }
                rounded="md"
                size="sm"
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="xs" mb={1}>
                Threshold
              </FormLabel>
              <Input
                value={threshold}
                onChange={({ currentTarget }) =>
                  setThreshold(Number(currentTarget.value))
                }
                rounded="md"
                size="sm"
              />
            </FormControl>
          </Box>

          <Box display="flex" gap={2} justifySelf="flex-end">
            <Button
              size="xs"
              variant="outline"
              color="green.400"
              onClick={handleOnSave}
            >
              Save
            </Button>
            <Button
              size="xs"
              variant="outline"
              color="red.400"
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      border="1px dashed"
      borderColor="gray.300"
      color="gray.500"
      cursor="pointer"
      display="flex"
      fontWeight="medium"
      gap={1}
      justifyContent="center"
      p={2}
      rounded="md"
      _hover={{
        bg: 'gray.50',
      }}
      onClick={() => setIsAdding(true)}
    >
      <PlusIcon />
      <Text>Add Reward</Text>
    </Box>
  );
}
