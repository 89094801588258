import { Box, Text } from '@chakra-ui/react';

export default function GetStartedStep(): JSX.Element {
  return (
    <Box>
      <Text fontSize="8xl" textAlign="center">
        🎯
      </Text>

      <Text fontSize="2xl" fontWeight="medium" mb={4} textAlign="center">
        Getting Started
      </Text>

      <Box
        color="gray.600"
        margin="auto"
        maxWidth={512}
        mb={4}
        textAlign="center"
      >
        <Text>Get started with MugClub in three steps:</Text>
      </Box>

      <Box margin="auto" maxWidth={512}>
        <Box
          border="2px solid"
          borderColor="gray.200"
          p={4}
          mb={4}
          rounded="md"
          shadow="sm"
        >
          <Text fontWeight="semibold" mb={1}>
            1. Create a Club
          </Text>

          <Text fontSize="sm" color="gray.600">
            First, you will need to create a mug club. Our software helps you
            customize the many aspects of your club that set it apart.
          </Text>
        </Box>

        <Box
          border="2px solid"
          borderColor="gray.200"
          p={4}
          mb={4}
          rounded="md"
          shadow="sm"
        >
          <Text fontWeight="semibold" mb={1}>
            2. Signup Patrons
          </Text>

          <Text fontSize="sm" color="gray.600">
            After creating a club, we provide you with an individualized QR code
            to be placed on premise that your patrons can use to signup for your
            club.
          </Text>
        </Box>

        <Box
          border="2px solid"
          borderColor="gray.200"
          p={4}
          mb={4}
          rounded="md"
          shadow="sm"
        >
          <Text fontWeight="semibold" mb={1}>
            3. Monitor Club Activity
          </Text>

          <Text fontSize="sm" color="gray.600">
            Now that your club is active and patrons are signing up, you can
            monitor its activity in real-time to seek out opportunities to
            improve.
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
