import { Box, Divider } from '@chakra-ui/react';
import { ReadOnlyMessageTextArea } from '../components';
import { IBrewery } from '../../../types';

interface Props {
  brewery: IBrewery;
}

function formatRenewalReminder(brewery: IBrewery, window: string): string {
  const { name, website } = brewery;

  return `Hello {{PATRON_FIRST_NAME}},\n
This is a friendly reminder that your mug club membership at ${name} expires in one ${window}.\n
Please visit ${website} for instructions on how to renew your membership.\n
Thank you,\n
The team at ${name}
  `;
}

export default function MembershipRenewal({ brewery }: Props): JSX.Element {
  return (
    <Box>
      <ReadOnlyMessageTextArea
        defaultValue={formatRenewalReminder(brewery, 'month')}
        description="Email sent to patrons one-month before their membership expires."
        label="One-Month Renewal Reminder"
        messageType="email"
        rows={12}
      />

      <Divider my={8} />

      <ReadOnlyMessageTextArea
        defaultValue={formatRenewalReminder(brewery, 'week')}
        description="Email sent to patrons one-week before their membership expires."
        label="One-Week Renewal Reminder"
        messageType="email"
        rows={12}
      />
    </Box>
  );
}
