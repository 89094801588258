import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

export default function useRouteAuthenticatedUser() {
  const navigate = useNavigate();
  const { currentUser } = auth;

  useEffect(() => {
    if (currentUser) {
      navigate('/auth/clubs', { replace: true });
    }
  }, [currentUser, navigate]);
}
