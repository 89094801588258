import {
  FormControl,
  FormLabel,
  Switch,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import { updateClubConfig } from '../../../../../api';
import { IClub } from '../../../../../types';

interface Props {
  club: IClub;
}

export default function MugNumbers({ club }: Props): JSX.Element {
  const toast = useToast();

  async function handleChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    const updateParams = {
      [currentTarget.id]: currentTarget.checked,
    };

    if (currentTarget.id === 'hasMugNumbers' && !currentTarget.checked) {
      updateParams.hasPatronMugNumberSelection = false;
    }

    try {
      await updateClubConfig(club.id, updateParams);

      toast({
        description: 'Mug number settings have been updated.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating the club settings. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <>
      <FormControl>
        <FormLabel mb={1}>Mug Numbers</FormLabel>
        <FormHelperText mb={2} mt={0}>
          Assign patrons unique mug or membership numbers.
        </FormHelperText>

        <Switch
          id="hasMugNumbers"
          isChecked={club.hasMugNumbers}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel mb={1}>Enable Patron Mug Number Selection</FormLabel>
        <FormHelperText mb={2} mt={0}>
          Allow patrons to pick their preferred mug number during registration.
        </FormHelperText>

        <Switch
          id="hasPatronMugNumberSelection"
          isChecked={club.hasPatronMugNumberSelection}
          onChange={handleChange}
          isDisabled={!club.hasMugNumbers}
        />
      </FormControl>
    </>
  );
}
