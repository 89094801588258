import { useMemo } from 'react';
import { defaultBreweryConfig } from '../../constants';
import { IFetchClubSignupData } from '../../api';
import { IBreweryConfig } from '../../types';

export function useGetBreweryConfig(
  pageData?: IFetchClubSignupData,
): IBreweryConfig {
  return useMemo(() => {
    if (!pageData) {
      return defaultBreweryConfig;
    }

    return getBreweryConfig(pageData);
  }, [pageData]);
}

function getBreweryConfig(pageData: IFetchClubSignupData): IBreweryConfig {
  const { brewery } = pageData;
  const { config } = brewery;

  return {
    ...defaultBreweryConfig,
    ...config,
  };
}
