import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { Box, Image } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { db, storage } from '../../../firebase';
import { IBrewery, IClub } from '../../../types';

interface IProps {
  clubId?: string;
}

export default function ClubLogo({ clubId }: IProps): JSX.Element {
  const [brewery, setBrewery] = useState<IBrewery>();
  const [club, setClub] = useState<IClub>();
  const [logoUrl, setLogoUrl] = useState<string>();

  useEffect(() => {
    async function fetchClub() {
      const clubRef = doc(db, `/clubs/${clubId}`);
      const clubDoc = await getDoc(clubRef);

      setClub({
        id: clubDoc.id,
        ...clubDoc.data(),
      } as IClub);
    }
    fetchClub();
  }, [clubId, setClub]);

  useEffect(() => {
    async function fetchBrewery() {
      if (!club) {
        return;
      }

      const breweryRef = doc(db, `/breweries/${club.breweryRef.id}`);
      const breweryDoc = await getDoc(breweryRef);

      setBrewery({
        id: breweryDoc.id,
        ...breweryDoc.data(),
      } as IBrewery);
    }

    fetchBrewery();
  }, [club]);

  useEffect(() => {
    async function fetchLogo() {
      if (!brewery?.id) {
        return;
      }

      try {
        const imageRef = ref(storage, `logos/${brewery.id}`);
        const url = await getDownloadURL(imageRef);

        setLogoUrl(url);
      } catch (error) {
        captureException(error);
      }
    }

    fetchLogo();
  }, [brewery]);

  return (
    <>
      <Box
        py={2}
        px={4}
        mb={4}
        h={50}
        borderBottom="1px solid"
        borderColor="gray.100"
        position="sticky"
        top={0}
        bg="white"
        shadow="sm"
      >
        <Image src={logoUrl} maxH={8} />
      </Box>

      {logoUrl && (
        <Box
          maxWidth={256}
          margin="auto"
          rounded="md"
          overflow="hidden"
          boxShadow="md"
          p={4}
        >
          <Image src={logoUrl} rounded="md" />
        </Box>
      )}
    </>
  );
}
