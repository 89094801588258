import { Link as ReactRouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';

interface IParams {
  children: React.ReactNode;
  color?: string;
  to: string;
}

const TextLink = ({ children, to, ...rest }: IParams) => (
  <Link to={to} as={ReactRouterLink} {...rest}>
    {children}
  </Link>
);

export default TextLink;
