import { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import ConfirmationDialog from '../ConfirmationDialog';
import { updateClubStatus } from '../../../../../api';
import { ClubStatus, IClub } from '../../../../../types';

interface Props {
  club: IClub;
}

export default function CompleteClubButton({ club }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  async function handleOnConfirm() {
    setIsLoading(true);

    try {
      await updateClubStatus(club.id, ClubStatus.COMPLETE);

      toast({
        description: 'Your club has been completed.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description: 'Failed to complete your club. Please try again.',
        status: 'error',
        title: 'Uh Oh!',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        nextStatus={ClubStatus.COMPLETE}
        onClose={onClose}
        onConfirm={handleOnConfirm}
      />

      <FormControl mb={4}>
        <FormLabel>Complete Club</FormLabel>

        <Button
          size="sm"
          onClick={onOpen}
          isLoading={isLoading}
          colorScheme="orange"
        >
          Complete
        </Button>

        <FormHelperText>
          Complete your club once its end date has been reached. New members
          cannot be added to completed clubs and club visits can no longer be
          tracked. Members can still redeem any earned rewards.
        </FormHelperText>
      </FormControl>
    </>
  );
}
