import { addDoc, collection, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { IBartender, IRecord } from '../../types';

interface AddRecordParams {
  bartender: IBartender;
  breweryId: string;
  clubId: string;
  createdAt?: Date;
  patronId: string;
}

export async function addRecord({
  bartender,
  breweryId,
  clubId,
  createdAt,
  patronId,
}: AddRecordParams): Promise<void> {
  const recordsCollection = collection(db, 'records');
  const recordData: Omit<IRecord, 'id'> = {
    bartender: {
      name: bartender.name,
      ref: doc(db, 'bartenders', bartender.id),
    },
    breweryId,
    clubId,
    clubRef: doc(db, 'clubs', clubId),
    createdAt: createdAt ? Timestamp.fromDate(createdAt) : Timestamp.now(),
    patronId,
    patronRef: doc(db, 'patrons', patronId),
  };

  await addDoc(recordsCollection, recordData);
}
