import { useEffect, useState } from 'react';
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useFetchBartenders } from '../../../../hooks';
import { EmptyState } from '../../../../components';
import { db } from '../../../../firebase';
import { IBrewery, IClub, ITrackedBenefit } from '../../../../types';

interface IProps {
  brewery: IBrewery;
  club: IClub;
  patronId: string;
}

export default function PatronBenefits({
  brewery,
  club,
  patronId,
}: IProps): JSX.Element {
  const [bartenders] = useFetchBartenders(brewery.id);
  const [benefits, setBenefits] = useState<ITrackedBenefit[]>([]);
  const toast = useToast();

  useEffect(() => {
    let unsubscribe: any;
    const benefitsCollection = collection(db, 'trackedBenefits');
    const q = query(
      benefitsCollection,
      where('clubId', '==', club.id),
      where('patronId', '==', patronId),
      orderBy('createdAt', 'desc'),
    );

    unsubscribe = onSnapshot(q, (benefitsCollection) => {
      const benefits: ITrackedBenefit[] = [];
      benefitsCollection.forEach((doc) => {
        benefits.push({
          id: doc.id,
          ...doc.data(),
        } as ITrackedBenefit);
      });

      setBenefits(benefits);
    });

    return () => unsubscribe();
  }, [club, patronId]);

  async function handleRemoveBenefit(id: string): Promise<void> {
    const benefitsRef = doc(db, `trackedBenefits/${id}`);

    try {
      await deleteDoc(benefitsRef);

      toast({
        description: 'Benefit removed',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred removing this benefit. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <Box>
      {benefits.length > 0 && (
        <Text
          color="gray.500"
          fontSize="sm"
          fontWeight="medium"
          textAlign="right"
          mb={3}
        >
          {benefits.length} {benefits.length === 1 ? 'benefit' : 'benefits'}
        </Text>
      )}

      {benefits.length ? (
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Benefit</Th>
                <Th>Date</Th>
                <Th>Club</Th>
                <Th>Recorded By</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {benefits.map((benefit, idx) => {
                const staffMember = bartenders.find(
                  (bartender) => bartender.id === benefit.createdBy,
                );

                return (
                  <Tr key={benefit.id}>
                    <Td>{benefit.benefit}</Td>
                    <Td>{benefit.createdAt.toDate().toLocaleDateString()}</Td>
                    <Td>{club.name}</Td>
                    <Td>{staffMember?.name}</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <Button
                        colorScheme="gray"
                        size="xs"
                        onClick={() => handleRemoveBenefit(benefit.id)}
                        variant="outline"
                      >
                        Remove
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState
          heading="No Benefits Recorded"
          description={`Track a patron's benefit usage by clicking "Track Benefit" above.`}
        />
      )}
    </Box>
  );
}
