import axios from 'axios';
import { auth } from '../../../firebase';
import { firebaseURL } from '../../../utils';
import { IClub } from '../../../types';

export async function exportPatronsByClub(clubId: string, clubs: IClub[]) {
  const token = await auth.currentUser?.getIdToken();
  const url = `${firebaseURL()}/exportPatronsByClubToCSV?clubId=${clubId}`;
  const { data } = await axios.get(url, {
    headers: {
      'firebase-auth-token': token,
    },
  });
  const blob = new Blob([data], { type: 'text/csv' });
  const link = document.createElement('a');
  const clubName = clubs.find((club) => club.id === clubId)?.name;

  link.href = window.URL.createObjectURL(blob);
  link.download = `mug-club-${clubName}-patrons.csv`;
  link.click();
}
