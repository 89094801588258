import { Box } from '@chakra-ui/react';
import { ClubStatusIcon } from '../';
import { ClubStatus } from '../../types';

interface IProps {
  status: ClubStatus;
}

const clubStatusDetails: Record<ClubStatus, any> = {
  [ClubStatus.ACTIVE]: {
    bg: 'green.100',
    color: 'green.500',
    text: 'Active',
  },
  [ClubStatus.COMPLETE]: {
    bg: 'orange.100',
    color: 'orange.500',
    text: 'Complete',
  },
  [ClubStatus.DRAFT]: {
    bg: 'gray.100',
    color: 'gray.500',
    text: 'Draft',
  },
};

export default function ClubStatusBadge({ status }: IProps): JSX.Element {
  const { bg, color, text } = clubStatusDetails[status];

  return (
    <Box
      alignItems="center"
      bg={bg}
      color={color}
      display="inline-flex"
      fontWeight="semibold"
      fontSize="2xs"
      gap={1}
      px={2}
      py={0.25}
      rounded="xl"
      textTransform="uppercase"
    >
      <ClubStatusIcon fontSize="xs" status={status} /> {text}
    </Box>
  );
}
