import { useState } from 'react';
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { EyeClosedIcon, EyeOpenIcon } from '../../assets';

interface IProps {
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  isInvalid?: boolean;
  onChange: (value: string) => void;
}

export default function PasswordInput({
  onChange,
  ...rest
}: IProps): JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <InputGroup>
      <Input
        bg="white"
        borderColor="gray.200"
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        onChange={({ currentTarget }) => onChange(currentTarget.value)}
        {...rest}
      />
      <InputRightElement>
        <Button
          onClick={() => setShowPassword(!showPassword)}
          h="1.5rem"
          mr={4}
          variant="ghost"
        >
          {showPassword ? (
            <EyeClosedIcon color="gray.500" />
          ) : (
            <EyeOpenIcon color="gray.500" />
          )}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
