import { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import ClubDetailsList from './ClubDetailsList';
import ClubBenefitsList from '../ClubBenefitsList';
import ClubRewardsList from '../ClubRewardsList';
import MugNumberConfig from './MugNumberConfig';
import SectionHeading from '../SectionHeading';
import SectionNavigation from '../SectionNavigation';
import { useClubCreatorContext } from '../Context';
import { useNavigate } from 'react-router-dom';
import { useGetBrewery } from '../../../contexts';
import { createClub } from './utils';

export default function Review(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formValues, stepBackward } = useClubCreatorContext();
  const [brewery] = useGetBrewery();
  const toast = useToast();
  const navigate = useNavigate();
  const { benefits, customQuestions, rewards, terms } = formValues;

  async function handleOnSubmit() {
    setIsLoading(true);

    try {
      const id = await createClub(brewery, formValues);

      toast({
        description: 'Mug club created',
        status: 'success',
        title: 'Success',
      });

      navigate(`/auth/clubs/${id}`);
    } catch (error) {
      setIsLoading(false);

      toast({
        description:
          'An error occurred while creating the mug club. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <Box>
      <SectionHeading
        title="Review Your Club"
        description="Review and confirm the details of your club."
      />

      <Box display="grid" gap={6}>
        <ClubDetailsList />

        <hr />

        <MugNumberConfig />

        <hr />

        <Box>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Benefits
          </Text>

          {benefits.length !== 0 ? (
            <ClubBenefitsList benefits={benefits} />
          ) : (
            <Text>No benefits have been added to this club.</Text>
          )}
        </Box>

        <hr />

        <Box>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Rewards
          </Text>

          {rewards.length !== 0 ? (
            <ClubRewardsList rewards={rewards} />
          ) : (
            <Text>No rewards have been added to this club.</Text>
          )}
        </Box>

        <hr />

        <FormControl>
          <FormLabel fontSize="lg" htmlFor="customQuestions">
            Custom Questions
          </FormLabel>
          <Textarea
            id="customQuestions"
            value={
              customQuestions ||
              'Custom questions have not been added to this club.'
            }
            readOnly
          />
        </FormControl>

        <hr />

        <FormControl>
          <FormLabel fontSize="lg" htmlFor="terms">
            Terms
          </FormLabel>
          <Textarea
            id="terms"
            value={terms || 'Terms have not been added to this club.'}
            readOnly
          />
        </FormControl>

        <SectionNavigation
          finalStep={true}
          isLoading={isLoading}
          onPrevious={stepBackward}
          onNext={handleOnSubmit}
        />
      </Box>
    </Box>
  );
}
