import { useCallback, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IPatron } from '../types';

export default function useFetchPatron(
  id: string | undefined,
): [IPatron | undefined, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patronData, setPatronData] = useState<IPatron>();

  const fetchPatronData = useCallback(async () => {
    try {
      const patronRef = doc(db, 'patrons', id!);
      const patronDoc = await getDoc(patronRef);

      setPatronData({
        id: patronDoc.id,
        ...patronDoc.data(),
      } as IPatron);
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchPatronData();
    }
  }, [id, fetchPatronData]);

  return [patronData, isLoading];
}
