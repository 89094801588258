import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IBrewery } from '../types';

export default function useFetchUsersBrewery(
  userUid?: string,
): [IBrewery | undefined, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [breweryData, setBreweryData] = useState<IBrewery | undefined>();

  const fetchUsersBrewery = useCallback(async () => {
    const breweryCollection = collection(db, 'breweries');
    const q = query(
      breweryCollection,
      where('adminUids', 'array-contains', userUid),
    );

    try {
      const breweryDocs = await getDocs(q);
      let brewery;

      breweryDocs.forEach((doc) => {
        brewery = {
          id: doc.id,
          ...doc.data(),
        } as IBrewery;
      });

      setBreweryData(brewery);
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
      return error;
    } finally {
      setIsLoading(false);
    }
  }, [userUid]);

  useEffect(() => {
    if (userUid) {
      fetchUsersBrewery();
    }
  }, [userUid, fetchUsersBrewery]);

  return [breweryData, isLoading];
}
