import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import Metric from './Metric';
import PatronStatusMetrics from './PatronStatusMetrics';
import { IClub, IPatron, IPatronReward } from '../../../../types';

interface IParams {
  club: IClub;
  patrons: IPatron[];
  recordsCount: number;
  rewards: IPatronReward[];
}

function getRewardsRedemptionRatio(
  redeemedRewardsCount: number,
  rewardsCount: number,
): string {
  if (rewardsCount === 0) {
    return '0%';
  }

  return `${Math.floor((redeemedRewardsCount / rewardsCount) * 100)}%`;
}

export default function ClubStatistics({
  club,
  patrons,
  recordsCount,
  rewards,
}: IParams): JSX.Element {
  const estimatedRecordsRevenue = `$${(recordsCount * 8).toLocaleString()}`;
  const estimatedMembershipRevenue = `$${(
    patrons.length * (club.cost || 0)
  ).toLocaleString()}`;
  const redeemedRewardsCount = useMemo(
    () => rewards.filter((reward) => reward.redeemed).length,
    [rewards],
  );
  const rewardsRedemptionRatio = useMemo(
    () => getRewardsRedemptionRatio(redeemedRewardsCount, rewards.length),
    [redeemedRewardsCount, rewards.length],
  );
  const clubHasRewards = club.rewards.length > 0;
  const clubHasRollingMemberships = Boolean(club.membershipDurationMonths);

  return (
    <Box display="flex" gap={2}>
      <Metric
        label="Total Patrons"
        number={patrons.length}
        description="Number of patrons in this club"
      />

      {clubHasRollingMemberships && (
        <PatronStatusMetrics club={club} patrons={patrons} />
      )}

      {clubHasRewards && (
        <>
          <Metric
            label="Total Visits"
            number={recordsCount.toLocaleString()}
            description="Total number of patron visits"
          />

          <Metric
            label="Rewards Earned"
            number={rewards.length?.toLocaleString()}
            description="Total number of patron rewards"
          />

          <Metric
            description="Redeemed vs. earned rewards"
            label="Rewards Redemption Rate"
            number={rewardsRedemptionRatio}
          />

          <Metric
            description="Assumes an $8 pour per visit"
            label="Estimated Visits Revenue"
            number={estimatedRecordsRevenue}
          />
        </>
      )}

      <Metric
        description="Reflects membership dues"
        label="Membership Revenue"
        number={estimatedMembershipRevenue}
      />
    </Box>
  );
}
