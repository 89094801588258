import { useMemo } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import NoActiveClubAlert from './NoActiveClubAlert';
import { ClubStatus, IClub } from '../../../types';

interface Props {
  clubs: IClub[];
  clubId: string;
  hasError: boolean;
  onChange: (clubId: string) => void;
}

export default function ClubSelect({
  clubs,
  clubId,
  hasError,
  onChange,
}: Props): JSX.Element {
  const activeClubs = useMemo(
    () => clubs.filter((club) => club.status === ClubStatus.ACTIVE),
    [clubs],
  );
  const hasActiveClub = activeClubs.length > 0;

  return (
    <FormControl isRequired isInvalid={hasError}>
      <FormLabel htmlFor="club">Club</FormLabel>
      {hasActiveClub && (
        <>
          <Select
            onChange={({ currentTarget }) => onChange(currentTarget.value)}
            value={clubId}
          >
            <option selected disabled>
              Select Club
            </option>
            {activeClubs.map((club) => (
              <option value={club.id} key={club.id}>
                {club.name}
              </option>
            ))}
          </Select>

          <FormHelperText>
            Which club is this visit associated with? Only active clubs are
            shown.
          </FormHelperText>

          <FormErrorMessage>You must select a club.</FormErrorMessage>
        </>
      )}

      {!hasActiveClub && <NoActiveClubAlert />}
    </FormControl>
  );
}
