import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FlowStepper } from '..';
import AccountSetup from './AccountSetup';
import BreweryInfo from './BreweryInfo';
import ClubDetails from './ClubDetails';
import { fetchBreweryByUserUid } from '../../../../api';
import { auth } from '../../../../firebase';

export default function FormStepper(): JSX.Element {
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkForUserAndBrewery() {
      const { currentUser } = auth;

      if (!currentUser) {
        return;
      }

      const { uid } = currentUser;
      const existingBrewery = await fetchBreweryByUserUid(uid);
      const userHasBrewery = Boolean(existingBrewery);

      if (currentUser && !userHasBrewery) {
        setActiveStep(1);
      }

      if (currentUser && userHasBrewery) {
        navigate('/auth/clubs');
      }
    }

    checkForUserAndBrewery();
  }, [navigate]);

  function handleNext() {
    setActiveStep((prevStep) => prevStep + 1);
  }

  return (
    <Box>
      <Box mb={12} display={['none', 'block']}>
        <FlowStepper activeStep={activeStep} />
      </Box>

      <Box maxW={512} mx="auto">
        {activeStep === 0 && <AccountSetup onSubmit={handleNext} />}
        {activeStep === 1 && <BreweryInfo onSubmit={handleNext} />}
        {activeStep === 2 && <ClubDetails />}
      </Box>
    </Box>
  );
}
