import { Timestamp } from 'firebase/firestore';
import { IPatron, IRecord } from '../../../types';

interface IPatronWithRecords extends IPatron {
  records: IRecord[];
  lastActive?: Timestamp;
}

export function filterActivePatrons(
  patrons: IPatron[],
  clubId: string,
): [IPatron[], IPatron[]] {
  const patronsInClub: IPatron[] = [];
  const patronsNotInClub: IPatron[] = [];

  patrons.forEach((patron) => {
    if (patron.clubs.includes(clubId)) {
      patronsInClub.push(patron);
    } else {
      patronsNotInClub.push(patron);
    }
  });

  return [patronsInClub, patronsNotInClub];
}

export function mapPatronsToRecords(
  patrons: IPatron[],
  records: IRecord[],
): IPatronWithRecords[] {
  return patrons.map((patron) => {
    const patronsRecords = records.filter((record) => {
      return record.patronRef.id === patron.id;
    });
    const lastActive = patronsRecords.sort((a, b) => {
      return b.createdAt.seconds - a.createdAt.seconds;
    })[0]?.createdAt;

    return {
      ...patron,
      records: patronsRecords,
      lastActive,
    } as IPatronWithRecords;
  });
}

export function orderPatronsByRecordCount(
  patrons: IPatronWithRecords[],
  orderedDescending: boolean,
): IPatronWithRecords[] {
  return patrons.sort((a, b) => {
    if (orderedDescending) {
      if (a.records.length > b.records.length) {
        return -1;
      }

      return 1;
    }

    if (a.records.length > b.records.length) {
      return 1;
    }

    return -1;
  });
}
