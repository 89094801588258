import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

export default function NoActiveClubAlert(): JSX.Element {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>No active club found.</AlertTitle>
      <AlertDescription>
        You must activate a club in order to record a visit.
      </AlertDescription>
    </Alert>
  );
}
