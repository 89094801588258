import { Box, Text } from '@chakra-ui/react';
import Benefit from './Benefit';
import { IClub } from '../../../types';
import AddBenefit from './AddBenefit';

interface Props {
  club: IClub;
  isEditable?: boolean;
  onChange: (key: keyof IClub, value: string[]) => void;
}

export default function Benefits({
  club,
  isEditable,
  onChange,
}: Props): JSX.Element {
  const { benefits } = club;

  function handleOnRemove(index: number) {
    const updatedBenefits = benefits.filter((_, i) => i !== index);

    onChange('benefits', updatedBenefits);
  }

  function handleOnUpdate(benefit: string, index: number) {
    const updatedBenefits = [...benefits];
    updatedBenefits[index] = benefit;

    onChange('benefits', updatedBenefits);
  }

  function handleOnAdd(benefit: string) {
    const updatedBenefits = [...benefits, benefit];

    onChange('benefits', updatedBenefits);
  }

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Benefits
      </Text>

      <Box mb={2}>
        {benefits.map((benefit, index) => (
          <Benefit
            key={index}
            benefit={benefit}
            index={index}
            isEditable={isEditable}
            onRemove={handleOnRemove}
            onUpdate={handleOnUpdate}
          />
        ))}
      </Box>

      {isEditable && <AddBenefit onAdd={handleOnAdd} />}
    </Box>
  );
}
