import { NavLink } from 'react-router-dom';
import { Link, Text } from '@chakra-ui/react';
import { BeerStein, ListIcon, Message, Users } from '../../assets';

type TPage = 'clubs' | 'patrons' | 'messages' | 'mugs';

type TOptions = {
  Icon: () => JSX.Element;
  label: string;
  to: string;
};

const optionsByPage: Record<TPage, TOptions> = {
  clubs: {
    Icon: () => <ListIcon fontSize="lg" />,
    label: 'Clubs',
    to: '/auth/clubs',
  },
  patrons: {
    Icon: () => <Users fontSize="lg" />,
    label: 'Patrons',
    to: '/auth/patrons',
  },
  messages: {
    Icon: () => <Message fontSize="lg" />,
    label: 'Messages',
    to: '/auth/messages',
  },
  mugs: {
    Icon: () => <BeerStein fontSize="lg" />,
    label: 'Mugs',
    to: '/auth/mugs',
  },
};

interface IProps {
  page: TPage;
}

export default function NavigationItems({ page }: IProps): JSX.Element {
  const { Icon, label, to } = optionsByPage[page];

  return (
    <Link
      alignItems="center"
      as={NavLink}
      display="flex"
      flexDir="column"
      gap={1}
      px={1}
      py={1.5}
      rounded="md"
      to={to}
      _activeLink={{ background: 'green.100' }}
      _hover={{ background: 'green.50' }}
    >
      <Icon />
      <Text fontSize="2xs" fontWeight="semibold">
        {label}
      </Text>
    </Link>
  );
}
