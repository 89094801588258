import { FormControl, Text, Textarea } from '@chakra-ui/react';
import { IClub } from '../../types';

interface Props {
  club: IClub;
  isEditable?: boolean;
  onChange: (key: keyof IClub, value: string | number) => void;
}

export default function Terms({
  club,
  isEditable,
  onChange,
}: Props): JSX.Element {
  const { terms } = club;

  return (
    <>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Terms
      </Text>

      <FormControl mb={3}>
        <Textarea
          placeholder="Terms"
          value={terms || ''}
          readOnly={!isEditable}
          onChange={({ currentTarget }) =>
            onChange('terms', currentTarget.value)
          }
        />
      </FormControl>
    </>
  );
}
