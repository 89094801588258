import { useState } from 'react';
import { Divider } from '@chakra-ui/react';
import ClubSignUp from './ClubSignUp';
import { MessageGroup, MessagesMenu } from './components';
import MembershipRenewal from './MembershipRenewal';
import Rewards from './Rewards';
import { Layout } from '../../components';
import { useGetBrewery } from '../../contexts';
import { getBreweryConfig } from '../../utils';
import Birthdays from './Birthdays';

export default function MessagesPage(): JSX.Element {
  const [brewery] = useGetBrewery();
  const breweryConfig = getBreweryConfig(brewery);
  const [messageGroup, setMessageGroup] = useState<MessageGroup>(
    MessageGroup.CLUB_SIGN_UP,
  );

  return (
    <Layout
      pageDescription="Customize the messages your patrons see and receive."
      pageHeading="Messages"
    >
      <MessagesMenu onChange={setMessageGroup} />

      <Divider my={8} />

      {messageGroup === MessageGroup.BIRTHDAYS && (
        <Birthdays brewery={brewery} breweryConfig={breweryConfig} />
      )}

      {messageGroup === MessageGroup.CLUB_SIGN_UP && (
        <ClubSignUp brewery={brewery} breweryConfig={breweryConfig} />
      )}

      {messageGroup === MessageGroup.MEMBERSHIP_RENEWAL && (
        <MembershipRenewal brewery={brewery} />
      )}

      {messageGroup === MessageGroup.REWARDS && (
        <Rewards brewery={brewery} breweryConfig={breweryConfig} />
      )}
    </Layout>
  );
}
