import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useToast } from '@chakra-ui/react';
import { db } from '../firebase';
import { IMembership } from '../types';

export default function useFetchPatronMemberships(
  patronId: string | undefined,
): [IMembership[] | undefined, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [memberships, setMemberships] = useState<IMembership[]>();
  const toast = useToast();

  const fetchMemberships = useCallback(async () => {
    try {
      const membershipsCollection = collection(db, 'memberships');
      const q = query(membershipsCollection, where('patronId', '==', patronId));
      const querySnapshot = await getDocs(q);
      const memberships: IMembership[] = [];

      querySnapshot.forEach((doc) => {
        const membership = {
          id: doc.id,
          ...doc.data(),
        } as IMembership;

        memberships.push(membership);
      });

      setMemberships(memberships);
      setIsLoading(false);
    } catch (error) {
      toast({
        description:
          "An error fetching patron's memberships. Please try again.",
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [patronId, toast]);

  useEffect(() => {
    if (patronId) {
      fetchMemberships();
    }
  }, [patronId, fetchMemberships]);

  return [memberships, isLoading];
}
