import { useState } from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ChevronDown } from '../../../../assets';

export enum MessageGroup {
  BIRTHDAYS = 'birthdays',
  CLUB_SIGN_UP = 'clubSignUp',
  MEMBERSHIP_RENEWAL = 'membershipRenewal',
  REWARDS = 'rewards',
}

const menuCopyByGroup: Record<string, { title: string; description: string }> =
  {
    [MessageGroup.BIRTHDAYS]: {
      title: 'Birthdays',
      description: 'Messages sent to patrons during their birthday month.',
    },
    [MessageGroup.CLUB_SIGN_UP]: {
      title: 'Club Sign Up',
      description:
        'Messages shown to patrons as they move through the signup process for your club.',
    },
    [MessageGroup.MEMBERSHIP_RENEWAL]: {
      title: 'Membership Renewal Reminders',
      description:
        'Messages sent to patrons when their membership is ready for renewal.',
    },
    [MessageGroup.REWARDS]: {
      title: 'Rewards',
      description:
        'Messages shown to patrons when as they earn rewards for their loyalty.',
    },
  };

interface Props {
  onChange: (value: MessageGroup) => void;
}

export default function MessagesMenu({ onChange }: Props): JSX.Element {
  const [selectedGroup, setSelectedGroup] = useState<MessageGroup>(
    MessageGroup.CLUB_SIGN_UP,
  );

  const { title, description } = menuCopyByGroup[selectedGroup];

  function handleOnChange(value: MessageGroup) {
    setSelectedGroup(value);
    onChange(value);
  }

  return (
    <Menu>
      <Box as={MenuButton}>
        <Box
          alignItems="center"
          border="1px solid"
          borderColor="gray.200"
          display="flex"
          gap={4}
          justifyContent="space-between"
          rounded="md"
          p={2}
          w="540px"
        >
          <Box textAlign="left">
            <Text fontWeight="semibold">{title}</Text>
            <Text fontSize="xs" color="gray.500">
              {description}
            </Text>
          </Box>

          <ChevronDown />
        </Box>
      </Box>
      <MenuList w="540px">
        <MenuItem onClick={() => handleOnChange(MessageGroup.BIRTHDAYS)}>
          Birthdays
        </MenuItem>
        <MenuItem onClick={() => handleOnChange(MessageGroup.CLUB_SIGN_UP)}>
          Club Sign Up
        </MenuItem>
        <MenuItem
          onClick={() => handleOnChange(MessageGroup.MEMBERSHIP_RENEWAL)}
        >
          Membership Renewal Reminders
        </MenuItem>
        <MenuItem onClick={() => handleOnChange(MessageGroup.REWARDS)}>
          Rewards
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
