import { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  useToast,
} from '@chakra-ui/react';
import { createMembership } from './utils';
import { useFetchPatronMemberships } from '../../../../hooks';
import { ClubStatus, IClub, IPatron } from '../../../../types';

interface Props {
  clubs: IClub[];
  isOpen: boolean;
  onClose: () => void;
  onClubChange: (clubId: string) => void;
  patron: IPatron;
}

export default function AddToClub({
  clubs,
  isOpen,
  onClose,
  onClubChange,
  patron,
}: Props): JSX.Element {
  const [selectedClubId, setSelectedClubId] = useState<string>();
  const [availableClubs, setAvailableClubs] = useState<IClub[]>([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [memberships] = useFetchPatronMemberships(patron.id);
  const toast = useToast();

  useEffect(() => {
    const availableClubs = clubs
      .filter(
        (club) =>
          club.status === ClubStatus.ACTIVE || club.status === ClubStatus.DRAFT,
      )
      .filter((club) => {
        const membership = memberships?.find(
          ({ clubId }) => clubId === club.id,
        );
        return !membership;
      });

    setAvailableClubs(availableClubs);
  }, [clubs, memberships]);

  async function handleOnSubmit(): Promise<void> {
    setIsFormSubmitting(true);

    const club = availableClubs.find((club) => club.id === selectedClubId);

    if (!club) {
      return;
    }

    try {
      await createMembership(patron, club);

      toast({
        description: `Patron was added to ${club.name}.`,
        status: 'success',
        title: 'Success!',
      });

      onClose();
      onClubChange(club.id);
    } catch (error) {
      toast({
        description:
          'An error occurred adding the patron to the club. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsFormSubmitting(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Patron to Club</DrawerHeader>

        <DrawerBody>
          <FormControl mb={4} isRequired>
            <FormLabel>Available Clubs</FormLabel>
            <Select
              onChange={({ currentTarget }) =>
                setSelectedClubId(currentTarget.value)
              }
            >
              <option selected disabled>
                Select Club
              </option>
              {availableClubs.map((club) => (
                <option key={club.id} value={club.id}>
                  {club.name}
                </option>
              ))}
            </Select>
            <FormHelperText>
              Only active and draft clubs that the patron is not a member of are
              shown.
            </FormHelperText>
          </FormControl>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleOnSubmit} isLoading={isFormSubmitting}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
