import { Heading, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import Detail from './Detail';
import { IPatron } from '../../../types';

interface IProps {
  patron: IPatron;
}

export default function PatronDetails({ patron }: IProps): JSX.Element {
  const {
    birthdayMonth,
    email,
    createdAt,
    lastUpdatedAt,
    phoneNumber,
    shirtSize,
  } = patron;

  return (
    <VStack
      spacing={2}
      alignItems="flex-start"
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      shadow="sm"
      py={2}
      px={3}
    >
      <Heading as="h2" size="sm" mb={3}>
        Patron Details
      </Heading>

      <Detail label="Email" value={email ? email : 'Not provided'} />

      <Detail
        label="Phone Number"
        value={phoneNumber ? phoneNumber : 'Not provided'}
      />

      <Detail
        label="Birthday Month"
        value={
          birthdayMonth
            ? `${birthdayMonth.charAt(0).toUpperCase()}${birthdayMonth.slice(
                1,
              )}`
            : 'Not provided'
        }
      />

      <Detail
        label="Shirt Size"
        value={shirtSize ? shirtSize.toUpperCase() : 'Not provided'}
      />

      <Detail
        label="Last Updated"
        value={
          lastUpdatedAt
            ? dayjs(lastUpdatedAt.toDate()).format('MM/DD/YYYY')
            : dayjs(createdAt.toDate()).format('MM/DD/YYYY')
        }
      />
    </VStack>
  );
}
