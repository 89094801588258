import {
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

interface IProps {
  buttonText?: string;
  children: React.ReactNode;
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  isSubmitButtonDisabled?: boolean;
  onEsc?: () => void;
  onClose: () => void;
  onSubmit?: () => Promise<void> | void;
  showFooter?: boolean;
  size?: string;
  title: string;
}

const Drawer = ({
  buttonText = 'Submit',
  children,
  closeOnEsc = true,
  closeOnOverlayClick = true,
  isLoading = false,
  isOpen,
  isSubmitButtonDisabled = false,
  onEsc = undefined,
  onClose,
  onSubmit = undefined,
  showFooter = true,
  size = 'xl',
  title,
}: IProps) => {
  return (
    <ChakraDrawer
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onEsc}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>

        <DrawerBody>{children}</DrawerBody>

        {showFooter && (
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              background="green.500"
              color="white"
              onClick={onSubmit}
              isDisabled={isSubmitButtonDisabled}
              isLoading={isLoading}
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
