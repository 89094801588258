import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { IPatron } from '../types';

interface IRequestData {
  clubId: string;
  notes?: string;
  patron: Pick<
    IPatron,
    | 'birthdayMonth'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'mugNumber'
    | 'phoneNumber'
    | 'shirtSize'
  >;
}

export interface IReturnData {
  message: string;
}

export const processPatronSignup = httpsCallable<IRequestData, IReturnData>(
  functions,
  'processPatronSignup',
);
