import { Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import EmptyState from './EmptyState';
import LoadingState from './LoadingState';
import { useGetAvailableMugsByClub } from '../../hooks';
import { IClub } from '../../types';

interface IProps {
  club?: IClub;
  isGridView: boolean;
}

export default function MugsTable({ club, isGridView }: IProps): JSX.Element {
  const [mugs, isLoading] = useGetAvailableMugsByClub(club?.id);

  if (isLoading) {
    return <LoadingState />;
  }

  if (!club?.id) {
    return <EmptyState />;
  }

  if (mugs?.length === 0)
    return (
      <EmptyState
        title="No results found"
        description="Try changing your filters"
      />
    );

  return (
    <Box>
      {isGridView && (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={2}>
          {mugs?.map((mug) => (
            <Box
              key={mug.number}
              border="1px"
              borderColor={mug?.patron ? 'gray.200' : 'green.200'}
              textAlign="center"
              p={4}
              rounded="md"
              bg={mug?.patron ? 'gray.50' : 'green.50'}
            >
              <Text fontSize="2xl" fontWeight="medium" mb={1}>
                {mug.number}
              </Text>
              {mug?.patron && (
                <Text
                  as={Link}
                  to={`/auth/patrons/${mug.patron.id}`}
                  color="green.500"
                >
                  {mug.patron.firstName} {mug.patron.lastName}
                </Text>
              )}
              {!mug?.patron && <Text>Available</Text>}
            </Box>
          ))}
        </Box>
      )}

      {!isGridView && (
        <Box display="flex" flexDirection="column" gap={2}>
          {mugs?.map((mug) => (
            <Box
              key={mug.number}
              bg={mug?.patron ? 'gray.50' : 'green.50'}
              border="1px"
              borderColor={mug?.patron ? 'gray.200' : 'green.200'}
              display="flex"
              p={4}
              rounded="md"
              textAlign="center"
              alignItems="center"
              gap={2}
            >
              <Text fontWeight="medium">{mug.number}</Text>
              {mug?.patron && (
                <Text
                  as={Link}
                  to={`/auth/patrons/${mug.patron.id}`}
                  color="green.500"
                >
                  {mug.patron.firstName} {mug.patron.lastName}
                </Text>
              )}
              {!mug?.patron && <Text>Available</Text>}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
