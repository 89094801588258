import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import BartendersTableRow from './BartendersTableRow';
import { EmptyState } from '../../../components';
import { IBartender } from '../../../types';

interface IProps {
  onEdit: (bartender: IBartender) => void;
  bartenders: IBartender[];
}

export default function BarTendersTable({
  bartenders,
  onEdit,
}: IProps): JSX.Element {
  if (!bartenders.length) {
    return <EmptyState />;
  }

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Visits Recorded</Th>
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {bartenders?.map((bartender) => (
            <BartendersTableRow
              key={bartender.id}
              bartender={bartender}
              onClick={onEdit}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
