import { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import MugNumberField from './MugNumberField';
import {
  getFormData,
  getFormValues,
  PatronFormData,
  PatronFormValues,
} from './utils';
import { Drawer } from '../';
import { IClub, IPatron } from '../../types';

interface IProps {
  club?: IClub;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: PatronFormData) => Promise<void>;
  patron?: IPatron;
}

export default function PatronForm({
  club,
  isOpen,
  onClose,
  onSubmit,
  patron,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(values: PatronFormValues): Promise<void> {
    setIsLoading(true);

    const formData = getFormData(values);

    await onSubmit(formData);
    onClose();
    setIsLoading(false);
  }

  return (
    <Drawer
      title={`${patron ? 'Edit' : 'Add New'} Patron`}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      showFooter={false}
    >
      <Formik initialValues={getFormValues(patron)} onSubmit={handleSubmit}>
        {(props) => (
          <Box h="-webkit-fill-available">
            <form
              onSubmit={props.handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit',
              }}
            >
              <VStack spacing={3} mb={16}>
                {club?.hasMugNumbers && (
                  <>
                    <Heading alignSelf="flex-start" size="sm">
                      Club Details
                    </Heading>

                    <MugNumberField
                      club={club}
                      onChange={(value) =>
                        props.setFieldValue('mugNumber', value)
                      }
                      patronId={patron?.id}
                      size="sm"
                      value={props.values.mugNumber}
                    />

                    <Divider my={2} />
                  </>
                )}

                <Heading alignSelf="flex-start" size="sm">
                  Patron Details
                </Heading>

                <FormControl isRequired>
                  <FormLabel htmlFor="firstName" fontSize="sm">
                    First Name
                  </FormLabel>
                  <Input
                    name="firstName"
                    type="name"
                    placeholder="First Name"
                    onChange={props.handleChange}
                    value={props.values.firstName}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="lastName" fontSize="sm">
                    Last Name
                  </FormLabel>
                  <Input
                    name="lastName"
                    type="name"
                    placeholder="Last Name"
                    onChange={props.handleChange}
                    value={props.values.lastName}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="email" fontSize="sm">
                    Email
                  </FormLabel>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={props.handleChange}
                    value={props.values.email}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="phoneNumber" fontSize="sm">
                    Phone Number
                  </FormLabel>
                  <Input
                    name="phoneNumber"
                    placeholder="Phone Number"
                    onChange={props.handleChange}
                    value={props.values.phoneNumber}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="birthdayMonth" fontSize="sm">
                    Birthday Month
                  </FormLabel>
                  <Select
                    borderColor="green.200"
                    name="birthdayMonth"
                    placeholder="Select Month"
                    onChange={props.handleChange}
                    value={props.values.birthdayMonth}
                  >
                    <option value="january">January</option>
                    <option value="february">February</option>
                    <option value="march">March</option>
                    <option value="april">April</option>
                    <option value="may">May</option>
                    <option value="june">June</option>
                    <option value="july">July</option>
                    <option value="august">August</option>
                    <option value="september">September</option>
                    <option value="october">October</option>
                    <option value="november">November</option>
                    <option value="december">December</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="shirtSize" fontSize="sm">
                    T-Shirt Size
                  </FormLabel>

                  <Select
                    borderColor="green.200"
                    name="shirtSize"
                    placeholder="Select Size"
                    onChange={props.handleChange}
                    value={props.values.shirtSize}
                  >
                    <option value="x-small">X-Small</option>
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                    <option value="x-large">X-Large</option>
                    <option value="xx-large">XX-Large</option>
                    <option value="xxx-large">XXX-Large</option>
                  </Select>
                  <FormHelperText>
                    Optionally add the size of this patron's t-shirt
                  </FormHelperText>
                </FormControl>
              </VStack>

              <Box
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt="auto"
                pb={4}
              >
                <Button variant="outline" onClick={onClose} size="sm">
                  Cancel
                </Button>

                <Button
                  background="green.500"
                  color="white"
                  disabled={props.isSubmitting}
                  isLoading={isLoading}
                  size="sm"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Formik>
    </Drawer>
  );
}

export { default as MugNumberField } from './MugNumberField';
export { type PatronFormData } from './utils';
