import { Button, useDisclosure, Tooltip } from '@chakra-ui/react';
import { AddRecordForm } from '../../../../components';
import { PlusIcon } from '../../../../assets';
import { ClubStatus, IClub } from '../../../../types';

interface IProps {
  breweryId: string;
  clubs: IClub[];
  patronId: string;
  selectedClub: IClub;
}

export default function RecordVisit({
  breweryId,
  clubs,
  patronId,
  selectedClub,
}: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AddRecordForm
        clubs={clubs}
        breweryId={breweryId}
        isOpen={isOpen}
        onClose={onClose}
        patronId={patronId}
        selectedClub={selectedClub}
      />

      <Tooltip
        label={
          selectedClub.status !== ClubStatus.ACTIVE &&
          'Visits may only be recorded for active clubs. Activate this club to record visits.'
        }
      >
        <Button
          onClick={onOpen}
          size="sm"
          flexShrink={0}
          isDisabled={selectedClub.status !== ClubStatus.ACTIVE}
          leftIcon={<PlusIcon />}
        >
          Record Visit
        </Button>
      </Tooltip>
    </>
  );
}
