import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from '../../assets';

interface IProps {
  buttonText?: string;
  description?: string;
  heading?: string;
  isCompact?: boolean;
  onClick?: () => void;
}

const EmptyState = ({
  buttonText,
  description,
  heading = 'No Results Found',
  isCompact,
  onClick,
}: IProps): JSX.Element => (
  <Box
    alignItems="center"
    border="2px"
    borderColor="gray.300"
    display="flex"
    justifyContent="center"
    margin="auto"
    padding={isCompact ? 6 : 16}
    rounded="md"
    shadow="md"
  >
    <Box textAlign="center">
      <InfoCircleIcon mb={4} w={6} h={6} color="green.200" />
      <Heading as="h3" size="sm" fontWeight="medium">
        {heading}
      </Heading>

      {description && (
        <Text fontSize="sm" mt={1} color="gray.500">
          {description}
        </Text>
      )}

      {buttonText && (
        <Button mt={4} onClick={onClick} size="sm">
          {buttonText}
        </Button>
      )}
    </Box>
  </Box>
);

export default EmptyState;
