import { Box, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { IClub } from '../../types';

interface Props {
  club: IClub;
  isEditable?: boolean;
  onChange: (
    key: keyof IClub,
    value: string | null | number | Timestamp,
  ) => void;
}

export default function Cadence({
  club,
  isEditable,
  onChange,
}: Props): JSX.Element {
  const { startDate, endDate, registrationEndDate, membershipDurationMonths } =
    club;
  const isFixedDurationClub = membershipDurationMonths === null;

  function handleOnDateFieldChange(key: keyof IClub, value: string) {
    if (!value) {
      return onChange(key, null);
    }

    const date = dayjs(value).toDate();
    const timestamp = Timestamp.fromDate(date);

    onChange(key, timestamp);
  }

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Cadence
      </Text>

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
        {isFixedDurationClub && (
          <>
            <FormControl>
              <FormLabel htmlFor="startDate">Start Date</FormLabel>
              <Input
                type="date"
                value={timestampToDateString(startDate)}
                readOnly={!isEditable}
                onChange={({ currentTarget }) =>
                  handleOnDateFieldChange('startDate', currentTarget.value)
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="endDate">End Date</FormLabel>
              <Input
                type="date"
                value={timestampToDateString(endDate)}
                readOnly={!isEditable}
                onChange={({ currentTarget }) =>
                  handleOnDateFieldChange('endDate', currentTarget.value)
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="endDate">Registration Deadline</FormLabel>
              <Input
                type="date"
                value={timestampToDateString(registrationEndDate)}
                readOnly={!isEditable}
                onChange={({ currentTarget }) =>
                  handleOnDateFieldChange(
                    'registrationEndDate',
                    currentTarget.value,
                  )
                }
              />
            </FormControl>
          </>
        )}

        {!isFixedDurationClub && (
          <FormControl>
            <FormLabel htmlFor="membershipDurationMonths">
              Membership Duration in Months
            </FormLabel>

            <Input
              id="membershipDurationMonths"
              onChange={({ currentTarget }) =>
                onChange(
                  'membershipDurationMonths',
                  Number(currentTarget.value),
                )
              }
              value={membershipDurationMonths || ''}
              readOnly={!isEditable}
            />
          </FormControl>
        )}
      </Box>
    </Box>
  );
}

function timestampToDateString(
  timestamp?: Timestamp | null,
): string | undefined {
  if (!timestamp) {
    return undefined;
  }

  return dayjs(timestamp.toDate()).format('YYYY-MM-DD');
}
