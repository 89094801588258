import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ImportPatronsNotification({
  isOpen,
  onClose,
}: IProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Patrons</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={2}>👋 Hello,</Text>
          <Text mb={2}>
            We are currently working on an "Import Patrons" feature due in 2024.
          </Text>
          <Text mb={2}>
            In the meantime, please feel encouraged to email us at{' '}
            <b>chandler@mugclub.beer</b> and we can import your patrons for you.
          </Text>
          <Text mb={2}>
            Thank you for your patience as we build this feature.
          </Text>
          <Text mb={2}>Cheers,</Text>
          <Text>The MugClub.beer team</Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
