import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { IClub } from '../../../../types';

export function setNewExpirationDate(club: IClub): Date {
  const { membershipDurationMonths } = club;

  return dayjs().add(membershipDurationMonths!, 'month').toDate();
}

export function timestampToDateInput(date: Timestamp): string {
  return dayjs(date.toDate()).format('YYYY-MM-DD');
}
