import { useEffect, useState } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import { useFetchPatronMemberships } from '../../../hooks';
import { ChevronDown } from '../../../assets';
import { ClubStatus, IClub } from '../../../types';

interface IProps {
  clubs: IClub[];
  onChange: (clubId: string) => void;
  patronId: string;
}

export default function ClubSelect({
  clubs,
  onChange,
  patronId,
}: IProps): JSX.Element | null {
  const [selectedClubId, setSelectedClubId] = useState<string>();
  const [memberships] = useFetchPatronMemberships(patronId);
  const [patronClubs, setPatronClubs] = useState<IClub[]>([]);

  useEffect(() => {
    function filterAndSetPatronClubs() {
      if (!clubs || !memberships) {
        return;
      }

      const patronClubs = clubs
        .filter((club) =>
          memberships.some((membership) => membership.clubId === club.id),
        )
        .sort((a, b) => {
          return a.createdAt.toDate() > b.createdAt.toDate() ? -1 : 1;
        });

      const activeClubId = patronClubs.find(
        (club) => club.status === ClubStatus.ACTIVE,
      )?.id;

      setPatronClubs(patronClubs);
      setSelectedClubId(activeClubId || patronClubs[0]?.id);
    }

    filterAndSetPatronClubs();
  }, [clubs, memberships, patronId]);

  useEffect(() => {
    if (selectedClubId) {
      onChange(selectedClubId);
    }
  }, [onChange, selectedClubId]);

  if (patronClubs.length <= 1) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        variant="outline"
        rightIcon={<ChevronDown />}
      >
        {clubs.find((club) => club.id === selectedClubId)?.name}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup value={selectedClubId} type="radio">
          {patronClubs.map(({ id, name }) => {
            return (
              <MenuItemOption
                key={id}
                value={id}
                onClick={() => setSelectedClubId(id)}
              >
                {name}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
