import { useEffect, useState } from 'react';
import { useGetBrewery } from '../../../../contexts';
import { useFetchClubs } from '../../../../hooks';
import { ClubStatus, ProductPlan } from '../../../../types';

export function useCanActivateClub(): {
  canActivateClub: boolean;
  isLoading: boolean;
} {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canActivateClub, setCanActivateClub] = useState<boolean>(false);
  const [brewery] = useGetBrewery();
  const [clubs] = useFetchClubs(brewery.id);

  useEffect(() => {
    if (!brewery || !clubs) {
      return;
    }

    const activeClubs = clubs.filter(
      (club) => club.status === ClubStatus.ACTIVE,
    );

    if (brewery.plan === ProductPlan.STARTER && activeClubs.length >= 1) {
      setCanActivateClub(false);
    } else {
      setCanActivateClub(true);
    }

    setIsLoading(false);
  }, [brewery, clubs]);

  return {
    canActivateClub,
    isLoading,
  };
}
