import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import SectionHeading from '../SectionHeading';
import SectionNavigation from '../SectionNavigation';
import { useClubCreatorContext } from '../Context';

export default function MugNumberConfig(): JSX.Element {
  const { formValues, updateFormValues, stepBackward, stepForward } =
    useClubCreatorContext();
  const areMugNumbersEnabled = formValues.hasMugNumbers;

  function handleOnChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    const formUpdate = {
      [currentTarget.id]: currentTarget.checked,
    };

    if (currentTarget.id === 'hasMugNumbers' && !currentTarget.checked) {
      formUpdate.hasPatronMugNumberSelection = false;
    }

    updateFormValues(formUpdate);
  }

  return (
    <Box>
      <SectionHeading
        title="Mug Numbers"
        description="Configure mug or membership numbers for your club."
      />

      <FormControl mb={6}>
        <FormLabel mb={1}>Mug Numbers</FormLabel>
        <FormHelperText mb={2} mt={0}>
          Assign patrons unique mug or membership numbers.
        </FormHelperText>

        <Switch
          id="hasMugNumbers"
          isChecked={formValues.hasMugNumbers}
          onChange={handleOnChange}
        />
      </FormControl>

      <FormControl mb={6}>
        <FormLabel mb={1}>Enable Patron Mug Number Selection</FormLabel>
        <FormHelperText mb={2} mt={0}>
          Allow patrons to pick their preferred mug number during registration.
        </FormHelperText>

        <Switch
          id="hasPatronMugNumberSelection"
          isChecked={formValues.hasPatronMugNumberSelection}
          onChange={handleOnChange}
          isDisabled={!areMugNumbersEnabled}
        />
      </FormControl>

      <SectionNavigation onPrevious={stepBackward} onNext={stepForward} />
    </Box>
  );
}
