import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { updateBreweryConfig } from './../../utils';
import { FeatureGateText, MessageIcon } from '../';
import { BreweryConfigOptions } from '../../../../types';

interface IProps {
  breweryId?: string;
  configOption?: BreweryConfigOptions;
  defaultValue: string;
  description: string;
  isDefaultEnabled?: boolean;
  isFeatureGated?: boolean;
  isToggleable?: boolean;
  label: string;
  messageType: 'email' | 'sms' | 'web';
  rows?: number;
}

export default function ReadOnlyMessageTextArea({
  breweryId,
  configOption,
  defaultValue,
  description,
  isDefaultEnabled = true,
  isFeatureGated = false,
  isToggleable = false,
  label,
  messageType,
  rows = 5,
}: IProps): JSX.Element {
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const toast = useToast();

  useEffect(() => {
    setIsEnabled(isDefaultEnabled);
  }, [isDefaultEnabled]);

  async function onStatusChange(isEnabled: boolean) {
    if (!breweryId || !configOption) {
      return;
    }

    try {
      setIsEnabled(isEnabled);

      await updateBreweryConfig(breweryId, configOption, isEnabled);

      toast({
        description: `Message ${
          isEnabled ? 'enabled' : 'will no longer be sent'
        }.`,
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      setIsEnabled(!isEnabled);
      toast({
        description: `An error occurred. Please try again.`,
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <Box maxW={640}>
      <Box>
        <FormControl mb={3}>
          <FormLabel alignItems="center" display="flex" gap={1} mb={1}>
            <MessageIcon messageType={messageType} />
            {label}
          </FormLabel>
          <Text mb={3} color="gray.500" fontSize="sm">
            {description}
          </Text>
          <Textarea
            isDisabled={isFeatureGated}
            readOnly
            value={defaultValue}
            rows={rows}
          />
        </FormControl>
      </Box>

      {isFeatureGated && <FeatureGateText />}

      {!isFeatureGated && (
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box>
            {isToggleable && (
              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0" fontSize="sm">
                  Enable message
                </FormLabel>
                <Switch
                  colorScheme="green"
                  isChecked={isEnabled}
                  onChange={({ currentTarget }) =>
                    onStatusChange(currentTarget.checked)
                  }
                />
              </FormControl>
            )}
          </Box>
          <Text fontSize="xs" color="gray.500">
            <b>Note</b>: this message content is not yet editable.
          </Text>
        </Box>
      )}
    </Box>
  );
}
