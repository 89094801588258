import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../../assets';
import { EmptyState, Loader, SortableTableHeader } from '../../../components';
import PatronTableRow from './PatronsTableRow';
import { searchPatrons } from '../utils';
import { IClub, IPatron } from '../../../types';
import ClubsMenu from './ClubsMenu';

export const ALL_CLUBS = 'ALL_CLUBS';

interface IParams {
  clubs: IClub[];
  isLoading: boolean;
  patrons: IPatron[];
  showMugNumbers?: boolean;
}

function getInitialClubId(clubs: IClub[]): string {
  if (clubs.length === 1) {
    return clubs[0].id;
  }

  return ALL_CLUBS;
}

const PatronsTable = ({
  clubs,
  isLoading,
  patrons,
  showMugNumbers,
}: IParams): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [sorter, setSorter] = useState<string>('firstName');
  const [orderedDescending, setOrderedDescending] = useState<boolean>(false);
  const [clubId, setClubId] = useState<string>(ALL_CLUBS);
  const filteredPatrons = searchPatrons({
    clubId: clubId === ALL_CLUBS ? undefined : clubId,
    orderedDescending,
    patrons,
    searchValue,
    sorter,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (clubs.length) {
      const initialClubId = getInitialClubId(clubs);
      setClubId(initialClubId);
    }
  }, [clubs]);

  if (isLoading) {
    return <Loader height="60vh" text="Loading patrons..." />;
  }

  function handleClick(patronId: string) {
    navigate(`/auth/patrons/${patronId}`);
  }

  function handleSort(sortValue: string) {
    setSorter(sortValue);
    setOrderedDescending(!orderedDescending);
  }

  function handleResetFilters() {
    setSorter('firstName');
    setOrderedDescending(false);
    setClubId(ALL_CLUBS);
    setSearchValue('');
  }

  return (
    <>
      <Box mb={4} display="flex" gap={3}>
        <InputGroup maxW={350}>
          <InputLeftElement children={<SearchIcon />} />
          <Input
            value={searchValue}
            placeholder="Search patrons by name"
            onChange={({ target }) => setSearchValue(target.value)}
          />
        </InputGroup>

        {clubs?.length > 1 && (
          <Box display="flex" alignItems="center" gap={4}>
            <ClubsMenu clubs={clubs} onChange={setClubId} clubId={clubId} />

            <Button variant="ghost" size="sm" onClick={handleResetFilters}>
              Reset filters
            </Button>
          </Box>
        )}
      </Box>

      {filteredPatrons.length === 0 && (
        <Box
          alignItems="center"
          display="flex"
          h="calc(100vh - 32px - 50px - 24px - 56px)"
        >
          <EmptyState
            description="Try adjusting or clearing your search."
            heading="No Patrons Found"
          />
        </Box>
      )}

      {filteredPatrons.length > 0 && (
        <TableContainer>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <SortableTableHeader
                  active={sorter === 'firstName'}
                  onClick={() => handleSort('firstName')}
                  orderedDescending={orderedDescending}
                >
                  Name
                </SortableTableHeader>
                {showMugNumbers && (
                  <SortableTableHeader
                    active={sorter === 'mugNumber'}
                    onClick={() => handleSort('mugNumber')}
                    orderedDescending={orderedDescending}
                  >
                    Mug Number
                  </SortableTableHeader>
                )}
                <Th>Status</Th>
                <Th>Membership Expires</Th>
                <SortableTableHeader
                  active={sorter === 'createdAt'}
                  onClick={() => handleSort('createdAt')}
                  orderedDescending={orderedDescending}
                >
                  Date Joined
                </SortableTableHeader>
                <Th>Last Updated</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredPatrons?.map((patron) => (
                <PatronTableRow
                  clubId={clubId === ALL_CLUBS ? undefined : clubId}
                  key={patron.id}
                  patron={patron}
                  onClick={handleClick}
                  showMugNumbers={showMugNumbers}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default PatronsTable;
