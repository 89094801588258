import dayjs from 'dayjs';

export default function formatMembershipExpirationDate(
  timestamp?: string | null,
): string {
  if (!timestamp) {
    return 'N/A';
  }

  return dayjs(timestamp).format('M/D/YYYY');
}
