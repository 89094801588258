import { MembershipStatusBadge } from '../../../components';
import { useFetchPatronMembershipByClub } from '../../../hooks';
import { IClub, IPatron } from '../../../types';

interface IProps {
  club: IClub;
  patron: IPatron;
}

export default function PatronsTableMembershipStatus({
  club,
  patron,
}: IProps): JSX.Element {
  const [membership] = useFetchPatronMembershipByClub(patron.id, club.id);

  return <>{membership && <MembershipStatusBadge membership={membership} />}</>;
}
