import { Box, Button, Grid, Heading, Text } from '@chakra-ui/react';
import { Loader, Navigation, TextLink } from '../';
import { ArrowLeftIcon } from '../../assets';

interface IProps {
  ActionsComponent?: React.ReactNode;
  backUrl?: string;
  buttonDisabled?: boolean;
  buttonText?: string;
  children: React.ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  pageDescription?: string;
  pageHeading?: string;
}

const Layout = ({
  ActionsComponent,
  backUrl,
  buttonDisabled = false,
  buttonText,
  children,
  isLoading = false,
  onClick,
  pageDescription,
  pageHeading,
}: IProps): JSX.Element => (
  <Grid templateColumns="max-content auto" h="calc(var(--vh, 1vh) * 100)">
    <Navigation />

    <Box p={4} overflow="auto">
      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 32px)"
        >
          <Loader />
        </Box>
      )}

      {!isLoading && !pageHeading && children}

      {!isLoading && pageHeading && (
        <>
          <Box
            mb={6}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minH="50px"
          >
            <Box>
              {backUrl && (
                <TextLink to={backUrl}>
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={2}
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    <ArrowLeftIcon mr={1} />
                    Back
                  </Box>
                </TextLink>
              )}

              <Heading as="h1" fontSize="2xl">
                {pageHeading}
              </Heading>

              <Text fontSize="sm" color="gray.500">
                {pageDescription}
              </Text>
            </Box>

            {buttonText && (
              <Button
                background="green.500"
                color="white"
                onClick={onClick}
                disabled={buttonDisabled}
                size="sm"
              >
                {buttonText}
              </Button>
            )}

            {ActionsComponent && ActionsComponent}
          </Box>

          {children}
        </>
      )}
    </Box>
  </Grid>
);

export default Layout;
