import { useState } from 'react';
import { Box, Button, Input, Text } from '@chakra-ui/react';
import { PlusIcon } from '../../../assets';

interface Props {
  onAdd: (benefit: string) => void;
}

export default function AddBenefit({ onAdd }: Props): JSX.Element {
  const [value, setValue] = useState<string>('');
  const [isAdding, setIsAdding] = useState<boolean>(false);

  function handleOnSave() {
    if (!value) {
      return;
    }

    onAdd(value);
    setIsAdding(false);
    setValue('');
  }

  function handleOnCancel() {
    setIsAdding(false);
    setValue('');
  }

  if (isAdding) {
    return (
      <Box
        alignItems="center"
        border="1px solid"
        borderColor="gray.200"
        display="flex"
        justifyContent="space-between"
        gap={4}
        mb={2}
        px={4}
        py={3}
        rounded="md"
        shadow="sm"
      >
        <Box alignItems="center" display="flex" flex={1} gap={1}>
          <Input
            autoFocus
            placeholder="Add a benefit"
            rounded="md"
            value={value}
            size="sm"
            onChange={({ currentTarget }) => setValue(currentTarget.value)}
          />
        </Box>

        <Box display="flex" gap={2}>
          <Button
            size="xs"
            variant="outline"
            color="green.400"
            onClick={handleOnSave}
          >
            Save
          </Button>
          <Button
            size="xs"
            variant="outline"
            color="gray.400"
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      border="1px dashed"
      borderColor="gray.300"
      color="gray.500"
      cursor="pointer"
      display="flex"
      fontWeight="medium"
      gap={1}
      justifyContent="center"
      p={2}
      rounded="md"
      _hover={{
        bg: 'gray.50',
      }}
      onClick={() => setIsAdding(true)}
    >
      <PlusIcon />
      <Text>Add Benefit</Text>
    </Box>
  );
}
