import { useState } from 'react';
import { addDoc, collection, doc, Timestamp } from 'firebase/firestore';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useGetBrewery } from '../../contexts';
import { auth, db } from '../../firebase';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddBeerForm({ isOpen, onClose }: IProps): JSX.Element {
  const [name, setName] = useState<string>('');
  const [style, setStyle] = useState<string>('');
  const [abv, setAbv] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [brewery] = useGetBrewery();
  const toast = useToast();

  async function handleSubmit() {
    setIsLoading(true);

    try {
      const beersCollection = collection(db, 'beers');
      const breweryRef = doc(db, 'breweries', brewery.id);

      await addDoc(beersCollection, {
        abv,
        breweryRef,
        createdAt: Timestamp.now(),
        createdBy: auth.currentUser?.uid,
        description,
        isActive: false,
        name,
        style,
      });

      toast({
        description: 'Beer created.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description: 'An error occurred while creating the beer.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Beer</DrawerHeader>

        <DrawerBody>
          <VStack spacing={6}>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                type="name"
                placeholder="Name"
                onChange={({ target }) => setName(target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="type">Style</FormLabel>
              <Input
                id="style"
                type="style"
                placeholder="Style"
                onChange={({ target }) => setStyle(target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="email">ABV</FormLabel>
              <Input
                id="abv"
                type="abv"
                placeholder="ABV"
                onChange={({ target }) => setAbv(target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="description">Description</FormLabel>
              <Textarea
                id="description"
                placeholder="Description"
                onChange={({ target }) => setDescription(target.value)}
              />
            </FormControl>
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>

          <Button
            background="green.500"
            color="white"
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Add Beer
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
