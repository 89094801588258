import { SyntheticEvent, useEffect, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Spinner,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BreweryStaffSelect, Drawer } from '../../components';
import ClubSelect from './ClubSelect';
import { getDateString, parseDateStringToDate } from './utils';
import { addRecord } from '../../api';
import { useFetchBartenders } from '../../hooks';
import { IClub } from '../../types';

interface Props {
  clubs: IClub[];
  breweryId: string;
  isOpen: boolean;
  onClose: () => void;
  patronId: string;
  selectedClub?: IClub;
}

const AddRecordForm = ({
  clubs,
  breweryId,
  isOpen,
  onClose,
  patronId,
  selectedClub,
}: Props): JSX.Element => {
  const [bartenderId, setBartenderId] = useState<string>('');
  const [bartenders, isLoadingBartenders] = useFetchBartenders(breweryId);
  const [clubId, setClubId] = useState<string>('');
  const [hasClubError, setHasClubError] = useState<boolean>(false);
  const [hasBartenderError, setHasBartenderError] = useState<boolean>(false);
  const [dateString, setDateString] = useState<string | undefined>();
  const toast = useToast();

  useEffect(() => {
    const today = getDateString();

    setDateString(today);
  }, []);

  useEffect(() => {
    if (selectedClub) {
      setClubId(selectedClub.id);
    }
  }, [selectedClub]);

  async function handleOnSubmit() {
    const bartender = bartenders.find(
      (bartender) => bartender.id === bartenderId,
    );

    setHasClubError(false);
    setHasBartenderError(false);

    if (!clubId) {
      setHasClubError(true);
    }

    if (!bartender) {
      setHasBartenderError(true);
    }

    if (!clubId || !bartender) {
      return;
    }

    try {
      await addRecord({
        bartender,
        breweryId,
        clubId,
        createdAt: parseDateStringToDate(dateString),
        patronId,
      });

      toast({
        description: 'Visit recorded',
        status: 'success',
        title: 'Success!',
      });

      handleOnClose();
    } catch (error) {
      toast({
        description:
          'An error occurred recording this visit. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  function handleOnDateChange({
    currentTarget,
  }: SyntheticEvent<HTMLInputElement>) {
    setDateString(currentTarget.value);
  }

  function handleOnClose() {
    const today = getDateString();

    setBartenderId('');
    setDateString(today);
    onClose();
  }

  return (
    <Drawer
      title="Record Visit"
      buttonText="Record Visit"
      isOpen={isOpen}
      onClose={handleOnClose}
      onSubmit={handleOnSubmit}
      size="lg"
    >
      {isLoadingBartenders ? (
        <Spinner size="xl" />
      ) : (
        <VStack spacing={6}>
          <ClubSelect
            clubs={clubs}
            clubId={clubId}
            hasError={hasClubError}
            onChange={setClubId}
          />

          <FormControl isRequired isInvalid={hasBartenderError}>
            <FormLabel htmlFor="bartender">Brewery Staff Member</FormLabel>
            <BreweryStaffSelect
              breweryId={breweryId}
              onChange={setBartenderId}
            />

            <FormHelperText>
              Which member of brewery staff recorded this visit? Only active
              staff are shown.
            </FormHelperText>
            <FormErrorMessage>You must select a bartender.</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="createdAt">Date</FormLabel>
            <Input
              type="date"
              id="createdAt"
              value={dateString || ''}
              onChange={handleOnDateChange}
            />
            <FormHelperText>
              What date was this visit recorded? Defaults to today.
            </FormHelperText>
          </FormControl>
        </VStack>
      )}
    </Drawer>
  );
};

export default AddRecordForm;
