import dayjs from 'dayjs';

export function registrationClosed(
  registrationEndDate: string | null,
): boolean {
  if (!registrationEndDate) {
    return false;
  }

  const now = dayjs();

  return now.isAfter(registrationEndDate);
}
