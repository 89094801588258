import { Select } from '@chakra-ui/react';
import { useFetchBartenders } from '../../hooks';

interface IProps {
  breweryId: string;
  onChange: (bartenderId: string) => void;
}

export default function BreweryStaffSelect({
  breweryId,
  onChange,
}: IProps): JSX.Element {
  const [bartenders, isLoading] = useFetchBartenders(breweryId);

  return (
    <Select
      id="bartender"
      isDisabled={isLoading}
      defaultValue="default"
      onChange={({ target }) => onChange(target.value)}
    >
      <option value="default" disabled>
        Select Brewery Staff Member
      </option>
      {bartenders
        .filter((bartender) => bartender.isActive)
        .map((bartender) => (
          <option key={bartender.id} value={bartender.id}>
            {bartender.name}
          </option>
        ))}
    </Select>
  );
}
