import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import RewardDetailsModal from './RewardDetailsModal';
import { IClubReward, IPatronReward } from '../../../../types';

interface Props {
  clubReward: IClubReward;
  rewardStats: {
    earned: number;
    patronRewards: IPatronReward[];
    redeemed: number;
    unredeemed: number;
    ratio: string;
  };
}

export default function ClubRewardRow({
  clubReward,
  rewardStats,
}: Props): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { earned, patronRewards, redeemed, unredeemed, ratio } = rewardStats;

  function handleOnClick() {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }

  return (
    <Box
      p={2}
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      shadow="sm"
      mb={2}
    >
      <Box
        alignItems="center"
        display="grid"
        gridTemplateColumns="2.5fr 1fr 1fr 1fr 1fr 60px"
      >
        <Box>
          <Text fontWeight="semibold">{clubReward.description}</Text>
          <Text fontSize="sm" textColor="gray.500">
            Earned at {clubReward.threshold} visits
          </Text>
        </Box>

        <Text>{earned}</Text>
        <Text>{redeemed}</Text>
        <Text>{unredeemed}</Text>
        <Text>{ratio}</Text>

        <Button variant="ghost" onClick={handleOnClick} size="xs">
          Details
        </Button>
      </Box>

      <RewardDetailsModal
        clubReward={clubReward}
        patronRewards={patronRewards}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
}
