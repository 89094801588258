import { memo } from 'react';
import { Box, Button, Heading, Link, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '../../../assets';
import { IBrewery, ProductPlan } from '../../../types';

interface Props {
  brewery: IBrewery;
}

const priceByPlan: Record<ProductPlan, number> = {
  [ProductPlan.FREE]: 0,
  [ProductPlan.STARTER]: 49,
  [ProductPlan.CORE]: 99,
  [ProductPlan.COMPLETE]: 149,
};

function SubscriptionTab({ brewery }: Props): JSX.Element {
  const { plan } = brewery;

  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Manage Subscription
      </Heading>

      <Box mb={4}>
        <Text fontWeight="medium" mb={2}>
          Subscription Details
        </Text>

        <Box mb={2}>
          <Text fontWeight="medium" fontSize="sm" mb={1}>
            Plan
          </Text>
          <Text fontSize="sm" textTransform="capitalize">
            {plan}
          </Text>
        </Box>

        <Box>
          <Text fontWeight="medium" fontSize="sm" mb={1}>
            Monthly Price
          </Text>
          <Text fontSize="sm" textTransform="capitalize">
            ${priceByPlan[plan]}
          </Text>
        </Box>
      </Box>

      <Box mb={4}>
        <Text fontWeight="medium" mb={2}>
          Stripe Customer Portal
        </Text>

        <Text mb={2} fontSize="sm">
          Click the button below to manage your subscription via Stripe. You
          will be prompted to enter the email associated with your MugClub.beer
          account.
        </Text>

        <Button
          as={Link}
          leftIcon={<ExternalLinkIcon />}
          href={process.env.REACT_APP_PUBLIC_STRIPE_BILLING_PORTAL_URL}
          isExternal
          size="sm"
          variant="outline"
        >
          Open Stripe Portal
        </Button>
      </Box>

      <Box>
        <Text fontWeight="medium" mb={2}>
          Need Help?
        </Text>

        <Text fontSize="sm">
          If you have billing or subscription questions, please reach out to:{' '}
          <b>help@mugclub.beer</b>
        </Text>
      </Box>
    </Box>
  );
}

export default memo(SubscriptionTab);
