import { extendTheme, ThemeConfig, UseToastOptions } from '@chakra-ui/react';
import Button from './Button';
import Input from './Input';
import Link from './Link';
import Switch from './Switch';

export const toastConfiguration: UseToastOptions = {
  isClosable: true,
  position: 'top-right',
  variant: 'left-accent',
};

const config: ThemeConfig = {
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  components: {
    Button,
    Input,
    Link,
    Switch,
  },
});

export default theme;
