import {
  Skeleton,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';

interface IProps {
  description?: string;
  label: string;
  loading?: boolean;
  number: number | string;
}

export default function ClubMetric({
  description,
  label,
  loading,
  number,
}: IProps): JSX.Element {
  return (
    <Stat
      border="1px solid"
      borderColor="gray.300"
      rounded="md"
      width="100%"
      p={2}
    >
      <StatLabel fontSize="xs" mb={1}>
        {label}
      </StatLabel>
      <Skeleton isLoaded={!loading}>
        <StatNumber fontSize="xl">{number}</StatNumber>
      </Skeleton>
      {description && (
        <StatHelpText mb={0} fontSize="2xs">
          {description}
        </StatHelpText>
      )}
    </Stat>
  );
}
