import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { createBrewery } from '../../../../api';
import { auth } from '../../../../firebase';
import { formatBreweryData } from './utils';

interface Props {
  onSubmit: () => void;
}

export default function BreweryInfo({ onSubmit }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const toast = useToast();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsLoading(true);

    if (!auth.currentUser) {
      return;
    }

    const { email, uid } = auth.currentUser;
    const breweryData = formatBreweryData({
      email: email!,
      name,
      phoneNumber,
      uid,
      website,
    });

    try {
      await createBrewery(breweryData);
      onSubmit();
    } catch (error) {
      toast({
        isClosable: true,
        description: 'An error occurred while creating your brewery.',
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh.',
        variant: 'left-accent',
      });
      setIsLoading(false);
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <FormControl isRequired mb={4}>
        <FormLabel htmlFor="name">Brewery Name</FormLabel>
        <Input
          placeholder="Brewery Name"
          type="name"
          onChange={({ target }) => setName(target.value)}
        />
      </FormControl>

      <FormControl isRequired mb={6}>
        <FormLabel htmlFor="website">Brewery Website</FormLabel>
        <Input
          placeholder="Brewery Website"
          type="website"
          onChange={({ target }) => setWebsite(target.value)}
        />
      </FormControl>

      <FormControl isRequired mb={6}>
        <FormLabel htmlFor="phoneNumber">Brewery Phone Number</FormLabel>
        <Input
          placeholder="Brewery Phone Number"
          type="phoneNumber"
          onChange={({ target }) => setPhoneNumber(target.value)}
        />
      </FormControl>

      <Button w="100%" type="submit" isLoading={isLoading}>
        Next
      </Button>
    </Box>
  );
}
