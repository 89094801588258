import {
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

export default function Alert(): JSX.Element {
  return (
    <ChakraAlert status="error" mb={4} variant="left-accent">
      <AlertIcon />
      <AlertTitle>Club type required.</AlertTitle>
      <AlertDescription>
        Please select a club type before continuing.
      </AlertDescription>
    </ChakraAlert>
  );
}
