import { useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IBartender } from '../types';

export default function useFetchBartenders(
  breweryId?: string,
): [IBartender[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bartenders, setBartenders] = useState<IBartender[]>([]);

  useEffect(() => {
    async function fetchBartenders() {
      if (!breweryId) {
        return;
      }

      try {
        const bartendersCollection = collection(db, 'bartenders');
        const q = query(
          bartendersCollection,
          where('breweryRef', '==', doc(db, 'breweries', breweryId)),
          where('archivedAt', '==', null),
        );
        const bartenderDocs = await getDocs(q);
        const bartenders: IBartender[] = [];

        bartenderDocs.forEach((doc) => {
          bartenders.push({
            id: doc.id,
            ...doc.data(),
          } as IBartender);
        });

        setBartenders(bartenders);
      } catch (error) {
        // @TODO: Error handling
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchBartenders();
  }, [breweryId]);

  return [bartenders, isLoading];
}
