import { Spinner, Td, Tr } from '@chakra-ui/react';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import Status from './Status';
import { useFetchPatronsByClub } from '../../../hooks';
import { IClub } from '../../../types';

interface IProps {
  club: IClub;
  onClick: (url: string) => void;
}

export default function ClubsTableRow({ club, onClick }: IProps): JSX.Element {
  const [patrons, isLoading] = useFetchPatronsByClub(club.id);

  return (
    <Tr
      key={club.id}
      onClick={() => onClick(`/auth/clubs/${club.id}`)}
      cursor="pointer"
      _hover={{
        backgroundColor: 'gray.50',
      }}
    >
      <Td color="green.500">{club.name}</Td>
      <Td>
        <Status status={club.status} />
      </Td>
      <Td>{isLoading ? <Spinner /> : patrons?.length || 0}</Td>
      <Td>{formatMembershipLength(club)}</Td>
      <Td>{formatDate(club.startDate)}</Td>
      <Td>{formatDate(club.endDate)}</Td>
    </Tr>
  );
}

function formatDate(date?: Timestamp | null): string {
  if (!date) {
    return '-';
  }

  return date.toDate().toLocaleDateString();
}

function formatMembershipLength(club: IClub): string {
  const { membershipDurationMonths, startDate, endDate } = club;

  if (membershipDurationMonths) {
    return `${membershipDurationMonths} months`;
  }

  if (startDate && endDate) {
    const start = dayjs(startDate.toDate());
    const end = dayjs(endDate.toDate());

    return `${end.diff(start, 'month') + 1} months`;
  }

  return '-';
}
