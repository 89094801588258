import { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { firebaseURL } from '../../../utils';
import { DownloadCloud } from '../../../assets';
import { auth } from '../../../firebase';
import { IClub } from '../../../types';

interface Props {
  club: IClub;
}

export default function ExportRewardsButton({ club }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  async function handleExportRewards() {
    setIsLoading(true);

    try {
      const token = await auth.currentUser?.getIdToken();
      const url = `${firebaseURL()}/exportPatronRewardsByClub?clubId=${
        club.id
      }`;
      const { data } = await axios.get(url, {
        headers: {
          'firebase-auth-token': token,
        },
      });
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = `${club.name
        .split(' ')
        .join('-')}-unredeemed-rewards.csv`;
      link.click();
    } catch (error) {
      toast({
        description:
          'An error occurred exporting this report. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      size="sm"
      variant="outline"
      isLoading={isLoading}
      leftIcon={<DownloadCloud />}
      onClick={handleExportRewards}
    >
      Export Unredeemed Rewards
    </Button>
  );
}
