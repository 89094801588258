import {
  Box,
  Button,
  Link,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { Mail, Phone } from '../../assets';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function HelpModal({ isOpen, onClose }: IProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontSize="8xl" textAlign="center">
              🤔
            </Text>

            <Text fontSize="2xl" fontWeight="medium" mb={4} textAlign="center">
              Need Help?
            </Text>

            <Box
              color="gray.600"
              margin="auto"
              maxWidth={512}
              textAlign="center"
            >
              <Text mb={2}>
                We're standing by to help you get the most out of our software.
              </Text>

              <Text>
                Want a personal tour of the product? Just want to discuss mug
                club strategy? Whatever the reason, we'd love to hear from you.
              </Text>
            </Box>

            <Box margin="auto" maxWidth={512} mt={6}>
              <Box
                alignItems="center"
                border="2px solid"
                borderColor="gray.200"
                display="flex"
                flexDir="column"
                gap={2}
                mb={2}
                p={4}
                rounded="md"
                shadow="sm"
              >
                <Box
                  alignItems="center"
                  display="flex"
                  fontSize="lg"
                  fontWeight="medium"
                  gap={2}
                >
                  <Phone />
                  <Text>Call</Text>
                </Box>
                <Text fontSize="sm">
                  If you'd like to speak directly to a human, setup a call{' '}
                  <Link
                    href="https://calendly.com/mugclub/product-overview"
                    target="_blank"
                    rel="noreferrer"
                    fontWeight="bold"
                  >
                    here
                  </Link>
                  .
                </Text>
              </Box>

              <Box
                alignItems="center"
                border="2px solid"
                borderColor="gray.200"
                display="flex"
                flexDir="column"
                gap={2}
                p={4}
                rounded="md"
                shadow="sm"
              >
                <Box
                  alignItems="center"
                  display="flex"
                  fontSize="lg"
                  fontWeight="medium"
                  gap={2}
                >
                  <Mail />
                  <Text>Email</Text>
                </Box>
                <Text fontSize="sm">
                  We can be reached via email at{' '}
                  <Link href="mailto:help@mugclub.beer" fontWeight="bold">
                    help@mugclub.beer
                  </Link>
                  .
                </Text>
              </Box>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
