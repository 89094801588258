import { Box, Button, Text } from '@chakra-ui/react';

interface IProps {
  benefits: string[];
  onRemoveBenefit?: (benefit: string) => void;
}

export default function ClubBenefitsList({
  benefits,
  onRemoveBenefit,
}: IProps): JSX.Element {
  return (
    <>
      {benefits.map((benefit, idx) => (
        <Box
          key={idx}
          alignItems="center"
          border="1px solid"
          borderColor="gray.200"
          display="flex"
          gap={1}
          mb={2}
          p={4}
          rounded="md"
          shadow="sm"
        >
          <Text fontWeight="semibold" color="green.400">
            {idx + 1}.{' '}
          </Text>
          <Text>{benefit}</Text>

          {onRemoveBenefit && (
            <Button
              size="xs"
              variant="outline"
              ml="auto"
              color="gray.400"
              onClick={() => onRemoveBenefit(benefit)}
            >
              Remove
            </Button>
          )}
        </Box>
      ))}
    </>
  );
}
