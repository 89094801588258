import { Box, Text } from '@chakra-ui/react';

export default function WelcomeStep(): JSX.Element {
  return (
    <Box>
      <Text fontSize="8xl" textAlign="center">
        👋
      </Text>

      <Text fontSize="2xl" fontWeight="medium" mb={4} textAlign="center">
        Welcome to MugClub.beer!
      </Text>

      <Box color="gray.600" margin="auto" maxWidth={512} textAlign="center">
        <Text mb={2}>
          We help breweries build, grow, and manage their mug clubs.
        </Text>

        <Text>We've put together a brief overview to get you started.</Text>
      </Box>
    </Box>
  );
}
