import { useState } from 'react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Select,
  useToast,
  Box,
} from '@chakra-ui/react';
import { BreweryStaffSelect, Drawer } from '../../../../components';
import { IClub, ITrackedBenefit } from '../../../../types';
import { db } from '../../../../firebase';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  patronId: string;
  selectedClub: IClub;
}

export default function TrackBenefitsForm({
  isOpen,
  onClose,
  patronId,
  selectedClub,
}: IProps): JSX.Element {
  const [benefit, setBenefit] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('');
  const [hasBenefitError, setHasBenefitError] = useState<boolean>(false);
  const [hasQuantityError, setHasQuantityError] = useState<boolean>(false);
  const [hasStaffError, setHasStaffError] = useState<boolean>(false);
  const [staffId, setStaffId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  async function handleOnSubmit() {
    setHasBenefitError(false);
    setHasQuantityError(false);
    setHasStaffError(false);

    if (!benefit) {
      setHasBenefitError(true);
    }

    if (!quantity) {
      setHasQuantityError(true);
    }

    if (!staffId) {
      setHasStaffError(true);
    }

    if (!benefit || !quantity || !staffId) {
      return;
    }

    setIsLoading(true);

    const trackedBenefitsCollection = collection(db, 'trackedBenefits');

    try {
      const requests: (() => Promise<void>)[] = [];

      for (let i = 0; i < Number(quantity); i++) {
        const trackedBenefitData: Omit<ITrackedBenefit, 'id'> = {
          benefit,
          breweryId: selectedClub.breweryRef.id,
          clubId: selectedClub.id,
          createdAt: Timestamp.now(),
          createdBy: staffId,
          patronId,
        };

        requests.push(async () => {
          await addDoc(trackedBenefitsCollection, trackedBenefitData);
        });
      }

      for (const request of requests) {
        await request();
      }

      toast({
        description: 'Benefit recorded',
        status: 'success',
        title: 'Success!',
      });

      handleOnClose();
    } catch (error) {
      toast({
        description:
          'An error occurred tracking this benefit. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleOnClose() {
    setBenefit('');
    setStaffId('');
    setHasBenefitError(false);
    setHasStaffError(false);
    setIsLoading(false);

    onClose();
  }

  return (
    <Drawer
      title="Track Benefit"
      buttonText="Track Benefit"
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={handleOnClose}
      onSubmit={handleOnSubmit}
      size="lg"
    >
      <Box>
        <FormControl isRequired isInvalid={hasBenefitError} mb={6}>
          <FormLabel htmlFor="benefit">Benefit</FormLabel>
          <Select
            id="benefit"
            defaultValue="default"
            onChange={({ currentTarget }) => setBenefit(currentTarget.value)}
          >
            <option value="default" disabled>
              Select Benefit
            </option>
            {selectedClub.benefits.map((benefit) => (
              <option key={benefit} value={benefit}>
                {benefit}
              </option>
            ))}
          </Select>

          <FormHelperText>Which club benefit is being tracked?</FormHelperText>
          <FormErrorMessage>You must select a bartender.</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={hasQuantityError} mb={6}>
          <FormLabel htmlFor="quantity">Quantity</FormLabel>
          <Select
            id="quantity"
            defaultValue="default"
            onChange={({ currentTarget }) => setQuantity(currentTarget.value)}
          >
            <option value="default" disabled>
              Select Quantity
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </Select>

          <FormHelperText>Which club benefit is being tracked?</FormHelperText>
          <FormErrorMessage>You must select a bartender.</FormErrorMessage>
        </FormControl>
      </Box>

      <FormControl isRequired isInvalid={hasStaffError}>
        <FormLabel htmlFor="bartender">Brewery Staff Member</FormLabel>
        <BreweryStaffSelect
          breweryId={selectedClub.breweryRef.id}
          onChange={setStaffId}
        />

        <FormHelperText>
          Which member of brewery staff recorded this visit? Only active staff
          are shown.
        </FormHelperText>
        <FormErrorMessage>You must select a bartender.</FormErrorMessage>
      </FormControl>
    </Drawer>
  );
}
