import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../../firebase';
import { IClub, IMembership, IPatron } from '../../../../types';

export async function createMembership(
  patron: IPatron,
  club: IClub,
): Promise<void> {
  const membership: Omit<IMembership, 'id'> = {
    activatedAt: null,
    breweryId: patron.breweryRef.id,
    clubId: club.id,
    createdAt: Timestamp.now(),
    expiresAt: club.endDate || null,
    isActive: false,
    patronId: patron.id,
  };
  const membershipsCollection = collection(db, 'memberships');

  await addDoc(membershipsCollection, membership);
  await updatePatron(patron, club);
}

export async function updatePatron(
  patron: IPatron,
  club: IClub,
): Promise<void> {
  const patronRef = doc(db, `patrons/${patron.id}`);

  await updateDoc(patronRef, {
    clubs: arrayUnion(club.id),
  });
}
