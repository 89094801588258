import { Box, Heading } from '@chakra-ui/react';
import RewardsList from './RewardsList';
import ExportRewardsButton from './ExportRewardsButton';
import { IClub, IPatronReward } from '../../../types';

interface IParams {
  club: IClub;
  rewards: IPatronReward[];
}

export default function ClubRewards({ club, rewards }: IParams): JSX.Element {
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={6}>
        <Heading as="h2" fontSize="lg">
          Rewards
        </Heading>

        <ExportRewardsButton club={club} />
      </Box>

      <RewardsList club={club} rewards={rewards} />
    </>
  );
}
