import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { EmptyState } from '../../components';
import { IBeer } from '../../types';

interface IProps {
  beers: IBeer[];
  onClick: () => void;
}

export default function BeersTable({ beers, onClick }: IProps): JSX.Element {
  if (beers.length === 0) {
    return (
      <EmptyState
        buttonText="Add Beer"
        heading="No Beers Found"
        description="Populate a menu of beers to cast to a screen in your taproom."
        onClick={onClick}
      />
    );
  }

  return (
    <TableContainer maxW="100%" whiteSpace="pre-wrap">
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Style</Th>
            <Th>ABV</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {beers.map((beer) => (
            <Tr key={beer.id}>
              <Td>{beer.name}</Td>
              <Td>{beer.style}</Td>
              <Td>{beer.abv}%</Td>
              <Td maxWidth="500px">{beer.description}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
