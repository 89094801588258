import dayjs from 'dayjs';
import { Box, Text } from '@chakra-ui/react';
import { EmptyState, TextLink } from '../../../../components';
import { getRecordsByDate, mapRecordsToPatrons } from './utils';
import { IPatron, IRecord } from '../../../../types';

interface IProps {
  date: string;
  patrons: IPatron[];
  records: IRecord[];
}

export default function RecordsByDate({
  date,
  patrons,
  records,
}: IProps): JSX.Element {
  const recordsByDate = getRecordsByDate(date, records);
  const recordsMappedToPatrons = mapRecordsToPatrons(recordsByDate, patrons);
  const formattedDate = dayjs(date).format('MM/DD/YYYY');

  return (
    <Box>
      <Text fontSize="large" textAlign="right" mb={3}>
        <b>{recordsByDate.length}</b> records added on {formattedDate}
      </Text>

      {recordsMappedToPatrons.length === 0 && (
        <EmptyState
          heading="No Records Found"
          description="Try another date or clear your search"
        />
      )}

      {recordsMappedToPatrons.map((record) => (
        <Box
          key={record.id}
          p={4}
          mb={4}
          border="1px solid"
          borderColor="gray.300"
          borderRadius={4}
        >
          <TextLink to={`/auth/patrons/${record.patron.id}`}>
            {record.patron.firstName} {record.patron.lastName}
          </TextLink>
        </Box>
      ))}
    </Box>
  );
}
