import { Box, Text, Button } from '@chakra-ui/react';
import { LockIcon, UnlockIcon } from '../../../assets';
import { IClubReward, IPatronReward } from '../../../types';

interface IProps {
  earnedReward?: IPatronReward;
  onRedeemReward: (rewardId: string) => void;
  onUnredeemReward: (rewardId: string) => void;
  reward: IClubReward;
}

export default function Reward({
  earnedReward,
  onRedeemReward,
  onUnredeemReward,
  reward,
}: IProps): JSX.Element {
  return (
    <Box
      key={reward.id}
      rounded="md"
      shadow="sm"
      border={earnedReward ? '1px solid' : 'none'}
      borderColor="gray.200"
      bg={earnedReward ? 'white' : 'gray.200'}
      py={2}
      px={3}
      display="grid"
      gridTemplateColumns="min-content auto max-content"
      gap={4}
      alignItems="center"
      w="100%"
    >
      {earnedReward ? (
        <UnlockIcon color="green.500" fontSize="lg" />
      ) : (
        <LockIcon color="gray.600" fontSize="lg" />
      )}

      <Box>
        <Text fontWeight="medium" fontSize="sm">
          {reward.description}
        </Text>
        {earnedReward && (
          <Text
            color={earnedReward.redeemed ? 'green.400' : 'gray.400'}
            fontSize="xs"
          >
            {earnedReward.redeemed
              ? `Redeemed on ${earnedReward.redeemedAt
                  ?.toDate()
                  .toLocaleDateString()}`
              : `Earned on ${earnedReward.createdAt
                  .toDate()
                  .toLocaleDateString()}`}
          </Text>
        )}
        {!earnedReward && (
          <Text fontSize="xs" color="gray.600">
            Will be earned at {reward.threshold} visits
          </Text>
        )}
      </Box>

      {earnedReward?.redeemed && (
        <Button
          colorScheme="gray"
          size="xs"
          variant="outline"
          onClick={() => onUnredeemReward(earnedReward.id)}
        >
          Unredeem
        </Button>
      )}

      {earnedReward && !earnedReward.redeemed && (
        <Button size="xs" onClick={() => onRedeemReward(earnedReward.id)}>
          Redeem
        </Button>
      )}
    </Box>
  );
}
