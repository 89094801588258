import { useMemo } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import { ChevronDown } from '../../../assets';
import { IClub } from '../../../types';
import { ALL_CLUBS } from '.';

interface IProps {
  clubId: string;
  clubs: IClub[];
  onChange: (clubId: string) => void;
}

export default function ClubsMenu({
  clubId,
  clubs,
  onChange,
}: IProps): JSX.Element {
  const sortedClubs = useMemo(() => {
    return clubs.sort((a, b) => {
      if (a.createdAt.toDate() > b.createdAt.toDate()) {
        return -1;
      }

      return 1;
    });
  }, [clubs]);

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        rightIcon={<ChevronDown />}
        borderColor="green.200"
      >
        {clubId === ALL_CLUBS && 'All Clubs'}
        {clubId !== ALL_CLUBS && clubs.find((club) => club.id === clubId)?.name}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup value={clubId} type="radio">
          <MenuItemOption value={ALL_CLUBS} onClick={() => onChange(ALL_CLUBS)}>
            All Clubs
          </MenuItemOption>
          {sortedClubs.map(({ id, name }) => {
            return (
              <MenuItemOption key={id} value={id} onClick={() => onChange(id)}>
                {name}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
