import { useCallback, useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IPatron } from '../types';

export default function useFetchPatrons(
  breweryId?: string,
  newPatronId?: string,
): [IPatron[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patronsData, setPatronsData] = useState<IPatron[]>([]);

  const fetchPatrons = useCallback(async () => {
    if (!breweryId) {
      setIsLoading(false);
      return;
    }

    const patronsCollection = collection(db, 'patrons');
    const q = query(
      patronsCollection,
      where('breweryRef', '==', doc(db, 'breweries', breweryId)),
    );

    try {
      const patronDocs = await getDocs(q);
      const patrons: IPatron[] = [];

      patronDocs.forEach((doc) => {
        const { mugNumber } = doc.data();

        patrons.push({
          id: doc.id,
          ...doc.data(),
          mugNumber: mugNumber ? parseInt(mugNumber) : null,
        } as IPatron);
      });

      setPatronsData(patrons);
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [breweryId]);

  useEffect(() => {
    fetchPatrons();
  }, [breweryId, newPatronId, fetchPatrons]);

  return [patronsData, isLoading];
}
