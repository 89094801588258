import { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Link,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { PasswordInput, PublicLayout } from '../../components';
import { AlertCircle, CheckCircle } from '../../assets';
import { auth } from '../../firebase';
import { useQueryParams } from '../../hooks';

const Success = () => (
  <Box textAlign="center" bg="white" rounded="md" shadow="md" px={4} py={8}>
    <CheckCircle fontSize="4xl" mb={4} color="green.500" />

    <Heading size="lg" as="h1" mb={8}>
      Password Reset Successful
    </Heading>

    <Text color="gray.500">
      <Link as={ReactRouterLink} to="/">
        Click here
      </Link>{' '}
      to return to the Login page and login with your new password.
    </Text>
  </Box>
);

const InvalidCode = () => (
  <Box textAlign="center" bg="white" rounded="md" shadow="md" px={4} py={8}>
    <AlertCircle fontSize="4xl" mb={4} color="orange.500" />

    <Heading size="lg" as="h1" mb={8}>
      Invalid Password Reset Code
    </Heading>

    <Text mb={8} color="gray.500">
      The code used to verify your identify is no longer valid. To request a new
      password reset code,{' '}
      <Link as={ReactRouterLink} to="/request-password-reset">
        click here
      </Link>
      .
    </Text>

    <Text color="gray.500" mb={8}>
      Need help? Email us directly at{' '}
      <Link href="mailto:help@mugclub.beer">help@mugclub.beer</Link>.
    </Text>

    <Text color="gray.500">
      <Link as={ReactRouterLink} to="/">
        Click here
      </Link>{' '}
      to return to the Login page.
    </Text>
  </Box>
);

export default function ResetPassword(): JSX.Element {
  const query = useQueryParams();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [hasCodeError, setHasCodeError] = useState<boolean>(false);
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
  const oobCode = query.get('oobCode');

  useEffect(() => {
    async function verifyCode() {
      if (!oobCode) {
        return;
      }

      try {
        const email = await verifyPasswordResetCode(auth, oobCode);
        setEmail(email);
      } catch (error) {
        setHasCodeError(true);
      }
    }

    verifyCode();
  }, [oobCode]);

  async function handleOnSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    setHasCodeError(false);
    setIsLoading(true);

    try {
      await confirmPasswordReset(auth, oobCode!, password);
      setShowSuccess(true);
    } catch (error) {
      setHasPasswordError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <PublicLayout>
      {showSuccess && <Success />}
      {hasCodeError && <InvalidCode />}
      {email && !showSuccess && (
        <>
          <Heading as="h1" mb={2}>
            Reset Password
          </Heading>
          <Text mb={9} color="gray.500">
            Enter a new password to complete the password reset process.
          </Text>

          <form onSubmit={handleOnSubmit}>
            <VStack spacing={6}>
              <FormControl isRequired>
                <FormLabel htmlFor="email">Email</FormLabel>

                <Input
                  id="email"
                  type="email"
                  placeholder="email"
                  readOnly
                  defaultValue={email}
                />
              </FormControl>

              <FormControl isRequired isInvalid={hasPasswordError}>
                <FormLabel htmlFor="password">Password</FormLabel>

                <PasswordInput id="password" onChange={setPassword} />

                <FormErrorMessage>
                  Passwords must be at least six characters in length.
                </FormErrorMessage>
              </FormControl>

              <Button
                w="100%"
                type="submit"
                bg="green.500"
                color="white"
                isLoading={isLoading}
              >
                Reset Password
              </Button>
            </VStack>
          </form>
        </>
      )}
    </PublicLayout>
  );
}
