import { Box, Heading, Text } from '@chakra-ui/react';
import { useGetBrewery } from '../../../contexts';
import { IPatron } from '../../../types';

interface Props {
  patron: IPatron;
}

export default function PatronNote({ patron }: Props): JSX.Element {
  const [brewery] = useGetBrewery();
  const patronNotes = patron?.notes?.[brewery?.id];

  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      shadow="sm"
      py={2}
      px={3}
    >
      <Heading as="h2" size="sm" mb={3}>
        Notes
      </Heading>

      <Text mb={2} maxH="200px" overflow="auto">
        {patronNotes ? patronNotes : 'N/A'}
      </Text>
    </Box>
  );
}
