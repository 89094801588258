import { Box, Heading } from '@chakra-ui/react';
import { auth } from '../../firebase';
import { TextLink } from '../../components';

export default function NotFoundPage(): JSX.Element {
  const { currentUser } = auth;
  const homeUrl = currentUser ? '/auth/clubs' : '/';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="calc(var(--vh, 1vh) * 100)"
    >
      <Box textAlign="center">
        <Heading mb={4}>Page Not Found</Heading>
        <TextLink to={homeUrl}>Return Home</TextLink>
      </Box>
    </Box>
  );
}
