import { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import { useGetBrewery } from '../../../../contexts';
import { updatePatronNotes } from '../../../../api';
import { IPatron } from '../../../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  patron?: IPatron;
}

export default function Notes({ isOpen, onClose, patron }: Props): JSX.Element {
  const [value, setValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const [brewery] = useGetBrewery();

  useEffect(() => {
    const { id } = brewery;
    const patronNotes = patron?.notes?.[id];

    if (patronNotes) {
      setValue(patronNotes);
    }
  }, [brewery, patron]);

  async function handleOnSubmit(): Promise<void> {
    setIsLoading(true);

    if (!patron) {
      return;
    }

    try {
      await updatePatronNotes(patron.id, {
        breweryId: brewery.id,
        notes: value,
      });

      toast({
        description: 'Patron notes have been updated.',
        status: 'success',
        title: 'Success!',
      });

      onClose();
    } catch (error) {
      toast({
        description:
          'An error occurred updating the patron notes. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Patron Notes</DrawerHeader>

        <DrawerBody>
          <FormControl mb={4} isRequired>
            <FormLabel>Patron Notes</FormLabel>
            <Textarea
              placeholder="Patron Notes"
              value={value}
              onChange={({ currentTarget }) => setValue(currentTarget.value)}
              rows={5}
            />
          </FormControl>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleOnSubmit} isLoading={isLoading}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
