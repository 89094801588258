import { useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { Box, Button, Skeleton, Td, Tr } from '@chakra-ui/react';
import { CheckCircle, EditIcon, PauseCircle } from '../../../assets';
import { db } from '../../../firebase';
import { IBartender } from '../../../types';

interface IProps {
  bartender: IBartender;
  onClick: (bartender: IBartender) => void;
}

export default function BartendersTableRow({
  bartender,
  onClick,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visitCount, setVisitCount] = useState<number>(0);

  useEffect(() => {
    async function fetchRecordsByBartender() {
      const recordsCollection = collection(db, 'records');
      const bartenderRef = doc(db, 'bartenders', bartender.id);
      const q = query(
        recordsCollection,
        where('bartender.ref', '==', bartenderRef),
      );

      try {
        const records = await getDocs(q);

        setVisitCount(records.size);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchRecordsByBartender();
  }, [bartender]);

  return (
    <Tr key={bartender.id}>
      <Td>{bartender.name}</Td>
      <Td>
        <Skeleton isLoaded={!isLoading}>{visitCount.toLocaleString()}</Skeleton>
      </Td>
      <Td>
        {bartender.isActive ? (
          <Box
            alignItems="center"
            bg="green.100"
            color="green.800"
            display="inline-flex"
            fontSize="xs"
            fontWeight="medium"
            gap={1}
            px={1}
            rounded="md"
            textTransform="uppercase"
          >
            <CheckCircle /> Active
          </Box>
        ) : (
          <Box
            alignItems="center"
            bg="gray.300"
            color="gray.800"
            display="inline-flex"
            fontSize="xs"
            fontWeight="medium"
            gap={1}
            px={1}
            rounded="md"
            textTransform="uppercase"
          >
            <PauseCircle /> Inactive
          </Box>
        )}
      </Td>
      <Td>{bartender.createdAt?.toDate().toLocaleDateString()}</Td>
      <Td textAlign="right">
        <Button
          leftIcon={<EditIcon />}
          onClick={() => onClick(bartender)}
          size="xs"
          variant="outline"
        >
          Edit
        </Button>
      </Td>
    </Tr>
  );
}
