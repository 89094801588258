import { BreweryConfigOptions, IBreweryConfig } from '../types';

export const CONFIG_CLUB_SIGN_UP_CONFIRMATION =
  'You have successfully signed up! In order to complete your purchase, please check-in with a bartender for next steps.';
export const CONFIG_CLUB_SIGN_UP_INSTRUCTIONS =
  'Thank you for your interest in our mug club. Please complete the following form and then see a bartender to complete the purchase of your membership.';

export const defaultBreweryConfig: IBreweryConfig = {
  [BreweryConfigOptions.birthdayMessageEnabled]: true,
  [BreweryConfigOptions.clubSignUpConfirmation]:
    CONFIG_CLUB_SIGN_UP_CONFIRMATION,
  [BreweryConfigOptions.clubSignUpInstructions]:
    CONFIG_CLUB_SIGN_UP_INSTRUCTIONS,
  [BreweryConfigOptions.patronEarnedRewardEmailEnabled]: true,
  [BreweryConfigOptions.patronSignedUpForClubEmailEnabled]: true,
};
