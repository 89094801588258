import { SyntheticEvent, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import ClubBenefitsList from './ClubBenefitsList';
import SectionHeading from './SectionHeading';
import SectionNavigation from './SectionNavigation';
import { useClubCreatorContext } from './Context';
import { EmptyState } from '../';
import { PlusIcon } from '../../assets';

export interface IClubDetails {
  name: string;
  startDate: string;
  endDate: string;
  capacity: string;
  cost: string;
  renewalCost: string;
}

export default function ClubBenefits(): JSX.Element {
  const [currentBenefit, setCurrentBenefit] = useState<string>('');
  const { formValues, stepForward, stepBackward, updateFormValues } =
    useClubCreatorContext();
  const { benefits } = formValues;

  function handleOnSubmit() {
    stepForward();
  }

  function handleAddBenefit(event: SyntheticEvent) {
    event.preventDefault();

    const updatedBenefits = [...benefits, currentBenefit];

    updateFormValues({ benefits: updatedBenefits });
    setCurrentBenefit('');
  }

  function handleRemoveBenefit(benefit: string) {
    const updatedBenefits: string[] = benefits.filter((b) => b !== benefit);

    updateFormValues({ benefits: updatedBenefits });
  }

  return (
    <Box>
      <SectionHeading
        title="Club Benefits"
        description="Add your club's benefits one at a time. Benefits are enjoyed by all members of your club. They differ from rewards which must be earned. Rewards can be added in the next section."
      />
      <VStack spacing={4} mb={6} alignItems="left">
        <FormControl>
          <FormLabel htmlFor="benefit" fontSize="sm">
            Benefit
          </FormLabel>

          <Box display="flex" gap={4} as="form" onSubmit={handleAddBenefit}>
            <Input
              id="benefit"
              placeholder="Benefit"
              value={currentBenefit}
              onChange={({ currentTarget }: SyntheticEvent<HTMLInputElement>) =>
                setCurrentBenefit(currentTarget.value)
              }
              size="sm"
            />

            <Button
              leftIcon={<PlusIcon />}
              flexShrink={0}
              isDisabled={!currentBenefit}
              type="submit"
              size="sm"
            >
              Add Benefit
            </Button>
          </Box>
        </FormControl>

        <Box>
          <Text fontWeight="medium" mb={2}>
            Benefits
          </Text>
          {benefits.length === 0 && (
            <EmptyState
              heading="No Benefits Added"
              description="Use the input above to add new benefits"
              isCompact
            />
          )}

          <ClubBenefitsList
            benefits={benefits}
            onRemoveBenefit={handleRemoveBenefit}
          />
        </Box>
      </VStack>

      <SectionNavigation onNext={handleOnSubmit} onPrevious={stepBackward} />
    </Box>
  );
}
