import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  VStack,
  Input,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import MissingCheckoutModal from './MissingCheckoutModal';
import {
  FullscreenLoader,
  PasswordInput,
  PublicLayout,
} from '../../components';
import { useRouteAuthenticatedUser, useQueryParams } from '../../hooks';
import { auth, functions } from '../../firebase';

export default function SignUpPage() {
  const query = useQueryParams();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasCOSessionError, setHasCOSessionError] = useState<boolean>(false);
  const navigate = useNavigate();
  useRouteAuthenticatedUser();

  useEffect(() => {
    async function getEmail() {
      const checkoutId = query.get('co');

      if (!checkoutId) {
        setHasCOSessionError(true);
        return;
      }

      const getEmailFromCheckoutId = httpsCallable(
        functions,
        'getEmailFromCheckoutSession',
      );

      try {
        const { data }: any = await getEmailFromCheckoutId({ checkoutId });
        setEmail(data.email);
      } catch (error) {
        setHasCOSessionError(true);
      } finally {
        setIsLoading(false);
      }
    }

    getEmail();
  }, [query]);

  async function handleOnSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    setHasError(false);

    if (!password) {
      setHasError(true);
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);

      navigate('/get-started');
    } catch (error) {
      setHasCOSessionError(true);
    }
  }

  if (isLoading) {
    return <FullscreenLoader />;
  }

  return (
    <PublicLayout>
      {hasCOSessionError && <MissingCheckoutModal />}

      <Heading as="h1" mb={1}>
        Create Account
      </Heading>
      <Text mb={8} color="gray.500">
        Please set a password for your MugClub.beer account.
      </Text>

      <form onSubmit={handleOnSubmit}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={hasError}>
            <FormLabel htmlFor="password">Password</FormLabel>

            <PasswordInput id="password" onChange={setPassword} />

            <FormErrorMessage>A password is required.</FormErrorMessage>
          </FormControl>

          <Button w="100%" type="submit">
            Create Account
          </Button>
        </VStack>
      </form>
    </PublicLayout>
  );
}
