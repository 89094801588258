import { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Select,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Loader from './Loader';
import { useGetBrewery } from '../../../contexts';
import { useFetchClubs } from '../../../hooks';
import { DownloadCloud } from '../../../assets';
import { exportPatronsByClub } from './utils';

export default function PatronExportTab(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedClub, setSelectedClub] = useState<string>('');
  const [brewery] = useGetBrewery();
  const [clubs] = useFetchClubs(brewery?.id);
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  async function handleExportPatronsByClub() {
    if (!selectedClub) {
      return;
    }

    setIsLoading(true);
    onOpen();

    try {
      await exportPatronsByClub(selectedClub, clubs);

      toast({
        description: 'Your patrons have been exported to CSV.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description: 'An error occurred exporting your patrons to CSV.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <>
      <Loader isOpen={isOpen} onClose={onClose} />

      <Box maxWidth={512}>
        <Heading as="h2" fontSize="lg" mb={4}>
          Patron Export
        </Heading>

        <>
          <Text fontWeight="medium">Export Patrons By Club</Text>
          <Text mb={2} color="gray.500" fontSize="sm">
            Export all patrons for a specific club to CSV.
          </Text>

          <Box display="flex" gap={2}>
            <Select
              onChange={({ currentTarget }) =>
                setSelectedClub(currentTarget.value)
              }
              defaultValue=""
              size="sm"
            >
              <option value="" disabled>
                Select a Club
              </option>
              {clubs.map((club) => (
                <option key={club.id} value={club.id}>
                  {club.name}
                </option>
              ))}
            </Select>

            <Button
              colorScheme="green"
              flexShrink={0}
              isDisabled={!selectedClub}
              isLoading={isLoading}
              leftIcon={<DownloadCloud />}
              onClick={handleExportPatronsByClub}
              size="sm"
            >
              Export
            </Button>
          </Box>
        </>
      </Box>
    </>
  );
}
