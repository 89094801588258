import { Box, Heading } from '@chakra-ui/react';
import SignUp from './SignUp';
import { IClub } from '../../../types';

interface IParams {
  club: IClub;
}

export default function ClubSettings({ club }: IParams): JSX.Element {
  return (
    <Box>
      <Heading as="h2" fontSize="lg" mb={4}>
        Patron Signup
      </Heading>

      {club && <SignUp club={club} />}
    </Box>
  );
}
