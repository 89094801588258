import { Menu, MenuButton, MenuList, IconButton } from '@chakra-ui/react';
import { OptionsIcon } from '../../assets';

interface IProps {
  children: React.ReactNode;
}

export default function OptionsMenu({ children }: IProps): JSX.Element {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        colorScheme="gray"
        icon={<OptionsIcon />}
        variant="outline"
        size="sm"
      />
      <MenuList>{children}</MenuList>
    </Menu>
  );
}
