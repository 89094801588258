import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import ClubStatusAlert from './ClubStatusAlert';
import PatronActions from './PatronActions';
import MembershipStatus from './MembershipStatus';
import PatronDetails from './PatronDetails';
import PatronNote from './PatronNote';
import ActivityTabs from './ActivityTabs';
import { PatronProvider, usePatronContext } from './Context';
import { EmptyState, Layout } from '../../components';
import { useFetchClubs } from '../../hooks';
import { useGetBrewery } from '../../contexts';
import { IClub } from '../../types';

function PatronPage(): JSX.Element {
  const { uid } = useParams();
  const { patron } = usePatronContext();
  const [brewery] = useGetBrewery();
  const [clubs] = useFetchClubs(brewery?.id);
  const [selectedClub, setSelectedClub] = useState<IClub | undefined>();

  function handleClubChange(clubId: string) {
    const club = clubs.find((club) => club.id === clubId);
    setSelectedClub(club!);
  }

  if (!uid) {
    return <Text>Patron Not Found</Text>;
  }

  return (
    <Layout
      backUrl="/auth/patrons"
      isLoading={!patron}
      pageDescription="View a patron's details, records, and rewards progress."
      pageHeading={`${patron?.firstName} ${patron?.lastName}`}
      ActionsComponent={
        <PatronActions
          brewery={brewery}
          clubs={clubs}
          onClubChange={handleClubChange}
          patron={patron}
          selectedClub={selectedClub}
        />
      }
    >
      {patron && selectedClub && (
        <>
          <ClubStatusAlert club={selectedClub} />

          <Box display="grid" gridTemplateColumns="300px auto" gap={4}>
            <Box>
              <Box mb={4}>
                <MembershipStatus club={selectedClub} patron={patron} />
              </Box>

              <Box mb={4}>
                <PatronDetails patron={patron} />
              </Box>

              <PatronNote patron={patron} />
            </Box>

            <Box>
              <ActivityTabs
                brewery={brewery}
                club={selectedClub}
                patronId={uid}
              />
            </Box>
          </Box>
        </>
      )}

      {patron && !selectedClub && (
        <Box>
          <EmptyState
            heading="No Memberships Found"
            description="This patron does not have any memberships."
          />
        </Box>
      )}
    </Layout>
  );
}

export default function PatronPageAndProvider(): JSX.Element {
  return (
    <PatronProvider>
      <PatronPage />
    </PatronProvider>
  );
}
