import { Box, Button, Input, Link, Text } from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import { ExternalLinkIcon } from '../../../../assets';
import { isDevelopment, isStaging } from '../../../../utils';
import { IClub } from '../../../../types';

interface IProps {
  club: IClub;
}

function getSignUpURL(club: IClub): string {
  if (isDevelopment()) {
    return `http://localhost:3000/patron-sign-up/${club.id}`;
  }

  if (isStaging()) {
    return `https://dev.mugclub.beer/patron-sign-up/${club.id}`;
  }

  return `https://app.mugclub.beer/patron-sign-up/${club.id}`;
}

export default function SignUp({ club }: IProps): JSX.Element {
  const signUpURL = getSignUpURL(club);

  return (
    <>
      <Box mb={6}>
        <Box mb={4}>
          <Text fontWeight="medium">Sign Up QR Code</Text>
          <Text fontSize="sm" color="gray.600">
            Display this QR code on premise to direct patrons to your club's
            sign up website.
          </Text>
        </Box>

        <QRCode value={signUpURL} />
      </Box>

      <Box>
        <Box mb={4}>
          <Text fontWeight="medium">Sign Up Website</Text>
          <Text fontSize="sm" color="gray.600">
            This is the URL stored in the QR code above.
          </Text>
        </Box>

        <Box display="flex" alignItems="center" gap={2}>
          <Input isReadOnly value={signUpURL} w={500} />

          <Button
            leftIcon={<ExternalLinkIcon />}
            variant="outline"
            as={Link}
            href={signUpURL}
            isExternal
          >
            Open
          </Button>
        </Box>
      </Box>
    </>
  );
}
