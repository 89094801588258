import { IPatron } from '../../types';

export interface PatronFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthdayMonth: string | null;
  mugNumber: number | null;
  shirtSize: string | null;
}

export interface PatronFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthdayMonth?: string;
  mugNumber: string;
  shirtSize?: string;
}

export function getFormValues(patron?: IPatron): PatronFormValues {
  if (!patron) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      birthdayMonth: '',
      mugNumber: '',
      shirtSize: '',
    };
  }

  return {
    firstName: patron.firstName,
    lastName: patron.lastName,
    email: patron.email,
    phoneNumber: patron.phoneNumber,
    birthdayMonth: patron.birthdayMonth || '',
    mugNumber: parseMugNumberToString(patron.mugNumber),
    shirtSize: patron.shirtSize || '',
  };
}

export function getFormData(values: PatronFormValues): PatronFormData {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phoneNumber: values.phoneNumber,
    birthdayMonth: values.birthdayMonth || null,
    mugNumber: parseMugNumberToInt(values.mugNumber),
    shirtSize: values.shirtSize || null,
  };
}

function parseMugNumberToInt(value?: string): number | null {
  if (!value?.trim() || value === 'default') {
    return null;
  }

  return parseInt(value);
}

function parseMugNumberToString(value?: number | null): string {
  if (!value) {
    return '';
  }

  return String(value);
}
