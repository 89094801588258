import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import PatronBenefits from './PatronBenefits';
import PatronRewards from '../PatronRewards';
import PatronVisits from '../PatronVisits';
import { Features, IBrewery, IClub } from '../../../types';

interface IProps {
  brewery: IBrewery;
  club: IClub;
  patronId: string;
}

export default function ActivityTabs({
  brewery,
  club,
  patronId,
}: IProps): JSX.Element {
  const { features } = brewery;
  const breweryTracksBenefits = features?.includes(Features.BENEFITS_TRACKING);

  return (
    <Tabs colorScheme="gray" size="sm" variant="soft-rounded">
      <TabList>
        <Tab>Rewards</Tab>
        <Tab>Visits</Tab>
        {breweryTracksBenefits && <Tab>Benefits</Tab>}
      </TabList>
      <TabPanels>
        <TabPanel>
          <PatronRewards club={club} patronId={patronId} />
        </TabPanel>
        <TabPanel>
          <PatronVisits club={club} patronId={patronId} />
        </TabPanel>
        {breweryTracksBenefits && (
          <TabPanel>
            <PatronBenefits brewery={brewery} club={club} patronId={patronId} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
