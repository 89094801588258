import { useMemo } from 'react';
import { Box, Button, Tooltip } from '@chakra-ui/react';
import { IBrewery, IClub, ProductPlan } from '../../../types';

interface Props {
  brewery: IBrewery;
  clubs: IClub[];
  onClick: () => void;
}

const Label = () => (
  <Box>
    <Box as="span" textTransform="capitalize">
      {ProductPlan.FREE}
    </Box>{' '}
    and{' '}
    <Box as="span" textTransform="capitalize">
      {ProductPlan.STARTER}
    </Box>{' '}
    plans are limited to one club. Upgrade to a{' '}
    <Box as="span" textTransform="capitalize">
      {ProductPlan.CORE}
    </Box>{' '}
    or{' '}
    <Box as="span" textTransform="capitalize">
      {ProductPlan.COMPLETE}
    </Box>{' '}
    plan to create more clubs.
  </Box>
);

export default function ClubsActions({
  brewery,
  clubs,
  onClick,
}: Props): JSX.Element {
  const isButtonDisabled = useMemo(() => {
    const { plan } = brewery;

    return (
      (plan === ProductPlan.FREE || plan === ProductPlan.STARTER) &&
      clubs.length >= 1
    );
  }, [brewery, clubs]);

  return (
    <Tooltip
      isDisabled={!isButtonDisabled}
      label={<Label />}
      hasArrow
      placement="left"
    >
      <Button isDisabled={isButtonDisabled} onClick={onClick} size="sm">
        Create Club
      </Button>
    </Tooltip>
  );
}
