import { Box } from '@chakra-ui/react';
import { ReadOnlyMessageTextArea } from '../components';
import { BreweryConfigOptions, IBrewery, IBreweryConfig } from '../../../types';

interface Props {
  brewery: IBrewery;
  breweryConfig: IBreweryConfig;
}

function formatRenewalReminder(brewery: IBrewery): string {
  const { name } = brewery;

  return `Hello {{PATRON_FIRST_NAME}},\n
Wishing you a happy birthday month from all of us at ${name}!\n
Stop by the taproom any time over the next month to enjoy a birthday pour on us.\n
Looking forward to celebrating with you soon.\n
Cheers,\n
The team at ${name}`;
}

export default function Birthdays({
  brewery,
  breweryConfig,
}: Props): JSX.Element {
  const { birthdayMessageEnabled } = breweryConfig;

  return (
    <Box>
      <ReadOnlyMessageTextArea
        breweryId={brewery.id}
        configOption={BreweryConfigOptions.birthdayMessageEnabled}
        defaultValue={formatRenewalReminder(brewery)}
        description="Email sent to active patrons on the first day of their birthday month."
        isDefaultEnabled={birthdayMessageEnabled}
        isToggleable
        label="Birthday Month message"
        messageType="email"
        rows={12}
      />
    </Box>
  );
}
