import { Box, Button, Text } from '@chakra-ui/react';
import { IClubReward } from '../../types';

interface IProps {
  rewards: IClubReward[];
  onRemoveReward?: (idx: number) => void;
}

export default function ClubRewardsList({
  onRemoveReward,
  rewards,
}: IProps): JSX.Element {
  return (
    <>
      {rewards
        .sort((a, b) => {
          return a.threshold - b.threshold;
        })
        .map((reward, idx) => (
          <Box
            key={idx}
            alignItems="center"
            border="1px solid"
            borderColor="gray.200"
            display="flex"
            gap={1}
            mb={2}
            px={4}
            py={2}
            rounded="md"
            shadow="sm"
          >
            <Box
              display="grid"
              gridTemplateColumns="400px auto"
              alignItems="flex-start"
            >
              <Box>
                <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                  Reward
                </Text>
                <Text fontWeight="medium" fontSize="sm">
                  {reward.description}
                </Text>
              </Box>

              <Box>
                <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                  Visits Required
                </Text>
                <Text fontWeight="medium" fontSize="sm">
                  {reward.threshold}
                </Text>
              </Box>
            </Box>

            {onRemoveReward && (
              <Button
                size="xs"
                variant="ghost"
                ml="auto"
                color="gray.400"
                onClick={() => onRemoveReward(idx)}
              >
                Remove
              </Button>
            )}
          </Box>
        ))}
    </>
  );
}
