import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';

interface Props {
  clubHasEndDate: boolean;
  hasError: boolean;
  isDisabled: boolean;
  onChange: (date: string) => void;
  value?: string;
}

export default function ExpirationDateInput({
  clubHasEndDate,
  hasError,
  isDisabled,
  onChange,
  value,
}: Props): JSX.Element {
  function handleOnChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    onChange(currentTarget.value);
  }

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel>Membership Expiration Date</FormLabel>
      <Input
        type="date"
        value={value}
        isDisabled={isDisabled}
        onChange={handleOnChange}
      />
      <FormHelperText>
        {clubHasEndDate
          ? 'Cannot change membership expiration date as the associated club has a fixed end date.'
          : "Update this field if a patron's membership expiration date changes. For example, if a patron renews their membership."}
      </FormHelperText>
      <FormErrorMessage>This field is required.</FormErrorMessage>
    </FormControl>
  );
}
