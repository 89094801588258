import { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Text,
} from '@chakra-ui/react';
import { ClubStatus } from '../../../../../types';

interface Props {
  isOpen: boolean;
  nextStatus: ClubStatus;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export default function ConfirmationDialog({
  isOpen,
  nextStatus,
  onClose,
  onConfirm,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cancelRef = useRef(null);

  async function handleOnConfirm() {
    setIsLoading(true);

    try {
      await onConfirm();
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Are You Sure?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text mb={2}>
              {nextStatus === ClubStatus.ACTIVE
                ? 'Activating your club will allow brewery staff to track member visits and rewards.'
                : 'Completing your club will prevent new members from signing up and existing member visits from being tracked. Members can still redeem any earned rewards.'}
            </Text>

            <Text>This action cannot be undone.</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              isDisabled={isLoading}
              size="sm"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              colorScheme={
                nextStatus === ClubStatus.ACTIVE ? 'green' : 'orange'
              }
              onClick={handleOnConfirm}
              isLoading={isLoading}
              ml={2}
              size="sm"
            >
              {nextStatus === ClubStatus.ACTIVE
                ? 'Activate Club'
                : 'Complete Club'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
