import { createContext, useContext, useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import { IPatron } from '../../../types';

const PatronContext = createContext<Context | undefined>(undefined);

interface Context {
  patron?: IPatron;
}

interface Props {
  children: React.ReactNode;
}

function PatronProvider({ children }: Props): JSX.Element {
  const { uid } = useParams();
  const [patron, setPatron] = useState<IPatron>();

  useEffect(() => {
    if (!uid) {
      return;
    }

    let unsubscribe: any;
    const patronRef = doc(db, 'patrons', uid);

    unsubscribe = onSnapshot(patronRef, (patronDoc) => {
      if (!patronDoc.exists()) {
        return;
      }

      setPatron({
        id: patronDoc.id,
        ...patronDoc.data(),
      } as IPatron);
    });

    return () => unsubscribe();
  }, [uid]);

  const value = {
    patron,
  };

  return (
    <PatronContext.Provider value={value}>{children}</PatronContext.Provider>
  );
}

function usePatronContext() {
  const context = useContext(PatronContext);

  if (context === undefined) {
    throw new Error('usePatronContext must be used within a PatronProvider');
  }

  return context;
}

export { PatronContext, PatronProvider, usePatronContext };
