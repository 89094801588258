import { Box, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from '../../assets';

interface IProps {
  description?: string;
  title?: string;
}

export default function EmptyState({
  description,
  title,
}: IProps): JSX.Element {
  return (
    <Box
      border="1px solid"
      borderColor="green.50"
      rounded="md"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="74vh"
    >
      <Box textAlign="center" rounded="md" shadow="md" p={10} minWidth="500px">
        <InfoCircleIcon mb={4} fontSize="3xl" color="green.500" />
        <Text fontSize="xl" fontWeight="medium" mb={1}>
          {title ? title : 'Select a club to view its inventory'}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {description
            ? description
            : 'Only clubs where "Mug Numbers" have been enabled will be shown.'}
        </Text>
      </Box>
    </Box>
  );
}
