import { defaultBreweryConfig } from '../../constants';
import { BreweryConfigOptions, IBrewery, IBreweryConfig } from '../../types';

export function getBreweryConfig(brewery: IBrewery): IBreweryConfig {
  if (brewery.config) {
    return {
      ...defaultBreweryConfig,
      ...brewery.config,
    };
  }

  return defaultBreweryConfig;
}

export function getDefaultConfigValue(configOption: BreweryConfigOptions) {
  return defaultBreweryConfig[configOption];
}
