import { Box, Spinner, Heading } from '@chakra-ui/react';

const FullscreenLoader: React.FC = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100vh"
  >
    <Box textAlign="center">
      <Spinner size="xl" color="green.500" emptyColor="gray.200" mb={8} />
      <Heading as="h2" size="md">
        Loading...
      </Heading>
    </Box>
  </Box>
);

export default FullscreenLoader;
