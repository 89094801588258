import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { IClubReward } from '../../../types';

interface Props {
  isEditable?: boolean;
  onRemove: (id: string) => void;
  onUpdate: (reward: IClubReward) => void;
  reward: IClubReward;
}

export default function Reward({
  isEditable,
  onRemove,
  onUpdate,
  reward,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(reward.description);
  const [threshold, setThreshold] = useState<number>(reward.threshold);

  function handleOnSave() {
    if (!description) {
      return;
    }

    const updatedReward = {
      description,
      id: reward.id,
      threshold: threshold || 0,
    };

    onUpdate(updatedReward);
    setIsEditing(false);
  }

  function handleOnCancel() {
    setDescription(reward.description);
    setThreshold(reward.threshold);
    setIsEditing(false);
  }

  return (
    <Box
      alignItems="center"
      border="1px solid"
      borderColor="gray.200"
      display="flex"
      gap={1}
      mb={2}
      px={4}
      py={2}
      rounded="md"
      shadow="sm"
    >
      <Box
        display="grid"
        gridTemplateColumns={isEditing ? 'auto min-content' : '300px auto auto'}
        alignItems="center"
        gap={isEditing ? 4 : 0}
        w="100%"
      >
        {isEditing ? (
          <Box display="flex" gap={4}>
            <FormControl>
              <FormLabel fontSize="xs" mb={1}>
                Description
              </FormLabel>
              <Input
                value={description}
                onChange={({ currentTarget }) =>
                  setDescription(currentTarget.value)
                }
                rounded="md"
                size="sm"
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="xs" mb={1}>
                Threshold
              </FormLabel>
              <Input
                value={threshold}
                onChange={({ currentTarget }) =>
                  setThreshold(Number(currentTarget.value))
                }
                rounded="md"
                size="sm"
              />
            </FormControl>
          </Box>
        ) : (
          <>
            <Box>
              <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                Reward
              </Text>
              <Text fontWeight="medium" fontSize="sm">
                {reward.description}
              </Text>
            </Box>

            <Box>
              <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                Visits Required
              </Text>
              <Text fontWeight="medium" fontSize="sm">
                {reward.threshold}
              </Text>
            </Box>
          </>
        )}

        {isEditable && !isEditing && (
          <Box display="flex" gap={2} justifySelf="flex-end">
            <Button
              size="xs"
              variant="outline"
              color="gray.400"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
            <Button
              size="xs"
              variant="outline"
              color="red.400"
              onClick={() => onRemove(reward.id)}
            >
              Remove
            </Button>
          </Box>
        )}

        {isEditing && (
          <Box display="flex" gap={2} justifySelf="flex-end">
            <Button
              size="xs"
              variant="outline"
              color="green.400"
              onClick={handleOnSave}
            >
              Save
            </Button>
            <Button
              size="xs"
              variant="outline"
              color="red.400"
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
