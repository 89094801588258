import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { IBrewery } from '../../types';

interface ISubmitValues {
  name: string;
  website: string;
  phoneNumber: string;
}

interface IProps {
  brewery?: IBrewery;
  onSubmit: (values: ISubmitValues) => void;
  submitButtonText: string;
}

export default function BreweryForm({
  brewery,
  onSubmit,
  submitButtonText,
}: IProps): JSX.Element {
  const [name, setName] = useState<string>(brewery?.name || '');
  const [website, setWebsite] = useState<string>(brewery?.website || '');
  const [phoneNumber, setPhoneNumber] = useState<string>(
    brewery?.phoneNumber || '',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isFormValid = name && website && phoneNumber;

  async function handleOnSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await onSubmit({
        name,
        website,
        phoneNumber,
      });
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <VStack spacing={6}>
        <FormControl isRequired>
          <FormLabel htmlFor="name">Brewery Name</FormLabel>
          <Input
            id="name"
            type="text"
            placeholder="Name"
            defaultValue={name}
            onChange={({ target }) => setName(target.value)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="website">Brewery Website</FormLabel>
          <Input
            id="website"
            type="text"
            placeholder="https://your-awesome-brewery.com"
            defaultValue={website}
            onChange={({ target }) => setWebsite(target.value)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
          <Input
            id="phoneNumber"
            type="phone"
            placeholder="Name"
            defaultValue={phoneNumber}
            onChange={({ target }) => setPhoneNumber(target.value)}
          />
        </FormControl>

        <Button
          w="100%"
          type="submit"
          isLoading={isLoading}
          disabled={!isFormValid}
        >
          {submitButtonText}
        </Button>
      </VStack>
    </form>
  );
}
