import { Box, Link, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { CheckCircle } from '../../assets';

export default function Success(): JSX.Element {
  return (
    <Box
      bg="gray.100"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        textAlign="center"
        bg="white"
        rounded="md"
        shadow="md"
        px={4}
        py={8}
        w={384}
      >
        <CheckCircle fontSize="4xl" mb={4} color="green.500" />

        <Text fontSize="2xl" fontWeight="semibold" mb={4}>
          Password Reset Requested
        </Text>

        <Text mb={8}>
          Check your email for a link to reset your password. If it doesn't
          appear within a few minutes, check your spam folder.
        </Text>

        <Text color="gray.500" mb={4} fontSize="sm">
          Need help? Email us at{' '}
          <Link href="mailto:help@mugclub.beer">help@mugclub.beer</Link>.
        </Text>

        <Text color="gray.500" fontSize="sm">
          <Link as={ReactRouterLink} to="/">
            Click here
          </Link>{' '}
          to return to the Login page.
        </Text>
      </Box>
    </Box>
  );
}
