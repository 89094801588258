import { useCallback, useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { useToast } from '@chakra-ui/react';
import { db } from '../firebase';
import { IClub } from '../types';

export default function useFetchClubs(breweryId: string): [IClub[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clubsData, setClubsData] = useState<IClub[]>([]);
  const toast = useToast();

  const fetchClubs = useCallback(async () => {
    const clubsCollection = collection(db, 'clubs');
    const q = query(
      clubsCollection,
      where('breweryRef', '==', doc(db, 'breweries', breweryId)),
    );

    try {
      const clubsDocs = await getDocs(q);
      const clubs: IClub[] = [];

      clubsDocs.forEach((doc) => {
        clubs.push({
          id: doc.id,
          ...doc.data(),
        } as IClub);
      });

      setClubsData(clubs);
    } catch (error) {
      toast({
        description: 'An error occurred loading your clubs. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [breweryId, toast]);

  useEffect(() => {
    fetchClubs();
  }, [breweryId, fetchClubs]);

  return [clubsData, isLoading];
}
