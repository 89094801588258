import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { PostHogProvider } from 'posthog-js/react';
import theme, { toastConfiguration } from './theme';
import App from './App';
import { isProduction } from './utils';

if (isProduction()) {
  Sentry.init({
    dsn: 'https://bffe709ff7794f34af57f3dd6d1ac315@o4504121051840512.ingest.sentry.io/4504121059770368',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}>
      <BrowserRouter>
        <ChakraProvider
          theme={theme}
          toastOptions={{ defaultOptions: toastConfiguration }}
        >
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>,
);
