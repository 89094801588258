import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
} from '@chakra-ui/react';
import { Users } from '../../assets';
import { IClub } from '../../types';

interface Props {
  club: IClub;
  isEditable?: boolean;
  onChange: (key: keyof IClub, value: string | number) => void;
}

export default function Basics({
  club,
  isEditable,
  onChange,
}: Props): JSX.Element {
  const { capacity, cost, name, renewalCost } = club;

  return (
    <>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Basics
      </Text>

      <FormControl mb={3}>
        <FormLabel htmlFor="name">Club Name</FormLabel>
        <Input
          type="name"
          placeholder="Club Name"
          value={name}
          readOnly={!isEditable}
          onChange={({ currentTarget }) =>
            onChange('name', currentTarget.value)
          }
        />
      </FormControl>

      <Box display="flex" w="100%" columnGap={4} mb={3}>
        <FormControl>
          <FormLabel htmlFor="cost">Membership Cost</FormLabel>

          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.500"
              children="$"
            />
            <Input
              type="number"
              placeholder="Cost"
              value={cost || 0}
              readOnly={!isEditable}
              onChange={({ currentTarget }) =>
                onChange('cost', Number(currentTarget.value))
              }
            />
          </InputGroup>
        </FormControl>

        {renewalCost && (
          <FormControl>
            <FormLabel htmlFor="renewalCost">Renewal Cost</FormLabel>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.500"
                children="$"
              />
              <Input
                type="number"
                placeholder="Renewal Cost"
                value={renewalCost}
                readOnly={!isEditable}
                onChange={({ currentTarget }) =>
                  onChange('renewalCost', currentTarget.value)
                }
              />
            </InputGroup>
          </FormControl>
        )}

        {capacity && (
          <FormControl>
            <FormLabel htmlFor="capacity">Membership Capacity</FormLabel>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.500"
                children={<Icon as={Users} />}
              />
              <Input
                id="capacity"
                type="number"
                placeholder="Capacity"
                value={capacity}
                readOnly={!isEditable}
                onChange={({ currentTarget }) =>
                  onChange('capacity', currentTarget.value)
                }
              />
            </InputGroup>
          </FormControl>
        )}
      </Box>
    </>
  );
}
