import { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Link, SkeletonText } from '@chakra-ui/react';
import { IPatron } from '../../../types';
import { db } from '../../../firebase';

interface IProps {
  id: string;
}

export default function PatronName({ id }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patron, setPatron] = useState<IPatron | undefined>(undefined);

  useEffect(() => {
    async function fetchPatron() {
      const patronRef = doc(db, 'patrons', id);
      const patronDoc = await getDoc(patronRef);

      setPatron({
        id: patronDoc.id,
        ...patronDoc.data(),
      } as IPatron);
      setIsLoading(false);
    }

    fetchPatron();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <SkeletonText noOfLines={1} />
      ) : (
        <Link
          as={ReactRouterLink}
          to={`/auth/patrons/${id}`}
          color="green.300"
          textDecoration="underline"
        >
          {patron?.firstName} {patron?.lastName}
        </Link>
      )}
    </>
  );
}
