import { Box, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from '../../../assets';

export default function FeatureGate(): JSX.Element | null {
  return (
    <Box
      alignItems="center"
      bg="blackAlpha.400"
      border="1px solid"
      borderColor="green.50"
      display="flex"
      justifyContent="center"
      position="absolute"
      rounded="md"
      top={-2}
      bottom={-2}
      right={-2}
      left={-2}
    >
      <Box
        bg="white"
        textAlign="center"
        rounded="md"
        shadow="md"
        p={10}
        minWidth="500px"
        zIndex={100}
      >
        <InfoCircleIcon mb={4} fontSize="3xl" color="orange.500" />
        <Text fontSize="xl" fontWeight="medium" mb={1}>
          Upgrade to Access Reward Tracking
        </Text>
        <Text fontSize="sm" color="gray.500">
          Reward tracking is available on Core and Complete plans.
        </Text>
      </Box>
    </Box>
  );
}
