import { IClub } from '../../../types';

type OmittedClubFields = Omit<
  IClub,
  | 'id'
  | 'breweryId'
  | 'breweryRef'
  | 'capacity'
  | 'cost'
  | 'createdAt'
  | 'createdBy'
  | 'endDate'
  | 'membershipDurationMonths'
  | 'registrationEndDate'
  | 'renewalCost'
  | 'startDate'
  | 'status'
>;

export interface FormValues extends OmittedClubFields {
  capacity?: string;
  cost?: string;
  customQuestions?: string;
  endDate?: string;
  membershipDurationMonths?: string;
  registrationEndDate?: string;
  renewalCost?: string;
  startDate?: string;
}

export function getInitialFormValues(): FormValues {
  return {
    benefits: [],
    capacity: undefined,
    cost: undefined,
    customQuestions: undefined,
    endDate: undefined,
    hasMugNumbers: false,
    hasPatronApp: false,
    hasPatronMugNumberSelection: false,
    hasRenewalReminders: false,
    isActive: false,
    membershipDurationMonths: undefined,
    name: '',
    registrationEndDate: undefined,
    renewalCost: undefined,
    rewards: [],
    startDate: undefined,
    terms: undefined,
  };
}
