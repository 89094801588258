import { useEffect, useMemo, useState } from 'react';
import { Box, useToast, VStack } from '@chakra-ui/react';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  Timestamp,
} from 'firebase/firestore';
import { EmptyState } from '../../../components';
import Reward from './Reward';
import { db } from '../../../firebase';
import { IClub, IPatronReward } from '../../../types';

interface IProps {
  club: IClub;
  patronId: string;
}

export default function PatronRewards({ club, patronId }: IProps): JSX.Element {
  const [rewards, setRewards] = useState<IPatronReward[]>([]);
  const toast = useToast();
  const sortedClubRewards = useMemo(() => {
    return club.rewards.sort((a, b) => {
      if (a.threshold > b.threshold) {
        return 1;
      }

      return -1;
    });
  }, [club]);

  useEffect(() => {
    if (!patronId) {
      return;
    }

    let unsubscribe: any;
    const rewardsCollection = collection(db, 'rewards');
    const patronRef = doc(db, 'patrons', patronId);
    const clubRef = doc(db, 'clubs', club.id);
    const q = query(
      rewardsCollection,
      where('patronRef', '==', patronRef),
      where('clubRef', '==', clubRef),
      orderBy('createdAt', 'desc'),
    );

    unsubscribe = onSnapshot(q, (rewardsSnap) => {
      const rewards: IPatronReward[] = [];
      rewardsSnap.forEach((doc) => {
        rewards.push({
          id: doc.id,
          ...doc.data(),
        } as IPatronReward);
      });

      setRewards(rewards);
    });

    return () => unsubscribe();
  }, [club, patronId]);

  async function handleRedeemReward(rewardId: string): Promise<void> {
    try {
      const rewardDoc = doc(db, 'rewards', rewardId);
      const data: Pick<IPatronReward, 'redeemed' | 'redeemedAt'> = {
        redeemed: true,
        redeemedAt: Timestamp.now(),
      };

      await updateDoc(rewardDoc, data);

      toast({
        description: 'Reward redeemed',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        title: 'Success!',
        variant: 'left-accent',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred while redeeming the reward, please try again.',
        isClosable: true,
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh.',
        variant: 'left-accent',
      });
    }
  }

  async function handleUnredeemReward(rewardId: string): Promise<void> {
    try {
      const rewardDoc = doc(db, 'rewards', rewardId);
      const data: Pick<IPatronReward, 'redeemed' | 'redeemedAt'> = {
        redeemed: false,
        redeemedAt: null,
      };

      await updateDoc(rewardDoc, data);

      toast({
        description: 'Reward unredeemed',
        isClosable: true,
        position: 'top-right',
        status: 'info',
        title: 'Success!',
        variant: 'left-accent',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred while unredeeming the reward, please try again.',
        isClosable: true,
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh.',
        variant: 'left-accent',
      });
    }
  }

  return (
    <Box>
      {sortedClubRewards.length === 0 && (
        <EmptyState
          heading="No Rewards Found"
          description="Rewards are earned as a patron logs visits."
        />
      )}

      <VStack spacing={2}>
        {sortedClubRewards.map((clubReward, idx) => {
          const earnedReward = rewards.find((patronReward) => {
            return patronReward.rewardId === clubReward.id;
          });

          return (
            <Reward
              key={idx}
              reward={clubReward}
              earnedReward={earnedReward}
              onRedeemReward={handleRedeemReward}
              onUnredeemReward={handleUnredeemReward}
            />
          );
        })}
      </VStack>
    </Box>
  );
}
